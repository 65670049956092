import React from "react";
import { Col, Button, Row } from "react-bootstrap";

const UsersTable = (props) => {
  const usersArray = props.usersArray;

  if (usersArray != null) {
    return (
      usersArray.map((user, index) => (
        <>
          <Row className="my-3 align-items-center justify-content-around" key={user?.uid}>
            <Col xs={7}>
              <b>{user?.name}</b>
              <small className="m-0"> ({user?.email})</small>
            </Col>
            <Col xs={2}>
              <Button
                className="mt-0 mb-1"
                variant="danger"
                onClick={() => {
                  props.onClick({ index });
                }}>
                Delete
              </Button>
            </Col>
          </Row>
        </>
      ))
    )
  }
}

export default UsersTable;