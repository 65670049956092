import firebase from "firebase";
import { orderBy } from 'lodash';
import React, { useEffect, useState } from "react";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import LoadingCircle from "../components/loading";
import "./page-styles.css";

var firebaseApp = firebase.app();
var functions = firebaseApp.functions("asia-northeast1");

function PreviewCard(data) {
  const val = data.linkData
  const error = data.linkData.error;

  if (error == null || error == undefined) {
    return (
      <Row className="mx-0 preview-border d-flex flex-column flex-md-row p-2">
        <Col md="4" className="px-0">
          <img className="img-fluid" src={val.image} alt="" />
        </Col>
        <Col md="8" className="my-4 my-md-0">
          <div>
            <h4>{val.title}</h4>
            <p className="overflow">{val.description}</p>
          </div>
        </Col>
      </Row>
    );
  } else {
    return (
      <Row className="mx-0 d-flex justify-content-center">
        <Col xs="8" className="px-0">
          <p className="preview-error text-center">An error has occurred while trying to retrieve the Preview Link. Please try again later!</p>
        </Col>
      </Row>
    );
  }

};

const LearnContent = () => {
  const [previewPending, setPreviewPending] = useState(false);
  const [tagPending, setTagPending] = useState(false);
  const [pending, setPending] = useState(false);

  const [learnURL, setLearnURL] = useState(null);
  const [errorLearnURL, setErrorLearnURL] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const [validateLearnURL, setValidateLearnURL] = useState(false);

  const [tagsList, setTagsList] = useState(null);
  const [tagsField, setTagsField] = useState([]);
  const [contentType, setContentType] = useState(null);
  const [errorTagsField, setErrorTagsField] = useState(false);
  const [validateTagsField, setValidateTagsField] = useState(false);
  const [invalidTagsField, setInvalidTagsField] = useState(false);

  // ! Uncomment when bylines are in use.
  // const [byline, setByline] = useState(null);

  const [responseType, setResponseType] = useState(null);
  const [responseMessage, setResponseMessage] = useState(null);

  // Get the taglist on load of this page
  useEffect(() => {
    getTagsList();
  }, []);

  // Reset states upon submission.
  const resetStates = () => {
    setLearnURL(null);
    setThumbnail(null);
    setContentType(null);
  }

  const validateURL = async () => {
    setPreviewPending(true);
    const url = 'https://api.linkpreview.net/?key=' + process.env.REACT_APP__LINK_PREVIEW_KEY + '&q=' + learnURL;
    const res = await fetch(url, {
      method: 'POST',
      mode: 'cors'
    })
    const result = await res.json();
    setThumbnail(result);
    setPreviewPending(false);
  }

  const learnURLValue = (event) => {
    setErrorLearnURL(null);
    setLearnURL(event.target.value);
  };

  const getTagsList = () => {
    setResponseType(null);
    setResponseMessage(null);
    setTagPending(true);
    const getTagsListData = functions.httpsCallable("callGenerateTagsList");
    getTagsListData().then(function (res) {
      if (res.data != null) {
        const result = orderBy(res.data, ['count'], ['desc']);
        setTagsList(result);
      }
      setTagPending(false);
    });
  };

  // ! Uncomment when bylines are in use.
  // const bylineValue = (event) => {
  //   setByline(event.target.value)
  // }

  const validate = () => {
    setPending(true);
    if (tagsField == null || tagsField.length == 0) {
      setErrorTagsField("Please enter at least ONE tag");
      setValidateTagsField(false);
      setInvalidTagsField(true);
    } else {
      setValidateTagsField(true);
    }
    if (learnURL == null) {
      setErrorLearnURL("Please enter an URL");
    } else {
      setValidateLearnURL(true);
    }

    if (validateLearnURL && validateTagsField) {
      const tagsArray = JSON.stringify(tagsField);
      const data = {
        contentUrl: learnURL,
        tags: tagsArray,
        type: contentType
        // ! Uncomment when bylines are in use.
        // byline: byline
      }
      addLearnContent(data);
    }
    else {
      setPending(false);
    }
  }

  const addLearnContent = (details) => {
    setResponseType(null);
    setResponseMessage(null);
    setPending(true);
    const addLearnContentData = functions.httpsCallable("callAddLearnContentAdmin");
    addLearnContentData(details).then(function (res) {
      const result = JSON.parse(JSON.stringify(res.data));
      if (result.statusCode === 200) {
        setResponseType("success");
        setResponseMessage(result.statusMessage);
        resetStates();
      } else {
        setResponseType("danger");
        setResponseMessage(result.statusMessage);
      }
      setPending(false);
    })
      .catch(function (error) {
        setResponseType("danger");
        setResponseMessage(error);
      });
  }

  return (<div className="page-container">
    <Container className="d-flex justify-content-center my-4">
      <h2>Learn Recommendation</h2>
    </Container>
    <Container>
      <Form>
        <Form.Group>
          <Form.Label>Learn Content URL</Form.Label>
          <Row className="d-flex justify-content-center justify-content-lg-between">
            <Col lg="9">
              <Form.Control
                onChange={learnURLValue}
                required
                value={learnURL || ''}
                placeholder="http://www.youtube.com/video-for-learn"
                isInvalid={!!errorLearnURL ? true : false}
              />
              <Form.Control.Feedback type="invalid">
                {errorLearnURL}
              </Form.Control.Feedback>
            </Col>
            <Col lg="3" className="my-2 my-lg-0">
              {!previewPending &&
                <Button onClick={validateURL} className="btn-block">
                  Preview Link
                </Button>}
              {previewPending && <LoadingCircle />}
            </Col>
          </Row>
        </Form.Group>
        <Form.Group>
          {thumbnail && <PreviewCard linkData={thumbnail} />}
        </Form.Group>
        <Form.Group>
          <Form.Label>Tags</Form.Label>
          <Row>
            <Col xs="12">
              {!tagPending && <Typeahead
                id="tag"
                labelKey={(option) =>
                  (option.is_custom) ? `${option.name} (${option.category}), Custom` :
                    `${option.name} (${option.category})`
                }
                multiple
                options={tagsList}
                placeholder="Select a tag"
                isInvalid={invalidTagsField}
                onChange={(selected) => {
                  setTagsField(selected)
                }}
              />}
              {tagPending && <LoadingCircle />}
              {invalidTagsField && <div className="tag-error-text">
                {errorTagsField}
              </div>}
            </Col>
          </Row>
        </Form.Group>
        {/* ! Uncomment the bottom when bylines are enabled. */}
        {/* <Form.Group>
          <Form.Label>Byline (Optional)</Form.Label>
          <Row>
            <Col>
              <Form.Control
                onChange={bylineValue}
                value={byline || ''}
                placeholder="Add custom byline"
              />
            </Col>
          </Row>
        </Form.Group> */}
      <Form.Group>
        <Form.Label>Content Type (Optional)</Form.Label>
        <Row>
          <Col>
            <Form.Control as ="select"
            value = {contentType != null ? contentType : ''}
            onChange={(event) => {
              setContentType(event.target.value);
            }}>
              <option value={null}>Select a content type</option>
              <option value="Article">Article</option>
              <option value="Podcast">Podcast</option>
              <option value="Video">Video</option>
            </Form.Control>
          </Col>
        </Row>
      </Form.Group>
      </Form>
      {!pending && <Row className="justify-content-center">
        <Col sm="4" className="text-center my-4">
          <Button onClick={validate} size="lg">
            Submit
          </Button>
        </Col>
      </Row>}
      {pending &&
        <Row className="justify-content-center">
          <Col xs="3"><LoadingCircle /></Col>
        </Row>
      }
      {responseType &&
        <Row className="justify-content-center w-50 mt-3 alert-align">
          <Col className="align-self-center">
            <Alert className="" variant={"success"}>{responseMessage}</Alert>
          </Col>
        </Row>
      }
    </Container>
  </div>);
};
export default LearnContent;
