import firebase from "firebase";
import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import LoadingCircle from "../components/loading";
import { AuthContext } from "../services/auth";
import "./page-styles.css";
import axios from "axios";
var firebaseApp = firebase.app();
var functions = firebaseApp.functions("asia-northeast1");
var baseURL = `${process.env.REACT_APP_API_V2_URL}`;


const CreateUser = () => {
  const [pending, setPending] = useState(false);
  const { currentUser, bearerToken } = useContext(AuthContext);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [errorNameCode, setErrorNameCode] = useState(null);
  const [errorTextCode, setErrorTextCode] = useState(null);
  const [errorTextName, setErrorTextName] = useState(null);

  const [validateInputCode, setValidInputCode] = useState(true);
  const [validateInputName, setValidInputName] = useState(true);

  const [responseCode, setResponseCode] = useState(null);
  const [responseMessage, setResponseMessage] = useState(null);
  const [authHeader, setAuthHeader] = useState(null);

  useEffect(() => {
    // Set auth headers on loading of page
    AuthHeaderFunction();
  }, []);
  
   // Function to set auth header into state variable
    const AuthHeaderFunction = () => {
      if (currentUser && bearerToken !== null) {
        let authHeaderObj = {
          Authorization: 'Bearer ' + bearerToken,
          'Content-Type': 'application/json',
        };
        setAuthHeader(authHeaderObj);
      }
    };

  const callCreateUser = (data) => {
    setResponseMessage(null);
    setPending(true);
    axios.defaults.headers.post['Authorization'] = `Bearer ${bearerToken}`;
    axios.defaults.headers.post['content-type'] = `application/json`;
    axios.post(baseURL + '/admin/create-user', {name,email,password})
      .then(function (response) {
        const result = response?.data;
        setResponseCode(result?.status);
        //setResponseMessage()
        setEmail('');
        setName('');
        setPassword('');
        setPending(false);
      })
      .catch(function (error) {
        setResponseMessage(error?.response?.data?.message)
        console.error(error?.response?.data?.message);
        setPending(false);
      });
  };
  const isEmail = (email) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

  const validate = () => {
    if (name === null) {
      setErrorNameCode("Please enter name");
      setValidInputCode(false);
    }
    if (email === null) {
      setErrorTextCode("Please enter email");
      setValidInputCode(false);
    }
    if (!isEmail(email)) {
      setErrorTextCode("Please enter valid email address");
      setValidInputCode(false);
    }
    if (password === null || password.length === 0) {
      setErrorTextName("Please enter password");
      setValidInputName(false);
    }

    if (validateInputCode && validateInputName) {
      const data = {
        email: email,
        password: password,
      };
      callCreateUser(data);
    }
  };

  const nameValue = (event) => {
    setErrorTextCode(null);
    setName(event.target.value);
  };

  const emailValue = (event) => {
    setErrorTextCode(null);
    setEmail(event.target.value);
  };

  const passwordValue = (event) => {
    setErrorTextName(null);
    setPassword(event.target.value);
  };

  return (
    <div className="page-container">
      <Container className="d-flex justify-content-center my-4">
        <h2>Create Test User</h2>
      </Container>
      <Container>
        <Col className="p-0">
          <Card className="mx-auto">
            <Card.Body className="flex-column">
              <Container className="my-4 w-md-75">

              <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    onChange={nameValue}
                    required
                    value={name}
                    placeholder="Please enter name"
                    isInvalid={!!errorNameCode ? true : false}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorNameCode}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    onChange={emailValue}
                    required
                    value={email}
                    placeholder="Please enter email"
                    isInvalid={!!errorTextCode ? true : false}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorTextCode}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    onChange={passwordValue}
                    required
                    value={password}
                    placeholder="Please enter password"
                    isInvalid={!!errorTextName ? true : false}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorTextName}
                  </Form.Control.Feedback>
                </Form.Group>
                {!pending && <Row className="justify-content-center mt-5">
                  <Button onClick={validate}>
                    Create
                  </Button>
                </Row>}
                {pending &&
                  <Row className="justify-content-center">
                    <Col xs="3"><LoadingCircle /></Col>
                  </Row>
                }
                {responseMessage &&
                  <Alert className="" align="center" variant={"subtitle1"}>{responseMessage}</Alert>
                }
              </Container>
            </Card.Body>
          </Card>
        </Col>
      </Container>
    </div>

  );
};

export default CreateUser;