// Colleague 'connected_status'
export const CONNECTED_STATUS_CONNECTED = 'CONNECTED';
export const CONNECTED_STATUS_PENDING = 'PENDING';

// Method of specifying the user details to be added as colleague
export const SEARCH_COLLEAGUE_FROM_ALGOLIA = 'ALGOLIA';
export const ADD_DETAILS_MANUALLY = 'MANUALLY';

// Create Custom Tags Page: 
// Toggle between confirming existing custom tags or adding 
// new tags to an existing collection. 
export const CONFIRM_EXISTING_TAG_SELECTION = 'CONFIRMED';
export const ADD_NEW_TAGS_TO_TAG_COLLECTION = 'ADDNEWTAGS';

// Create Custom Tags Page: 
// Method of selecting users to add custom tags to.
export const SELECT_ALL_USERS = 'ALLUSERS';
export const INDIVIDUAL_USERS = 'INDIVIDUALUSERS';

// Notification Page: 
// Toggle between sending a slack or device notification.
export const DEVICE_NOTIFICATION = 'DEVICE';
export const SLACK_NOTIFICATION = 'SLACK'