import Avatar from "@material-ui/core/Avatar";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import firebase from "firebase";
import React, { useContext } from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { Redirect } from "react-router-dom";
import onLoopIcon from "../assets/onloop-circle.png";
import LoadingCircle from '../components/loading';
import { AuthContext } from "../services/auth";
import app from "../services/firebase.js";
import history from "../services/history";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ErrorTextTypography = withStyles({
  root: {
    color: "red"
  }
})(Typography);

var uiConfig = {
  signInFlow: "popup",
  signInoptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
  callbacks: {
    signInSuccessWithAuthResult: (authResult) => {
      return false;
    },
  },
};

const Login = () => {
  const classes = useStyles();
  const {validUser, errorMessage, pending} = useContext(AuthContext);

  if (validUser) {
    history.push("/home");
    return <Redirect to="/home" />;
  } else {
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar} src={onLoopIcon}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            OnLoop Admin
          </Typography>
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={app.auth()} />
          {pending && <LoadingCircle />}
          <ErrorTextTypography component="h1" variant="subtitle1">
            {errorMessage}
          </ErrorTextTypography>
        </div>
      </Container>
    );
  }
};

export default Login;
