import algoliasearch from 'algoliasearch/lite';
import firebase from "firebase";
import React, { useState } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import LoadingCircle from '../components/loading';
import UserSearch from "../components/user-search";
import OrganizationSearch from '../components/organization-search';
import * as constants from "../constants";
import "./page-styles.css";

const searchClient = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_API_KEY);
const index = searchClient.initIndex('users');
const orgIndex = searchClient.initIndex('organizations');
var firebaseApp = firebase.app();
var functions = firebaseApp.functions("asia-northeast1");

const CreateCustomTags = () => {

  // Initialising state variables and functions.
  const [isLoading, setIsLoading] = useState(false);

  // Users
  const [newUserId, setNewUserId] = useState(null);
  const [newName, setNewName] = useState(null);
  const [newEmail, setNewEmail] = useState(null);
  const [users, setUsers] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  // Organization
  const [organizationId, setOrganizationId] = useState(null);
  const [organizationOptions, setOrganizationOptions] = useState([]);
  const [existingCustomTagsCategories, setExistingCustomTagsCategories] = useState(null);

  // New, Existing, and Final Tags Array
  const [existingCustomTags, setExistingCustomTags] = useState([]);
  const [newCustomTags, setNewCustomTags] = useState([]);
  const [finalCustomTagsToAdd, setFinalCustomTagsToAdd] = useState([]);

  // New Custom Tags
  const [newCustomTagCategory, setNewCustomTagCategory] = useState(null);
  const [newCustomTagName, setNewCustomTagName] = useState(null);
  const [newCustomTagColor, setNewCustomTagColor] = useState(null);
  const [positiveSentiment, setPositiveSentiment] = useState(false);
  const [negativeSentiment, setNegativeSentiment] = useState(false);
  const [neutralSentiment, setNeutralSentiment] = useState(false);
  const [isOrgValues, setIsOrgValues] = useState(false);

  // Shown states 
  const [newCustomTagPreviewColor, setNewCustomTagPreviewColor] = useState(null);

  // Loading states.
  const [existingCustomTagsPending, setExistingCustomTagsPending] = useState(false);
  const [addCustomTagPending, setAddCustomTagPending] = useState(false);

  // Invalid fields 
  const [invalidNewCustomTagName, setInvalidNewCustomTagName] = useState(null);
  const [invalidNewCustomTagCategory, setInvalidNewCustomTagCategory] = useState(null);
  const [invalidNewCustomTagColor, setInvalidNewCustomTagColor] = useState(null);
  const [validateNewCustomTagNameLength, setValidateNewCustomTagNameLength] = useState(null);

  // Success message and status states.
  const [addUserMessage, setAddUserMessage] = useState(null);
  const [addUserStatus, setAddUserStatus] = useState(null);
  const [addCustomTagMessage, setAddCustomTagMessage] = useState(null);
  const [addCustomTagStatus, setAddCustomTagStatus] = useState(null);
  const [customTagsConfirmedMessage, setCustomTagsConfirmedMessage] = useState(null);
  const [responseType, setResponseType] = useState(null);
  const [responseMessage, setResponseMessage] = useState(null);
  const [existingCustomTagMessage, setExistingCustomTagMessage] = useState(null);
  const [errorNewTagName, setErrorNewTagName] = useState(null);

  // Conditional UI and form methods.
  const [organizationSelected, setOrganizationSelected] = useState(false);
  const [existingCustomTagsNotFound, setExistingCustomTagsNotFound] = useState(false);
  const [customTagsConfirmed, setCustomTagsConfirmed] = useState(false);
  const [addNewCustomTagCategory, setAddNewCustomTagCategory] = useState(false);
  const [addIndividualUsers, setAddIndividualUsers] = useState(false);
  const [usersMethodSelected, setUsersMethodSelected] = useState(false);
  const [colorPreview, setColorPreview] = useState(false);
  const [colorSelected, setColorSelected] = useState(false);
  const [categorySelected, setCategorySelected] = useState(false);
  const [orgValuesExist, setOrgValuesExist] = useState(false);
  const [isOrgValuesSelected, setIsOrgValuesSelected] = useState(false);

  // CSS 
  const tagPreviewColor = {
    backgroundColor: newCustomTagPreviewColor
  }

  // New Custom Tag Colors. 
  const newTagColors = [
    {
      displayValue: "Green",
      value: "green2",
      hexadecimalCode: '#2b9367'
    },
    {
      displayValue: "Orange",
      value: "orange2",
      hexadecimalCode: '#ba722f'
    },
    {
      displayValue: "Teal",
      value: "teal2",
      hexadecimalCode: '#00c0c0'
    },
    {
      displayValue: "Original Pink",
      value: "pink",
      hexadecimalCode: '#e75d7f'
    },
    {
      displayValue: "New Pink",
      value: "pink2",
      hexadecimalCode: '#e570a5'
    },
    {
      displayValue: "Blue",
      value: "blue2",
      hexadecimalCode: '#4978f2'
    },
    {
      displayValue: "Lime",
      value: "lime2",
      hexadecimalCode: '#60a545'
    }
  ]

  // Function to sort tags by name alphabetically.
  const sortAlphabetically = (a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  // Component to show dropdown menu for existing custom tag selection 
  // if existing custom tag categories exist. If no categories are found,
  // admin will be prompted to create a new custom tag collection.
  const ExistingCustomTagCategories = () => {
    if (existingCustomTagsCategories != null && existingCustomTagsCategories.length > 0) {
      return (
        <>
          <Form.Group>
            <h5 className="my-3"><b>Step 2: Select a Custom Tag Collection</b></h5>
            <Form.Control as="select"
              value={newCustomTagCategory}
              onChange={(event) => {
                if (event.target.value != -1) {
                  getExistingCustomTags(event.target.value)
                } else {
                  resetCustomTagStates();
                }
              }}>
              <option value={-1}>Select a custom tag category</option>
              {
                existingCustomTagsCategories.map((categoryData, index) => (
                  <option value={categoryData.customTagCategory}>
                    {categoryData.customTagCategory} {categoryData.isOrgValues ? '(Organization Values)' : ''}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>

          {(!existingCustomTagsNotFound && !categorySelected) &&
            <Row className="d-flex flex-column align-items-center my-4">
              <Col>
                <h6>Can't find a category you're looking for?</h6>
              </Col>
              <Col>
                <Button onClick={selectToAddNewCustomTags} className="btn-selection">
                  Add New Custom Tags
                </Button>
              </Col>
            </Row>
          }
        </>
      )
    } else {
      if (!existingCustomTagsPending) {
        return (
          <Row className="d-flex flex-column align-items-center my-4">
            <Col>
              <h6>This organization does not have any pre-existing tags.</h6>
            </Col>
            <Col>
              <Button onClick={selectToAddNewCustomTags} className="btn-selection">
                Add New Custom Tags
              </Button>
            </Col>
          </Row>
        )
      } else {
        return (
          <>
          </>
        )
      }
    }
  }

  // Component to populate "Existing Tags" preview after custom tag category is selected.
  // If no tags are found with this category, admin will be prompted
  // to create a new custom tag collection.
  const ExistingCustomTagsPreview = () => {
    if (existingCustomTags != null && existingCustomTags.length > 0) {
      const existingTagsPreviewArray = existingCustomTags;
      return (
        <Container className="d-flex flex-column justify-content-center">
          <h5 className="py-4 text-center">Existing {newCustomTagCategory} Custom Tags</h5>
          <Container className="d-flex flex-row align-items-center flex-wrap">
            {existingTagsPreviewArray.map((existingTag, index) => (
              <Col xs={4} className='text-center' key={index}>
                <b className="tag-preview" style={tagPreviewColor}>
                  {existingTag.name}</b>
                <div className='text-center mt-3 mb-4'>
                  <p className="m-0">{existingTag.type}</p>
                </div>
              </Col>
            ))}
          </Container>
        </Container>
      )
    } else if (existingCustomTagsNotFound) {
      return (
        <Container className="d-flex flex-row justify-content-start align-items-center">
          <Alert className="text-center my-4 mr-4" variant={"danger"}>{existingCustomTagMessage}</Alert>
          <Button onClick={selectToAddNewCustomTags} className="btn-selection">
            Add New Custom Tags
          </Button>
        </Container>
      );
    } else {
      return (
        <>
        </>
      )
    }
  }

  // Component to populate "Existing Tags" table. 
  const ExistingCustomTags = () => {
    if (existingCustomTags) {
      const existingTagsArray = existingCustomTags;
      return (
        existingTagsArray.map((existingTag, index) => (
          <>
            <Row className="mb-4 align-items-center justify-content-around" key={index}>
              <Col xs={7}>
                <b className="tag-preview" style={tagPreviewColor}>
                  {existingTag.name}</b>
              </Col>
              <Col xs={3}>
                <p className="m-0 text-center">{existingTag.type}</p>
              </Col>
              <Col xs={2}>
              </Col>
            </Row>
          </>
        ))
      )
    } else {
      return (
        <>
        </>
      );
    }
  }

  // Component to populate "Users" table when 'selectedUsers' state is populated.
  const Users = () => {
    if (selectedUsers) {
      const usersArray = selectedUsers;
      return (
        usersArray.map((user, index) => (
          <>
            <Row className="my-3 align-items-center justify-content-around" key={user.uid}>
              <Col xs={7}>
                <b>{user.name}</b>
                <small className="m-0"> ({user.email})</small>
              </Col>
              <Col xs={2}>
                <Button
                  className="mt-0 mb-1"
                  variant="danger"
                  onClick={() => removeUser({ index })}>
                  Delete
                </Button>
              </Col>
            </Row>
          </>
        ))
      );
    } else {
      return (
        <>
        </>
      );
    };
  };

  // Component to populate "Tags to Add" table when 'newCustomTags' state is populated.
  const NewCustomTags = () => {
    if (newCustomTags) {
      const customTagsArray = newCustomTags;
      return (
        customTagsArray.map((customTag, index) => (
          <>
            <Row className="mb-4 align-items-center justify-content-around" key={index}>
              <Col xs={7}>
                <b className="tag-preview" style={tagPreviewColor}>
                  {customTag.name}</b>
              </Col>
              <Col xs={3}>
                <p className="m-0 text-center">{customTag.type}</p>
              </Col>
              <Col xs={2}>
                {!customTagsConfirmed &&
                  <Button
                    variant="danger"
                    onClick={() => removeCustomTag({ index })}>
                    Delete
                  </Button>}
              </Col>
            </Row>
          </>
        ))
      );
    } else {
      return (
        <>
        </>
      );
    }
  };

  // Reset main states (e.g. organization, custom tag and user states).
  // Used when organization is reset
  const resetStates = () => {

    // User states
    setUsers([]);
    setUserOptions([]);
    setSelectedUsers([]);

    // Organization states
    setOrganizationId(null);
    setOrganizationOptions([]);
    setExistingCustomTagsCategories(null);

    // Custom tag states
    setExistingCustomTags([]);
    setNewCustomTags([]);
    setFinalCustomTagsToAdd([]);
    setNewCustomTagCategory(null);
    setNewCustomTagColor(null);
    setNewCustomTagPreviewColor(null)

    // success message and status states
    setAddUserMessage(null);
    setAddUserStatus(null);
    setAddCustomTagMessage(null);
    setAddCustomTagStatus(null);
    setCustomTagsConfirmedMessage(null);
    setResponseType(null);
    setResponseMessage(null);
    setExistingCustomTagMessage(null);

    // Conditional UI states
    setOrganizationSelected(false);
    setExistingCustomTagsNotFound(false);
    setCustomTagsConfirmed(false);
    setAddNewCustomTagCategory(false);
    setAddIndividualUsers(false);
    setUsersMethodSelected(false);
    setColorPreview(false);
    setColorSelected(false);
    setCategorySelected(false);
    setIsOrgValues(false);
    setOrgValuesExist(false);
    setIsOrgValuesSelected(false);
  }

  // Reset only existing custom tag main states.
  // Used when custom tag category is deselected or changed. 
  const resetCustomTagStates = () => {
    getExistingCustomTags(null);
    setNewCustomTagCategory(null);
    setCategorySelected(false);
    setIsOrgValues(false);
    setIsOrgValuesSelected(false);
    setExistingCustomTags([]);
    setNewCustomTagColor(null);
    setNewCustomTagPreviewColor(null);
    setColorSelected(false);
  }

  // AsyncTypeAhead x Algolia Function to search for organization. 
  // Call Algolia function to retrieve objectId and name of organization. 
  // Param query refers to what the user enters into the AsyncTypeAhead Textbox.
  // Assign hits obtained from Algolia to state organizationOptions. 

  const handleOrganizationSearch = async (query) => {
    setIsLoading(true);
    await orgIndex.search(query, {
      attributesToRetrieve: ['objectID', 'name'],
    }).then(({ hits }) => {
      const retrievedOrganizationOptions = hits.map((org) => ({
        objectID: org.objectID,
        name: org.name,
      }));
      setOrganizationOptions(retrievedOrganizationOptions);
    });
    setIsLoading(false);
  };

  // onChange function to populate the users and custom tag categories options after admin  
  // enters a new organization using the Algolia search bar.
  const getUsersAndExistingTagCategories = async (selectedOrg) => {
    setExistingCustomTagsPending(true);

    if (selectedOrg != null) {
      const selectedOrganizationId = selectedOrg?.objectID;
      setOrganizationId(selectedOrganizationId);
      setOrganizationSelected(true);

      if (selectedOrganizationId != null) {
        const data = {
          "organizationId": selectedOrganizationId
        }

        const getUsersByOrg = functions.httpsCallable("callGetOrganizationUsers");
        await getUsersByOrg(data).then(res => {
          const result = JSON.parse(JSON.stringify(res.data));
          if (result?.statusCode === 200) {
            setUsers(result?.organizationUsersArray);
          }
        });

        const getExistingTagCategories = functions.httpsCallable("callGetExistingCustomTagCategories");
        await getExistingTagCategories(data).then(res => {
          const result = JSON.parse(JSON.stringify(res.data));
          if (result?.statusCode === 200) {
            setExistingCustomTagsCategories(result?.existingCustomTagsCategories);
            const existingCustomTagsCategoriesList = result?.existingCustomTagsCategories;

            // Check for existence of organization values category. 
            const orgValuesExistCheck = existingCustomTagsCategoriesList.every((category) => {
              return (!category.isOrgValues)
            });

            // If isOrgValuesCheck is false, meaning there is an organization values category, set
            // orgValuesExist to be true, which disables the creation of another organization values category.
            if (!orgValuesExistCheck) {
              setOrgValuesExist(true);
            } else {
              setOrgValuesExist(false);
            }
          };
        });
      }
    } else {
      resetStates();
    }
    setExistingCustomTagsPending(false);
  }

  // onChange function to populate the states of existing custom tags, custom tag category, 
  // and custom tag color after admin selects a custom tag category.
  const getExistingCustomTags = async (selectedCustomTagCategory) => {
    setExistingCustomTagsPending(true);

    if (selectedCustomTagCategory != null) {
      // Set new custom tag category to the category selected and prevent further changes.  
      setNewCustomTagCategory(selectedCustomTagCategory);
      setCategorySelected(true);

      // Filter through existing custom tag categories to find match.
      const selectedCustomTagCategoryArray = existingCustomTagsCategories.filter((category) => {
        return category.customTagCategory == selectedCustomTagCategory;
      });

      const selectedCategory = selectedCustomTagCategoryArray[0];

      // If category is organization values, set isOrgValues to true.
      if (selectedCategory.isOrgValues == true) {
        setIsOrgValues(true);
      } else {
        setIsOrgValues(false);
      }

      // Set isOrgValuesSelected to be true to prevent further changes. 
      setIsOrgValuesSelected(true);

      const data = {
        "organizationId": organizationId,
        "selectedCustomTagCategory": selectedCustomTagCategory
      }

      // Retrieve existing custom tags, set new custom tag color
      // to the category color, and prevent further changes.
      const getExistingCustomTagsFunction = functions.httpsCallable("callGetExistingCustomTags");
      await getExistingCustomTagsFunction(data).then(res => {
        const result = JSON.parse(JSON.stringify(res.data));
        if (result?.statusCode === 200) {
          if (result?.customTagsArray != null && result?.customTagsArray.length > 0) {
            const existingCustomTagsArray = result.customTagsArray;
            setExistingCustomTags(existingCustomTagsArray.sort(sortAlphabetically));
            setNewCustomTagColor(existingCustomTagsArray[0]?.color);

            const filteredColors = newTagColors.filter((color) => {
              return color.value === existingCustomTagsArray[0]?.color
            });
            const previewColor = filteredColors[0]?.hexadecimalCode;
            setNewCustomTagPreviewColor(previewColor)

            setColorSelected(true);
          } else {
            setExistingCustomTagsNotFound(true);
            setExistingCustomTagMessage('No custom tags found!');
          }
        }
      });
    }
    setExistingCustomTagsPending(false);
  }

  // onClick function used in three buttons in ExistingCustomTagCategories component
  // and ExistingCustomTagsPreview component to show forms to add new custom tags 
  // if no custom tag category/ tags are found
  // or the admin wants to create a new custom tag collection. 
  const selectToAddNewCustomTags = () => {
    setExistingCustomTags([]);
    setAddNewCustomTagCategory(true)
    setExistingCustomTags([]);
    setNewCustomTagCategory(null);
    setNewCustomTagColor(null);
    setNewCustomTagPreviewColor(null);
    setColorSelected(false);
    setCategorySelected(false);
    setIsOrgValues(false);
    setIsOrgValuesSelected(false);
  }

  // onClick function uses radio buttons to toggle between showing forms to add new custom tags or
  // confirming tag selection and moving on to select users.
  // Function differs from selectToAddNewCustomTags in that it doesn't reset the existing tags states to null. 
  const confirmExistingTagSelection = status => {
    if (status === constants.CONFIRM_EXISTING_TAG_SELECTION) {
      setFinalCustomTagsToAdd(existingCustomTags);
      setAddNewCustomTagCategory(false);
      setCustomTagsConfirmed(true);
      setCustomTagsConfirmedMessage('Custom Tags Confirmed!');
    }
    else if (status === constants.ADD_NEW_TAGS_TO_TAG_COLLECTION) {
      setAddNewCustomTagCategory(true);
      setCustomTagsConfirmed(false);
    }
  }

  // onChange function to set correct color naming after color is selected.  
  const addNewCustomTagColor = colorArrIndex => {
    const newColor = newTagColors[colorArrIndex];

    if (newColor != null) {
      setColorPreview(true);
      setNewCustomTagColor(newColor.value);
      setNewCustomTagPreviewColor(newColor.hexadecimalCode);
    } else {
      setColorPreview(false);
      setNewCustomTagColor(null);
      setNewCustomTagPreviewColor(null);
    }
  }

  const maxLengthValidate = () => {
    if (newCustomTagName !== null & newCustomTagName.length > 35) {
      setErrorNewTagName("Exceeding maxlength of 35 characters, please use a shorter tag name");
      setValidateNewCustomTagNameLength(false);
      setInvalidNewCustomTagName(true);
    } else {
      setValidateNewCustomTagNameLength(true);
      setInvalidNewCustomTagName(false);
    }
  }

  // onClick function for the 'Add Custom Tag' button. Validates that custom tag name, 
  // color, category and sentiment are set before adding the custom tag into the new custom tag array. 
  const validateNewCustomTag = () => {
    let validateNewCustomTagName = false;
    let validateNewCustomTagColor = false;
    let validateNewCustomTagCategory = false;
    let validateNewCustomTagSentiment = true;

    // Check if tag name is set. 
    if (newCustomTagName != null && newCustomTagName.length > 0 && validateNewCustomTagNameLength) {
      validateNewCustomTagName = true;
      setInvalidNewCustomTagName(false);
    } else {
      setInvalidNewCustomTagName(true);
    }

    // Check if tag color is set. 
    if (newCustomTagColor != null && newCustomTagColor.length > 0) {
      validateNewCustomTagColor = true;
      setInvalidNewCustomTagColor(false);
    } else {
      setInvalidNewCustomTagColor(true);
    }

    // Check if tag category is set. 
    if (newCustomTagCategory != null && newCustomTagCategory.length > 0) {
      validateNewCustomTagCategory = true;
      setInvalidNewCustomTagCategory(false);
    } else {
      setInvalidNewCustomTagCategory(true);
    }

    // Check if at leaset one tag sentiment is set. 
    if (positiveSentiment === false && negativeSentiment == false && neutralSentiment == false) {
      validateNewCustomTagSentiment = false;
    }

    // If all are set, add custom tag to array.
    if (validateNewCustomTagName && validateNewCustomTagColor && validateNewCustomTagCategory && validateNewCustomTagSentiment) {
      addCustomTagToArray();
      setCustomTagsConfirmedMessage(null);
      setColorSelected(true);
      setCategorySelected(true);
      setIsOrgValuesSelected(true);
      setColorPreview(false);
    } else {
      setAddCustomTagStatus('danger');
      setAddCustomTagMessage('One or more fields are missing!');
    }
  }

  // Function triggered by 'validateNewCustomTag' when there are no errors. 
  // Adds a new custom tag into the new custom tags array. 
  // Reset tag sentiments and name states after it has been successfully added into the array.
  const addCustomTagToArray = () => {
    let positiveTag = { "name": newCustomTagName, "is_custom": false, "category": newCustomTagCategory, "sentiment": "positive", "type": "Celebrate", "color": newCustomTagColor };
    let negativeTag = { "name": newCustomTagName, "is_custom": false, "category": newCustomTagCategory, "sentiment": "negative", "type": "Improve", "color": newCustomTagColor };
    let neutralTag = { "name": newCustomTagName, "is_custom": false, "category": newCustomTagCategory, "sentiment": "neutral", "type": "Goal", "color": newCustomTagColor };

    let array = [...newCustomTags];
    if (positiveSentiment) {
      array = [...array, positiveTag];
    }
    if (negativeSentiment) {
      array = [...array, negativeTag];
    }
    if (neutralSentiment) {
      array = [...array, neutralTag];
    }
    setNewCustomTags(array);
    setAddCustomTagMessage(`Successfully added ${newCustomTagName} to the ${newCustomTagCategory} custom tag list!`);
    setAddCustomTagStatus("success");
    setPositiveSentiment(false);
    setNegativeSentiment(false);
    setNeutralSentiment(false);
    setNewCustomTagName(null);
  }

  // onClick function for the 'Delete' button in the 'Custom Tags' component table.
  // Remove selected custom tag by index from the custom tags array.
  // Index refers to the key of the 'Delete' button upon initialising the table.
  const removeCustomTag = (index) => {
    setAddCustomTagStatus(null);
    setAddCustomTagMessage(null);
    let updatedCustomTags = newCustomTags;
    updatedCustomTags.splice(index.index, 1);
    setNewCustomTags([...updatedCustomTags]);
  }

  // onClick function for 'Select Users' button. Validates that there are custom tags 
  // to be added by checking whether both existing and new custom tag arrays are not empty
  // before confirming tag selection.
  const validateTagsArray = () => {
    let existingTagsArrayIsEmpty = false;
    let newTagsArrayIsEmpty = false;

    if (existingCustomTags == [] || existingCustomTags.length === 0) {
      existingTagsArrayIsEmpty = true;
    }
    if (newCustomTags == [] || newCustomTags.length === 0) {
      newTagsArrayIsEmpty = true;
    }

    if (existingTagsArrayIsEmpty && newTagsArrayIsEmpty) {
      setCustomTagsConfirmedMessage('Please add at least one custom tag!')
    } else {
      confirmNewTagSelection();
    }
  }

  // Upon confiriming tag selection, function combines the existing and new custom tags 
  // into a final custom tags array which will be sent when addCustomTags function is called. 
  const confirmNewTagSelection = () => {
    let array = existingCustomTags;
    if (newCustomTags != null && newCustomTags.length > 0) {
      array = array.concat(newCustomTags);
    }
    setFinalCustomTagsToAdd(array);
    setAddNewCustomTagCategory(false);
    setCustomTagsConfirmed(true);
    setCustomTagsConfirmedMessage('Custom Tags Confirmed!');
  }

  // AsyncTypeAhead x Algolia Function
  // Call Algolia function to retrieve organization user objectId, name and email
  // filtered by Algolia orgId. 
  // Param query refers to what the user enters into the AsyncTypeAhead Textbox
  // Assign hits obtained from Algolia to userOptions and assigned it to state userOptions.

  const handleSearch = async (query) => {
    setIsLoading(true);
    await index.search(query, {
      filters: `orgId: ${organizationId}`,
      attributesToRetrieve: ['objectID', 'name', 'email'],
    }).then(({ hits }) => {
      const retrievedOptions = hits.map((user) => ({
        objectId: user.objectID,
        name: user.name,
        email: user.email,
      }));
      setUserOptions(retrievedOptions);
    });
    setIsLoading(false);
  };

  // onClick function uses radio buttons to toggle between populating selectedUsers state and users table
  // with all organization users or showing dropdown form to add individual users. 
  const addUsersMethod = method => {
    setUsersMethodSelected(true);
    if (method === constants.SELECT_ALL_USERS) {
      setAddIndividualUsers(false);
      setSelectedUsers(users);
    } else if (method === constants.INDIVIDUAL_USERS) {
      setSelectedUsers([]);
      setAddIndividualUsers(true);
    }
  }

  // onChange function to populate the new user states after 
  // admin selects a new individual user using Algolia search. 
  const addOrgUser = (selectedUser) => {

    // Check if selectedUser is not null
    if (selectedUser != null) {
      setAddUserMessage(null);
      setAddUserStatus(null);
      setNewName(selectedUser.name);
      setNewEmail(selectedUser.email);
      setNewUserId(selectedUser.objectId);
    } else {
      setNewUserId(null);
      setNewName(null);
      setNewEmail(null);
    }
  }

  // onClick function for 'Add User' button. Validates that user id, name, and email 
  // are set before adding new user into the selected users array. 
  const validateUser = () => {
    let validateUserId = false;
    let validateUserName = false;
    let validateUserEmail = false;

    if (newUserId != null && newUserId.length > 0) {
      validateUserId = true;
    }

    if (newName != null && newName.length > 0) {
      validateUserName = true;
    }

    if (newEmail != null && newEmail.length > 0) {
      validateUserEmail = true;
    }

    if (validateUserId && validateUserName && validateUserEmail) {
      addUserToArray();
    } else {
      setAddUserStatus('danger');
      setAddUserMessage('Please select a user!');
    }
  }

  // Function triggered by 'validateUser' when there are no errors. 
  // Adds a new user into the selected users array. 
  // Reset user states after user has been successfully added into the array.
  const addUserToArray = () => {
    setAddUserMessage(`Successfully added ${newName} (${newEmail}) to the users list!`);
    setAddUserStatus("success");
    let array = [...selectedUsers, { "uid": newUserId, "name": newName, "email": newEmail }];
    setSelectedUsers(array);
    setNewUserId(null);
    setNewName(null);
    setNewEmail(null);
  }

  // onClick function for the 'Delete' button in the 'Users' component table.
  // Remove selected user by index from the users array.
  // Index refers to the key of the 'Delete' button upon initialising the table.
  const removeUser = (index) => {
    setAddUserMessage(null);
    setAddUserStatus(null);
    let updatedUsers = selectedUsers;
    updatedUsers.splice(index.index, 1);
    setSelectedUsers([...updatedUsers]);
  }

  // onClick function for the 'Submit' button. To validate if at least one user and one custom tag 
  // have each been created and organization id is available before calling sendNewCustomTags,
  // which triggers a Cloud Function call to 'addCustomTags'. 
  const validateSubmission = async () => {
    setAddCustomTagPending(true);
    let validateUsersArray = false;
    let validateCustomTagsAdded = false;
    let validateOrganizationId = false;

    // Check if selected users array is not empty.
    if (selectedUsers != null && selectedUsers.length > 0) {
      validateUsersArray = true;
    } else {
      setResponseType("danger");
      setResponseMessage('Please add at least one user!');
    }

    if (finalCustomTagsToAdd != null && finalCustomTagsToAdd.length > 0) {
      validateCustomTagsAdded = true;
    } else {
      setResponseType('danger');
      setResponseMessage('Please add at least one custom tag!');
    }

    if (organizationId != null && organizationId.length > 0) {
      validateOrganizationId = true;
    } else {
      setResponseType('danger');
      setResponseMessage('Organization Id is missing!');
    }

    if (validateUsersArray && validateCustomTagsAdded && validateOrganizationId) {
      sendNewCustomTags();
    } else {
      setAddCustomTagPending(false);
    }
  }

  // Function triggered by 'validateSubmission' when there are no errors. 
  // Package users organization name, users array, newCustomTags array, and newCustomTagCategory into an object 'data'. 
  // Makes Cloud Function call to 'addCustomTags' and passes 'data' as function parameter.
  const sendNewCustomTags = async () => {
    setResponseType(null);
    setResponseMessage(null);
    setAddCustomTagPending(true);

    // Create an array of user ids to pass into request for addCustomTags.
    let userIds = [];
    for (const selectedUser of selectedUsers) {
      userIds.push(selectedUser.uid);
    }

    const data = {
      "userIds": userIds,
      "organizationId": organizationId,
      "newCustomTags": finalCustomTagsToAdd,
      "customTagCategory": newCustomTagCategory,
      "isOrgValues": isOrgValues
    }

    const addCustomTagToUser = functions.httpsCallable("callAddCustomTags");
    await addCustomTagToUser(data).then(res => {
      const result = JSON.parse(JSON.stringify(res.data));
      if (result.statusCode === 200) {
        setResponseType("success");
        setResponseMessage(result?.statusMessage);
      } else {
        setResponseType("danger");
        setResponseMessage(result?.statusMessage);
      }
      setAddCustomTagPending(false);
    });
  }

  return (
    <div className="page-container">

      <Container className="d-flex justify-content-center my-4">
        <h2>Create Custom Tags</h2>
      </Container>
      <h6>Instructions</h6>
      <ul className="notification-hint-padding mb-5">
        <li>Step 1: Enter an organization.</li>
        <li>Step 2: Select the custom tag collection you would like to add to users or create a new custom tag collection.</li>
        <ul>
          <li>To add new custom tags to <em>an existing collection</em>, please select the custom tag collection first.</li>
          <li>You will later be prompted to add additional tags to this collection.</li>
        </ul>
        <li>Step 3: Once custom tags have been confirmed, select the users to add the tags to.</li>
        <li>Step 4: Verify the lists of customs tags and users before clicking "Submit" as no changes can happen after. </li>
      </ul>

      <Form.Group>
        <h5 className="my-3"><b>Step 1: Search for an Organization</b></h5>
        <OrganizationSearch
          id={'find-organization-from-algolia'}
          className="pr-0 w-100"
          isLoading={isLoading}
          onSearch={handleOrganizationSearch}
          minLength={3}
          options={organizationOptions}
          onChange={getUsersAndExistingTagCategories}
        />
      </Form.Group>

      {
        (organizationSelected && !addNewCustomTagCategory && !customTagsConfirmed) &&
        <Form.Group className="my-5">
          < ExistingCustomTagCategories />
          {existingCustomTagsPending && <LoadingCircle />}
        </Form.Group>
      }

      {(!addNewCustomTagCategory && !customTagsConfirmed) &&
        <>
          <Row className="d-flex flex-wrap">
            {<ExistingCustomTagsPreview />}
          </Row>
        </>
      }

      {(!customTagsConfirmed && existingCustomTags != null && existingCustomTags.length > 0 && !addNewCustomTagCategory) &&
        <Form className="my-4 d-flex justify-content-center">
          <Form.Group>
            <Form.Check className="px-4"
              inline
              name="tag-selection-confirmation"
              label="Confirm above existing tag selection"
              value={constants.CONFIRM_EXISTING_TAG_SELECTION}
              type='radio'
              onClick={event => confirmExistingTagSelection(event.target.value)}
              id='add-all-users'
            />
            <Form.Check className="px-4"
              inline
              name="tag-selection-confirmation"
              label={`Add additional tags to the ${newCustomTagCategory} collection`}
              value={constants.ADD_NEW_TAGS_TO_TAG_COLLECTION}
              type='radio'
              onClick={event => confirmExistingTagSelection(event.target.value)}
              id='add-individual-users'
            />
          </Form.Group>
        </Form>
      }

      {addNewCustomTagCategory &&
        <div>
          {/* New Custom Tag Category */}
          <Form.Group>
            <Form.Label><b>Tag Category</b></Form.Label>
            <Form.Text>This will be the category for all the tags.</Form.Text>
            <Form.Control
              type="text"
              placeholder="Enter Tag Category (e.g. OnLoop Values)"
              value={newCustomTagCategory || ''}
              onChange={(event) => { setNewCustomTagCategory(event.target.value); }}
              disabled={categorySelected}
              isInvalid={invalidNewCustomTagCategory}
            />
          </Form.Group>


          {/* New Custom Tag Category Type */}
          <Form.Group>
            <Form.Label><b>Tag Category Type</b></Form.Label>
            <Form.Text>Please check this box ONLY if this collection is an organization's values. This will be set for all the tags.</Form.Text>
            <Form.Text>There can only be one collection of organization values. If the organization already has a collection of organization values, this option cannot be selected. </Form.Text>

            <Form.Check className="mt-3"
              inline
              name="tag-category-type"
              inline label="Organization Values"
              value={true}
              type="checkbox"
              onClick={event => {
                setIsOrgValues(event.currentTarget.checked)
              }}
              id='set-category-as-organization-values'
              disabled={orgValuesExist || isOrgValuesSelected}
              checked={isOrgValues}
            />
          </Form.Group>

          {/* New Custom Tag Color */}
          <Form.Group>
            <Form.Label><b>Select Custom Tag Color</b></Form.Label>
            <Form.Text>This will be the color for all the tags. If adding new tags to an existing tag collection, the color will be same as pre-existing tags.</Form.Text>
            <Form.Control as="select"
              onChange={(event) => {
                if (event.target.value != -1) {
                  addNewCustomTagColor(event.target.value)
                  setInvalidNewCustomTagColor(false);
                } else {
                  setColorPreview(false);
                  setNewCustomTagColor(null);
                  setNewCustomTagPreviewColor(null);
                }
              }}
              isInvalid={invalidNewCustomTagColor}
              disabled={colorSelected}
            >
              <option value={-1}>Select One</option>
              {
                newTagColors.map((color, index) => (
                  <option value={index}>
                    {color.displayValue}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>

          {colorPreview &&
            <div className="d-flex my-3 flex-column align-items-start">
              <Form.Label><b>Color Sample: Please note that the sample is just an approximation of what the final custom tag will look like in the app.</b></Form.Label>
              <div className='text-center my-2'>
                <b className="tag-preview" style={tagPreviewColor}>
                  Sample Custom Tag</b>
              </div>
            </div>
          }

          {/* New Custom Tag Name */}
          <Form.Group>
            <Form.Label><b>Tag Name</b></Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Tag Name"
              value={newCustomTagName || ''}
              onChange={(event) => {
                setAddCustomTagMessage(null);
                setErrorNewTagName(null);
                setNewCustomTagName(event.target.value);
              }}
              onKeyUp={maxLengthValidate}
              isInvalid={invalidNewCustomTagName} />
            <Form.Control.Feedback type="invalid">
              {errorNewTagName}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Row>
              {/* New Custom Tag Sentiment */}
              <Col lg={2}>
                <Form.Label><b>Sentiment</b></Form.Label>
              </Col>
              <Col>
                <Form.Check
                  type="checkbox"
                  id={"Positive tag sentiment"}
                  inline label={"Positive"}
                  checked={positiveSentiment}
                  value={positiveSentiment}
                  onChange={event => {
                    setPositiveSentiment(event.currentTarget.checked)
                  }}
                />
                <Form.Check
                  type="checkbox"
                  id={"Negative tag sentiment"}
                  inline label={"Negative"}
                  checked={negativeSentiment}
                  value={negativeSentiment}
                  onChange={event => {
                    setNegativeSentiment(event.currentTarget.checked)
                  }}
                />
                <Form.Check
                  type="checkbox"
                  id={"Neutral tag sentiment"}
                  inline label={"Neutral"}
                  checked={neutralSentiment}
                  value={neutralSentiment}
                  onChange={event => {
                    setNeutralSentiment(event.currentTarget.checked)
                  }}
                />
              </Col>
            </Row>
          </Form.Group>

          <Row>
            {/* Add Custom Tag Button */}
            <Row className="justify-content-center w-100">
              <Button variant="primary label-alignment mt-1" onClick={() => { validateNewCustomTag() }}>
                Add Custom Tag
              </Button>
            </Row>
            <Row className="justify-content-center w-100 text-align mt-2">
              {addCustomTagMessage &&
                <Alert variant={addCustomTagStatus}>{addCustomTagMessage}</Alert>
              }
            </Row>
          </Row>


          {/* EXISTING TAG AND NEW TAG TABLES */}
          <Row className="d-flex flex-column flex-lg-row mt-5">
            {/*Existing Tag Table */}
            <Col>
              <div>
                <Form.Label className="text-center w-100">
                  <h6>{newCustomTagCategory} Existing Custom Tags</h6>
                </Form.Label>
                {/* <Table bordered hover> */}
                <Container>
                  <hr className='my-2' />
                  <Row className='my-4 d-flex justify-content-around'>
                    <Col xs={7}>
                      <h6>Name</h6>
                    </Col>
                    <Col xs={3}>
                      <h6 className="text-center">Type</h6>
                    </Col>
                    <Col xs={2}>
                    </Col>
                  </Row>
                  {existingCustomTags &&
                    <ExistingCustomTags />
                  }
                  {/* </Table> */}
                </Container>
              </div>
            </Col>

            {/*New Custom Tag Table */}
            <Col>
              <div>
                <Form.Label className="text-center w-100">
                  <h6>{newCustomTagCategory} New Custom Tags</h6>
                </Form.Label>
                {/* <Table bordered hover> */}
                <Container>
                  <hr className='my-2' />
                  <Row className='my-4 d-flex justify-content-around'>
                    <Col xs={7}>
                      <h6>Name</h6>
                    </Col>
                    <Col xs={3}>
                      <h6 className="text-center">Type</h6>
                    </Col>
                    <Col xs={2}>
                      <h6>Remove</h6>
                    </Col>
                  </Row>
                  {newCustomTags &&
                    <NewCustomTags />
                  }
                  {/* </Table> */}
                </Container>
              </div>
            </Col>
          </Row>
          <hr className='my-2' />
          <Container className="d-flex flex-column align-items-center">
            {/*Confirm New Tags Button */}
            <h6 className="text-center my-4">Click the button below only if you've completed adding new tags.</h6>
            <Button onClick={() => validateTagsArray()} className="btn btn-primary">
              Select Users
            </Button>
            {customTagsConfirmedMessage &&
              <Row className="justify-content-center w-50 my-3">
                <Alert variant={"danger"}>{customTagsConfirmedMessage}</Alert>
              </Row>
            }
          </Container>
        </div>
      }

      {customTagsConfirmed &&
        <Container className="d-flex flex-column align-items-center py-4">
          {customTagsConfirmedMessage &&
            <Row className="justify-content-center w-50 my-3">
              <Alert variant={"success"}><Alert.Heading>{customTagsConfirmedMessage}</Alert.Heading></Alert>
            </Row>
          }
          <Row>
            <h5 className="text-center mb-4">Step 3: Select users to add custom tags to.</h5>
          </Row>
          <Row>
            <Form className="d-flex justify-content-center">
              <Form.Group>
                <Form.Check className="px-4"
                  inline
                  name="add-users-type"
                  label="Add All Users in Organization"
                  value={constants.SELECT_ALL_USERS}
                  type='radio'
                  onClick={event => addUsersMethod(event.target.value)}
                  id='add-all-users'
                />
                <Form.Check className="px-4"
                  inline
                  name="add-users-type"
                  label="Add Individual Users"
                  value={constants.INDIVIDUAL_USERS}
                  type='radio'
                  onClick={event => addUsersMethod(event.target.value)}
                  id='add-individual-users'
                />
              </Form.Group>
            </Form>
          </Row>
        </Container>
      }

      { /* Form to search for individual users to add custom tags to. */}
      {addIndividualUsers &&
        <Form>
          <Row className="w-100 mx-0 mb-3">
            <Col>
              <Form.Group>
                <Form.Label><b>Select a user in the organization to add custom tags to.</b></Form.Label>
                <UserSearch
                  id={'find-organization-user'}
                  className="pr-0 w-100"
                  isLoading={isLoading}
                  labelKey="email"
                  minLength={3}
                  onSearch={handleSearch}
                  options={userOptions}
                  placeholder="name@example.com"
                  onChange={(selected) => {
                    addOrgUser(selected);
                  }} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Container className="justify-content-center w-100">
              <Row className="justify-content-center w-100">
                <Button variant="primary label-alignment mt-1" onClick={() => { validateUser(); }}>
                  Add User
                </Button>
              </Row>
              <Row className="justify-content-center w-100 text-align mt-2">
                {addUserMessage &&
                  <Alert variant={addUserStatus}>{addUserMessage}</Alert>
                }
              </Row>
            </Container>
          </Row>
        </Form>
      }

      {/*User Table */}
      {usersMethodSelected &&
        <>
          <Row className="d-flex flex-column flex-lg-row mt-5">
            <Col>
              <div>
                <Form.Label className="text-center w-100">
                  <h6>Users</h6>
                </Form.Label>
                {/* <Table bordered hover> */}
                <Container>
                  <hr className='mt-2 mb-2' />
                  <Row className='mb-2 d-flex justify-content-around'>
                    <Col xs={7}>
                      <b>Name</b>
                    </Col>
                    <Col xs={2}>
                      <b>Remove</b>
                    </Col>
                  </Row>
                  {
                    Users
                    && <Users />
                  }
                  {/* </Table> */}
                </Container>
              </div>
            </Col>

            {/*Custom Tag Table */}
            <Col className="mt-5 mt-lg-0">
              <div>
                <Form.Label className="text-center w-100">
                  <h6>{newCustomTagCategory} Custom Tags</h6>
                </Form.Label>
                {/* <Table bordered hover> */}
                <Container>
                  <hr className='mt-2 mb-2' />
                  {existingCustomTags &&
                    <>
                      <Row className="justify-content-center my-4">
                        <p>Existing Custom Tags</p>
                      </Row>
                      <Row className='mb-2 d-flex justify-content-around'>
                        <Col xs={7}>
                          <h6>Name</h6>
                        </Col>
                        <Col xs={3}>
                          <h6 className="text-center">Type</h6>
                        </Col>
                        <Col xs={2}>
                        </Col>
                      </Row>
                      {<ExistingCustomTags />}
                    </>
                  }
                  <hr className='mt-2 mb-2' />
                  {newCustomTags &&
                    <>
                      <Row className="justify-content-center my-4">
                        <p>New Custom Tags</p>
                      </Row>
                      <Row className='mb-2 d-flex justify-content-around'>
                        <Col xs={7}>
                          <h6>Name</h6>
                        </Col>
                        <Col xs={3}>
                          <h6 className="text-center">Type</h6>
                        </Col>
                        <Col xs={2}>
                        </Col>
                      </Row>
                      <NewCustomTags />
                    </>
                  }
                  {/* </Table> */}
                </Container>
              </div>
            </Col>
          </Row>
          <div className="pt-5">
            <Row className="d-flex flex-column align-items-center">
              <h5 className="my-3">
                <b>Step 4: Please ensure all details are correct - There is no going back!</b>
              </h5>
            </Row>
            <Row className="justify-content-center">
              {!addCustomTagPending &&
                <Button variant="primary" onClick={() => validateSubmission()} className="btn btn-primary">
                  Submit!
                </Button>
              }
            </Row>
            {addCustomTagPending &&
              <LoadingCircle />
            }
            {responseType &&
              <Row className="justify-content-center mt-3">
                <Alert className="text-center" variant={responseType}>{responseMessage}</Alert>
              </Row>
            }
          </div>
        </>
      }
    </div >
  )
}

export default CreateCustomTags;