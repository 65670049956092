import firebase from "firebase";
import React, { useState } from "react";
import { Alert, Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import LoadingCircle from "../components/loading";
import "./page-styles.css";

var firebaseApp = firebase.app();
var functions = firebaseApp.functions("asia-northeast1");

const ReferralCode = () => {
  const [pending, setPending] = useState(false);

  const [referralCode, setReferralCode] = useState(null);
  const [referrerName, setReferrerName] = useState(null);

  const [errorTextCode, setErrorTextCode] = useState(null);
  const [errorTextName, setErrorTextName] = useState(null);

  const [validateInputCode, setValidInputCode] = useState(true);
  const [validateInputName, setValidInputName] = useState(true);

  const [responseCode, setResponseCode] = useState(null);
  const [responseMessage, setResponseMessage] = useState(null);

  const generateReferralCode = (data) => {
    setResponseMessage(null);
    setPending(true);
    const referralCode = functions.httpsCallable("callGenerateReferralCode");
    referralCode(data).then(function (res) {
      const result = JSON.parse(JSON.stringify(res.data));
      if (result.statusCode === 200) {
        setResponseMessage("You have successfully generated the referral code.");
      } else {
        setResponseCode(result.statusCode);
        setResponseMessage(result.errorMsg);
      }
      setPending(false);
    });
  };

  const validate = () => {
    if (referralCode === null || referralCode.length !== 5) {
      setErrorTextCode("Please enter a 5 character code");
      setValidInputCode(false);
    }
    if (referrerName === null || referrerName.length === 0) {
      setErrorTextName("Please do not leave this field blank");
      setValidInputName(false);
    }

    if (validateInputCode && validateInputName) {
      const data = {
        codeId: referralCode,
        referrer: referrerName,
      };
      generateReferralCode(data);
    }
  };

  const referralCodeValue = (event) => {
    setErrorTextCode(null);
    setReferralCode(event.target.value);
  };

  const referrerNameValue = (event) => {
    setErrorTextName(null);
    setReferrerName(event.target.value);
  };

  return (
    <div className="page-container">
      <Container className="d-flex justify-content-center my-4">
        <h2>Generate Referral Code</h2>
      </Container>
      <Container>
        <Col className="p-0">
          <Card className="mx-auto">
            <Card.Body className="flex-column">
              <Container className="my-4 w-md-75">
                <Form.Group>
                  <Form.Label>Referral Code</Form.Label>
                  <Form.Control
                    onChange={referralCodeValue}
                    required
                    value={referralCode}
                    placeholder="Please enter a 5 character code"
                    isInvalid={!!errorTextCode ? true : false}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorTextCode}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Referral Name</Form.Label>
                  <Form.Control
                    onChange={referrerNameValue}
                    required
                    value={referrerName}
                    placeholder="Please enter referrer's name"
                    isInvalid={!!errorTextName ? true : false}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorTextName}
                  </Form.Control.Feedback>
                </Form.Group>
                {!pending && <Row className="justify-content-center mt-5">
                  <Button onClick={validate}>
                    Generate Referral Code
                  </Button>
                </Row>}
                {pending &&
                  <Row className="justify-content-center">
                    <Col xs="3"><LoadingCircle /></Col>
                  </Row>
                }
                {responseMessage &&
                  <Alert className="" align="center" variant={"subtitle1"}>{responseMessage}</Alert>
                }
              </Container>
            </Card.Body>
          </Card>
        </Col>
      </Container>
    </div>

  );
};

export default ReferralCode;