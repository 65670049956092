import React from "react";
import Form from "react-bootstrap/Form";

const OptionSelector = (props) => {

  return (
      <Form.Check className="px-4"
        inline
        type='radio'
        name={props.name}
        label={props.label}
        value={props.value}
        onClick={props.onClick}
        id={props.id}
        disabled={props.disabled}
      />
  )
}

export default OptionSelector;