import { useContext, useEffect, useState } from "react";
import { Alert, Button, Container, Form, Row } from "react-bootstrap";
import LoadingCircle from "../components/loading";
import { AuthContext } from "../services/auth";
import axios from "axios";
import firebase from "firebase";
import Select from 'react-select'
import DatePicker from "react-datepicker";

var firebaseApp = firebase.app();
var functions = firebaseApp.functions("asia-northeast1");

const GeneratePrismSummary = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser, bearerToken } = useContext(AuthContext);
  const [organizationLists, setOrganizationLists] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [colleaguesList, setColleagueList] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedColleagueId, setSelectedColleagueId] = useState("");

  const [selectedOrgObj, setSelectedOrgObj] = useState({});
  const [selectedUserObj, setSelectedUserObj] = useState({});
  const [selectedColleagueIdObj, setSelectedColleagueIdObj] = useState({});
  const [errorMessage,setErrorMessage] = useState(null);
  const baseURL = `${process.env.REACT_APP_API_V2_URL}/api/graphql`;
  const [isOrgPending, setOrgPending] = useState(true);
  const [isUserPending, setUserPending] = useState(true);
  const [enableSubmit, setEnableSumit] = useState(false);
  const [overrideUserEmailTo, setOrverrideUserEmailTo] = useState('')
  const [excludePrivateCapture, setExcludePrivateCaptures] = useState(false)
  const [isColleaguePending, setColleaguePending] = useState(true);
  const [successMessage, setSuccessMessage] = useState("");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  useEffect(() => {
    setTimeout(getOrgs,2000)
  }, []);

  const getOrgs = async () => {
    
    setOrgPending(true);
    setIsLoading(true);
    const graphqlQuery = {
        "query": `query {
          orgs {
            id
            name
          }
        }`,
        "variables": {}
    };
    axios.defaults.headers.post['Authorization'] = `Bearer ${bearerToken}`;
    axios.defaults.headers.post['content-type'] = `application/json`;
    // Even if endpoint is not expecting data, pass null as the data.
    axios.post(baseURL, graphqlQuery)
      .then(function (response) {
        const result = response?.data;
        if (response?.status === 200) {
          const orgs = response?.data?.data?.orgs;
          if(orgs.length > 0) {
            orgs.sort(function(a, b){
              var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
              if (nameA < nameB) 
               return -1;
              if (nameA > nameB)
               return 1;
              return 0;
             });
             let orgsOptions = orgs.map((org) => ({value: org.id, label: org.name}));
              
            setOrganizationLists(orgsOptions)
          }
          setOrgPending(false);
          setIsLoading(false);
        } 
      })
      .catch(function (error) {
        setErrorMessage(error?.response?.data?.message)
        console.error(error?.response?.data?.message);
        setOrgPending(false);
        setIsLoading(false);
      });
  }

  const getUsersByOrg = async (org) => {
    setSelectedOrgObj(org)
    const orgId = org?.value;
    setSelectedOrg(orgId)
    setUsersList([]);
    setIsLoading(true);
    setSelectedUser(false)
    setSelectedColleagueId(false)
    setColleagueList([])
    setUserPending(true)
    setColleaguePending(true)
    setEnableSumit(false)
    setSelectedUserObj({})
    setSelectedColleagueIdObj({})
    if(orgId == null) return;

    const graphqlQuery = {
      "query": `query BIO_QUERY($orgId: String!) {
        org(id: $orgId) {
          id
          name
          users {
            id
            email
            name
          }
          
        }
      }
      `,
      "variables": {orgId}
  };
  axios.defaults.headers.post['Authorization'] = `Bearer ${bearerToken}`;
  axios.defaults.headers.post['content-type'] = `application/json`;
  // Even if endpoint is not expecting data, pass null as the data.
  axios.post(baseURL, graphqlQuery)
    .then(function (response) {
      const result = response?.data;
      if (response?.status === 200) {
        let users = response?.data?.data?.org?.users;
        users = (!users || users.length ==0 ) ? [] : users;
        const userObj = users.map((user) => ({value: user.id, label: user.name+' - '+user.email}));
        setUsersList(userObj)
        setUserPending(false);
        setIsLoading(false);
      } 
    })
    .catch(function (error) {
      setErrorMessage(error?.response?.data?.message)
      console.error(error?.response?.data?.message);
      setUserPending(false);
      setIsLoading(false);
    });
  }

  const getUserColleagues = async (user) => {
    setSelectedUserObj(user)
    const viewingUserId = user?.value;
    setColleagueList([]);
    setSelectedColleagueId("")
    setSelectedColleagueIdObj({})
    setSelectedUser(viewingUserId)
    setIsLoading(true);
    // callGetUserExistingColleagues() is an existing function that takes in two params
    // so just mapping the data to ensure this functions works without editing the current function
    const graphqlQuery = {
      "query": `query BIO_QUERY($userId: String!) {
        user(id: $userId) {
          id
          name
          email
          colleagues {
            id
            email
            name
          }
          
        }
      }
      `,
      "variables": {userId: viewingUserId}
  };
  axios.defaults.headers.post['Authorization'] = `Bearer ${bearerToken}`;
  axios.defaults.headers.post['content-type'] = `application/json`;
  // Even if endpoint is not expecting data, pass null as the data.
  axios.post(baseURL, graphqlQuery)
    .then(function (response) {
      const result = response?.data;
      if (response?.status === 200) {
        let colleagues = response?.data?.data?.user?.colleagues;
        colleagues = (!colleagues || colleagues.length ==0 ) ? [] : colleagues;
        const colleaguesObj = colleagues.map((colleague) => ({value: colleague.id, label: colleague.name +' - '+colleague.email}));
        setColleagueList(colleaguesObj)
        setColleaguePending(false);
    setIsLoading(false);
      } 
    })
    .catch(function (error) {
      setErrorMessage(error?.response?.data?.message)
      console.error(error?.response?.data?.message);
      setColleaguePending(false);
      setIsLoading(false);
    });
  }

  const updateColleague = async (colleague) => {
    setSelectedColleagueIdObj(colleague)
    const colleagueId = colleague?.value;
    setSelectedColleagueId(colleagueId);
    
    if(colleagueId != "") {
      setEnableSumit(true)
    } else {
      setEnableSumit(false);
    }
  } 

  const UsersElement = () => {
    if (usersList.length != 0) {
      return (
        <Select options={usersList} value={selectedUserObj} onChange={(event) => { getUserColleagues(event) }} name="user" placeholder="Choose your user" />
      );
    }
    else {
      return (
        <>
        </>
      )
    }
}

const OrgListElement = () => {
  if (organizationLists.length != 0) {
    return (
      <Select options={organizationLists} value={selectedOrgObj} hideSelectedOptions="false" onChange={(event) => { getUsersByOrg(event) }} name="organization" placeholder="Choose your Organization" />

    );
  }
  else {
    return (
      <>
      </>
    )
  }
}

const ColleaguesList = () => {
  return (
    <>
    <Select options={colleaguesList} value={selectedColleagueIdObj} onChange={(event) => { updateColleague(event) }} name="colleague" placeholder="Choose your Colleague" />
    </>
  )
}

const FromDatePicker = () => {
  return (
    <>
    <DatePicker selected={fromDate} 
    onChange={(date) => setFromDate(date)}
    minDate={new Date(2019, 0, 1)}
    maxDate={new Date()}
    className="form-control"
    placeholderText="From Date"
    />
    </>
  )
}

const ToDatePicker = () => {
  return (
    <>
    <DatePicker selected={toDate} 
    onChange={(date) => setToDate(date)} 
    minDate={new Date(2019, 0, 1)}
    maxDate={new Date()}
    className="form-control"
    placeholderText="To date"
    />
    </>
  )
}

const generateSummary = () => {
  let fromDateInSeconds;
  let toDateInSeconds;

  //convert from Date object to seconds. If the From date was not selected, the dafault From date is 2019-01-01. If the To date was not selected, the default To date is the current date.
  if (!fromDate && toDate ){
    fromDateInSeconds = 1546272000
    let newToDate = new Date(
      toDate.getFullYear(), toDate.getMonth(), toDate.getDate(), 23, 59, 59
    );
    toDateInSeconds = (newToDate.getTime() + newToDate.getTimezoneOffset()*60*1000) / 1000;
  } else if ( fromDate && !toDate ){
    fromDateInSeconds = (fromDate.getTime() + fromDate.getTimezoneOffset()*60*1000) / 1000;
    let currentDate = new Date();
    let newToDate = new Date(
      currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59
    );
    toDateInSeconds = (newToDate.getTime() + newToDate.getTimezoneOffset()*60*1000) / 1000;;
  } else if ( !fromDate && !toDate){
    fromDateInSeconds = 0;
    toDateInSeconds = 0;
  } else {
    fromDateInSeconds = (fromDate.getTime() + fromDate.getTimezoneOffset()*60*1000) / 1000;
    let newToDate = new Date(
      toDate.getFullYear(), toDate.getMonth(), toDate.getDate(), 23, 59, 59
    );
    toDateInSeconds = (newToDate.getTime() + newToDate.getTimezoneOffset()*60*1000) / 1000;
  }

  if(!selectedOrg || selectedOrg == "") {
    setErrorMessage("Please select org!");
    return;
  }

  if(!selectedUser || selectedUser == "") {
    setErrorMessage("Please select user!");
    return;
  }

  if(!selectedColleagueId || selectedColleagueId == "") {
    setErrorMessage("Please select user!");
    return;
  }

  if(!overrideUserEmailTo || overrideUserEmailTo == "") {
    setErrorMessage("Please enter email address for get summary!");
    return;
  }
   if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(overrideUserEmailTo)) {
    setErrorMessage('Invalid email address')
    return;
  }

  if (fromDateInSeconds > toDateInSeconds) {
    setErrorMessage('From Date is later than To Date!')
    return;
  }
  setIsLoading(true);
 
  var graphql = {
    query: `mutation {\n  generatePrismSummaryForUserColleague(\n    generatePrismSummary: {\n      userId: "${selectedUser}",\n      colleagueId: "${selectedColleagueId}",\n      overrideUserEmailTo: "${overrideUserEmailTo}",\n  skipStoreSummaryInDB: true,\n     dateRangeString: "all time", \n isPrismV32: true, \n isVisibilityOn: true,\n  excludePrivateCapture: ${excludePrivateCapture},\n    toDateSeconds:${toDateInSeconds},\n      fromDateSeconds: ${fromDateInSeconds}\n    }\n  )\n}\n`,
    variables: {}
  }
      
      axios.defaults.headers.post['Authorization'] = `Bearer ${bearerToken}`;
      axios.defaults.headers.post['content-type'] = `application/json`;
      // Even if endpoint is not expecting data, pass null as the data.
      axios.post(baseURL, graphql)
        .then(function (response) {
          if (response?.status === 200) {
            const result = response?.data?.data?.generatePrismSummaryForUserColleague;
            if(result) {
              setSuccessMessage('Your request has been submitted, You will get update on entered email!');
            } else {
              setErrorMessage('Sorry, Your request not accepted!, Please try after sometime!');
            }
            setIsLoading(false);
            setSelectedUser("")
            setSelectedUserObj({})
            setSelectedColleagueId("")
            setSelectedColleagueIdObj({})
            setColleaguePending(true)
            setEnableSumit(false)
            setOrverrideUserEmailTo("")
            setToDate();
            setFromDate();
            setExcludePrivateCaptures(false)
            setTimeout(() => setSuccessMessage(""), 2000)
          } 
        })
        .catch(function (error) {
          setErrorMessage(error?.response?.data?.message)
          console.error(error?.response?.data?.message);
          setUserPending(false);
          setIsLoading(false);
        });
  return;
}

  return (
    <div className="page-container">

      <Container className="d-flex justify-content-center my-4">
        <h2>Generate Prism summary for review</h2>
      </Container>
      <Container className="w-100">
        <ol className="notification-hint-padding">
          <li>Search for the organization</li>
          <li>Search for the user</li>
          <li>Search for the colleague that the prism summary is for</li>
          <li>Enter the email address in which you want to get generated prism summary or an error message if a colleague is not eligible for the prism summary.</li>
          <li>If the Prism should be generated for a specific time period, you can specify either the From Date, To Date or both dates. If you do not specify the dates, the Prism will be generated from all Captures.</li>
        </ol>
        {!isOrgPending && <Form>
          <div className="row">
            <div className="col-sm-4">
            <Form.Group>
              <Form.Label>
                    <b>Select Organization</b>
                  </Form.Label>
                  <OrgListElement />
              </Form.Group>
            </div>
            {!isUserPending && <div className="col-sm-4"><Form.Group>
              <Form.Label>
                    <b>Select User</b>
                  </Form.Label>
                  <UsersElement />
              </Form.Group>
            </div> }
            
            
            {!isColleaguePending && <div className="col-sm-4">
            <Form.Group>
              <Form.Label>
                    <b>Select Colleague</b>
                    
                  </Form.Label>
                    <ColleaguesList />
              </Form.Group>
            </div>}
          </div>
          {enableSubmit && <>
            <div className="row">
            <div className="col-sm-4">
              
            <Form.Label>
                    <b>Override User Email to</b>
                  </Form.Label>
              <Form.Control type="text" placeholder="Enter Email" id="overrideUserEmailTo" onChange = { (event) => { setOrverrideUserEmailTo(event.target.value)  } }
 value={overrideUserEmailTo} />
            </div>
          
           

            <div className="col-sm-4">
              
                <Form.Group>

                <Form.Label style={{display:"block"}}>
                  <b> From Date </b><br/>
                </Form.Label>
                <FromDatePicker />
                </Form.Group>
              
              </div>
              <div className="col-sm-4">
              <Form.Group>
                <Form.Label style={{display:"block"}}>
                  <b> To Date </b><br/>
                </Form.Label>
                <ToDatePicker/>
                </Form.Group>
              </div>
            </div>
            <div className="row">
            <div className="col-sm-3" style={{ paddingTop: "40px" }}>
            
                  
            <b>Exclude Private captures</b> <input type="checkbox" placeholder="Exclude private captures" id="excludePrivateCaptures" onChange = { (event) => { setExcludePrivateCaptures(event.target.checked)  } }
value={excludePrivateCapture} />
            
            
      </div>

            </div>
            
            <div className="col-sm-4">
              <button type="button" style={{marginTop: "27px"}} className="btn btn-success" name="submit" id="submit" onClick={generateSummary} >Submit</button> 
            </div>
          </>}
          </Form>
        } 
        
        {errorMessage && (
            <Row className="justify-content-center row-margin">
              <Alert variant={"danger"}>
                {errorMessage}
              </Alert>
            </Row>
          )}
        
        {isLoading && <Row className="justify-content-center row-margin">
        <LoadingCircle />
          </Row>}
        {successMessage && (
            <Row className="justify-content-center row-margin">
              <Alert variant={"success"}>
                {successMessage}
              </Alert>
            </Row>
          )}

          
      </Container>
    </div >
  );
}

export default GeneratePrismSummary;