import { useContext, useEffect, useState } from "react";
import { Alert, Button, Container, Form, Row } from "react-bootstrap";
import LoadingCircle from "../components/loading";
import { AuthContext } from "../services/auth";
import axios from "axios";
import Select from 'react-select'

const SyncUserColleagues = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser, bearerToken } = useContext(AuthContext);
  const [organizationLists, setOrganizationLists] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  
  const [selectedOrgObj, setSelectedOrgObj] = useState({});
  const [selectedUserObj, setSelectedUserObj] = useState({});
  const [errorMessage,setErrorMessage] = useState(null);
  const baseURL = `${process.env.REACT_APP_API_V2_URL}/api/graphql`;
  const [isOrgPending, setOrgPending] = useState(true);
  const [isUserPending, setUserPending] = useState(true);
  const [enableSubmit, setEnableSumit] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
 
  useEffect(() => {
    setTimeout(getOrgs,2000)
  }, []);

  const getOrgs = async () => {
    
    setOrgPending(true);
    setIsLoading(true);
    const graphqlQuery = {
        "query": `query {
          orgs {
            id
            name
          }
        }`,
        "variables": {}
    };
    axios.defaults.headers.post['Authorization'] = `Bearer ${bearerToken}`;
    axios.defaults.headers.post['content-type'] = `application/json`;
    // Even if endpoint is not expecting data, pass null as the data.
    axios.post(baseURL, graphqlQuery)
      .then(function (response) {
        const result = response?.data;
        if (response?.status === 200) {
          const orgs = response?.data?.data?.orgs;
          if(orgs.length > 0) {
            orgs.sort(function(a, b){
              var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
              if (nameA < nameB) 
               return -1;
              if (nameA > nameB)
               return 1;
              return 0;
             });
             let orgsOptions = orgs.map((org) => ({value: org.id, label: org.name}));
              
            setOrganizationLists(orgsOptions)
          }
          setOrgPending(false);
          setIsLoading(false);
        } 
      })
      .catch(function (error) {
        setErrorMessage(error?.response?.data?.message)
        console.error(error?.response?.data?.message);
        setOrgPending(false);
        setIsLoading(false);
      });
  }

  const getUsersByOrg = async (org) => {
    setSelectedOrgObj(org)
    const orgId = org?.value;
    setSelectedOrg(orgId)
    setUsersList([]);
    setIsLoading(true);
    setSelectedUser(false)
    setUserPending(true)
    setEnableSumit(false)
    setSelectedUserObj({})
    if(orgId == null) return;

    const graphqlQuery = {
      "query": `query BIO_QUERY($orgId: String!) {
        org(id: $orgId) {
          id
          name
          users {
            id
            email
            name
          }
          
        }
      }
      `,
      "variables": {orgId}
  };
  axios.defaults.headers.post['Authorization'] = `Bearer ${bearerToken}`;
  axios.defaults.headers.post['content-type'] = `application/json`;
  // Even if endpoint is not expecting data, pass null as the data.
  axios.post(baseURL, graphqlQuery)
    .then(function (response) {
      const result = response?.data;
      if (response?.status === 200) {
        let users = response?.data?.data?.org?.users;
        users = (!users || users.length ==0 ) ? [] : users;
        const userObj = users.map((user) => ({value: user.id, label: user.name+' - '+user.email}));
        setUsersList(userObj)
        setUserPending(false);
        setIsLoading(false);
      } 
    })
    .catch(function (error) {
      setErrorMessage(error?.response?.data?.message)
      console.error(error?.response?.data?.message);
      setUserPending(false);
      setIsLoading(false);
    });
  }

  const enableSubmitBtn = async (user) => {
    setSelectedUserObj(user)
    const viewingUserId = user?.value;
    setSelectedUser(viewingUserId)
    if(viewingUserId != "") {
      setEnableSumit(true)
    } else {
      setEnableSumit(false);
    }
  }

  const UsersElement = () => {
    if (usersList.length != 0) {
      return (
        <Select options={usersList} value={selectedUserObj} onChange={(event) => { enableSubmitBtn(event) }} name="user" placeholder="Choose your user" />
      );
    }
    else {
      return (
        <>
        </>
      )
    }
}

const OrgListElement = () => {
  if (organizationLists.length != 0) {
    return (
      <Select options={organizationLists} value={selectedOrgObj} hideSelectedOptions="false" onChange={(event) => { getUsersByOrg(event) }} name="organization" placeholder="Choose your Organization" />

    );
  }
  else {
    return (
      <>
      </>
    )
  }
}


const submitSyncUserColleague = () => {
  if(!selectedOrg || selectedOrg == "") {
    setErrorMessage("Please select org!");
    return;
  }

  if(!selectedUser || selectedUser == "") {
    setErrorMessage("Please select user!");
    return;
  }

  
  setIsLoading(true);

  var graphql = {
    query: `mutation {\n  addOrgUsersAsColleaguesFromAdmin(\n   userId: "${selectedUser}" \n  )\n}\n`,
    variables: {}
  }
      
      axios.defaults.headers.post['Authorization'] = `Bearer ${bearerToken}`;
      axios.defaults.headers.post['content-type'] = `application/json`;
      // Even if endpoint is not expecting data, pass null as the data.
      axios.post(baseURL, graphql)
        .then(function (response) {
          if (response?.status === 200) {
            const result = response?.data?.data?.addOrgUsersAsColleaguesFromAdmin;
            if(result) {
              setSuccessMessage('Your request has been submitted, Org users will be added as colleague to selected user!');
            } else {
              setErrorMessage('Sorry, Your request not accepted!, Please try after sometime!');
            }
            setIsLoading(false);
            setSelectedUser("")
            setSelectedUserObj({})
           
            setEnableSumit(false)
           
            setTimeout(() => setSuccessMessage(""), 2000)
          } 
        })
        .catch(function (error) {
          setErrorMessage(error?.response?.data?.message)
          console.error(error?.response?.data?.message);
          setUserPending(false);
          setIsLoading(false);
        });
  return;
}

  return (
    <div className="page-container">

      <Container className="d-flex justify-content-center my-4">
        <h2>Generate Prism summary for review</h2>
      </Container>
      <Container className="w-100">
        <ol className="notification-hint-padding">
          <li>Search for the organization</li>
          <li>Search for user you wish to add colleagues</li>
          
        </ol>
        {!isOrgPending && <Form>
          <div className="row">
            <div className="col-sm-4">
            <Form.Group>
              <Form.Label>
                    <b>Select Organization</b>
                  </Form.Label>
                  <OrgListElement />
              </Form.Group>
            </div>
            {!isUserPending && <div className="col-sm-4"><Form.Group>
              <Form.Label>
                    <b>Select User</b>
                  </Form.Label>
                  <UsersElement />
              </Form.Group>
            </div> }
            
            
          </div>
          {enableSubmit && <>
            <div className="row">
            <div className="col-sm-4">
            <Form.Label>
                    
                  </Form.Label>
              <button type="button" style={{marginTop: "27px"}} className="btn btn-success" name="submit" id="submit" onClick={submitSyncUserColleague} >Submit</button> 
            </div>
          </div></>}
          </Form>
        } 
        
        {errorMessage && (
            <Row className="justify-content-center row-margin">
              <Alert variant={"danger"}>
                {errorMessage}
              </Alert>
            </Row>
          )}
        
        {isLoading && <Row className="justify-content-center row-margin">
        <LoadingCircle />
          </Row>}
        {successMessage && (
            <Row className="justify-content-center row-margin">
              <Alert variant={"success"}>
                {successMessage}
              </Alert>
            </Row>
          )}

          
      </Container>
    </div >
  );
}

export default SyncUserColleagues;