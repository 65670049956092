import firebase from "firebase";
import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import LoadingCircle from "../components/loading";
import { AuthContext } from "../services/auth";
import "./page-styles.css";
import axios from "axios";
import Select from 'react-select'
var firebaseApp = firebase.app();
var functions = firebaseApp.functions("asia-northeast1");
var baseURL = process.env.REACT_APP_API_V1_URL;


const CreateOrgUser = () => {
  const [pending, setPending] = useState(false);
  const [enableSubmit, setEnableSumit] = useState(false);
  const { currentUser, bearerToken } = useContext(AuthContext);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [errorNameCode, setErrorNameCode] = useState(null);
  const [errorOrgCode, setErrorOrgCode] = useState(null);
  const [errorProviderCode, setErrorProviderCode] = useState(null);
  const [errorTextCode, setErrorTextCode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [validateInputCode, setValidInputCode] = useState(true);
  const [validateInputName, setValidInputName] = useState(true);
  const [responseMessage, setResponseMessage] = useState(null);
  const [authHeader, setAuthHeader] = useState(null);
  const baseURL = `${process.env.REACT_APP_API_V2_URL}/api/graphql`;
  const [isOrgPending, setOrgPending] = useState(true);
  const [organizationLists, setOrganizationLists] = useState([]);
  const [errorMessage,setErrorMessage] = useState(null);
  const [selectedOrgObj, setSelectedOrgObj] = useState({});
  const [selectedProvider, setSelectedProvider] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  useEffect(() => {
    // Set auth headers on loading of page
    setIsLoading(true);
    AuthHeaderFunction();
    setTimeout(getOrgs,2000)
  }, []);
  
  const providersList = [
    {
      label: "Google",
      value: "Google"
    },
    {
      label: "Apple",
      value: "Apple"
    },
    {
      label: "Microsoft",
      value: "Microsoft"
    }
  ]
  const getOrgs = async () => {
    
    setOrgPending(true);
    
    const graphqlQuery = {
        "query": `query {
          orgs {
            id
            name
          }
        }`,
        "variables": {}
    };
    axios.defaults.headers.post['Authorization'] = `Bearer ${bearerToken}`;
    axios.defaults.headers.post['content-type'] = `application/json`;
    // Even if endpoint is not expecting data, pass null as the data.
    axios.post(baseURL, graphqlQuery)
      .then(function (response) {
        const result = response?.data;
        if (response?.status === 200) {
          const orgs = response?.data?.data?.orgs;
          if(orgs.length > 0) {
            orgs.sort(function(a, b){
              var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
              if (nameA < nameB) 
               return -1;
              if (nameA > nameB)
               return 1;
              return 0;
             });
             let orgsOptions = orgs.map((org) => ({value: org.id, label: org.name}));
              
            setOrganizationLists(orgsOptions)
          }
          setOrgPending(false);
          setIsLoading(false);
        } 
      })
      .catch(function (error) {
        setErrorMessage(error?.response?.data?.message)
        console.error(error?.response?.data?.message);
        setOrgPending(false);
        setIsLoading(false);
      });
  }
   // Function to set auth header into state variable
    const AuthHeaderFunction = () => {
      if (currentUser && bearerToken !== null) {
        let authHeaderObj = {
          Authorization: 'Bearer ' + bearerToken,
          'Content-Type': 'application/json',
        };
        setAuthHeader(authHeaderObj);
      }
    };
    const OrgListElement = () => {
      if (organizationLists.length != 0) {
        return (
          <Select options={organizationLists} value={selectedOrgObj} hideSelectedOptions="false" onChange={(event) => { setSelectedOrgObj(event) }} name="organization" placeholder="Choose your Organization" />
    
        );
      }
      else {
        return (
          <>
          </>
        )
      }
    }
  const callCreateOrgUser = (data) => {
    setResponseMessage("");
    setErrorMessage("")
    setPending(true);
    var graphql = {
      query: `mutation {\n  createUser(\n    createuser: {\n      name: "${data.name}",\n      email: "${data.email}",\n      orgId: "${data.orgId}",\n  provider: ${data.provider}    }\n  )\n}\n`,
      variables: {}
    }
        
        axios.defaults.headers.post['Authorization'] = `Bearer ${bearerToken}`;
        axios.defaults.headers.post['content-type'] = `application/json`;
        // Even if endpoint is not expecting data, pass null as the data.
        axios.post(baseURL, graphql)
          .then(function (response) {
            if (response?.status === 200) {
              const result = response?.data?.data?.createUser;
              if(result) {
                setSuccessMessage('User created successfully!');
              } else {
                console.log(response?.data)
                setErrorMessage('Sorry, User not created!');
              }
              setIsLoading(false);
              setPending(false)
              setEnableSumit(false);
              setName("")
              setEmail("")
              setSelectedOrgObj({})
              setSelectedProvider({})
              setTimeout(() => {
                setSuccessMessage("");
                setErrorMessage("")
              }, 2000)
            } else {
              setPending(false)
              console.log(response)
            }
          })
          .catch(function (error) {
            
            let message = "";
            for(const er of error?.response?.data?.errors) {
              message += er.message+"\n";
            }
            setErrorMessage(message)
            console.error(message);
            setIsLoading(false);
            setPending(false)
          });
  };
  const isEmail = (email) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

  const validate = () => {
    if (Object.keys(selectedOrgObj).length == 0) {
      setErrorOrgCode("Please select org!");
      setValidInputCode(false);
    }
    if (name === null) {
      setErrorNameCode("Please enter name");
      setValidInputCode(false);
    }
    if (email === null) {
      setErrorTextCode("Please enter email");
      setValidInputCode(false);
    }
    
    if (!isEmail(email)) {
      setErrorTextCode("Please enter valid email address");
      setValidInputCode(false);
    }
    if (selectedProvider === null) {
      setErrorProviderCode("Please select sign-in method");
      setValidInputCode(false);
    }
    if (validateInputCode && validateInputName) {
      const data = {
        email: email,
        name: name,
        orgId: selectedOrgObj.value,
        provider: selectedProvider.value
      };
      callCreateOrgUser(data);
    }
  };

  const nameValue = (event) => {
    setErrorNameCode(null);
    setName(event.target.value);
  };

  const emailValue = (event) => {
    setErrorTextCode(null);
    setEmail(event.target.value);
  };


  return (
    <div className="page-container">
      <Container className="d-flex justify-content-center my-4">
        <h2>Create Org User</h2>
        </Container>
      {!isOrgPending && <>
      
      <Container>
        <Col className="p-0">
          <Card className="mx-auto">
            <Card.Body className="flex-column">
              <Container className="my-4 w-md-75">
              <Form.Group>
              <Form.Label>
                    <b>Select Organization</b>
                  </Form.Label>
                  <OrgListElement />
                  <Form.Control.Feedback type="invalid">
                    {errorOrgCode}
                  </Form.Control.Feedback>
              </Form.Group>
             
              <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    onChange={nameValue}
                    required
                    value={name}
                    placeholder="Please enter name"
                    isInvalid={!!errorNameCode ? true : false}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorNameCode}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    onChange={emailValue}
                    required
                    value={email}
                    placeholder="Please enter email"
                    isInvalid={!!errorTextCode ? true : false}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorTextCode}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
              <Form.Label>
                    <b>Select Sign-in method</b>
                  </Form.Label>
                  <Select options={providersList} value={selectedProvider} hideSelectedOptions="false" onChange={(event) => { setSelectedProvider(event) }} name="provider" placeholder="Choose provider" />
                  <Form.Control.Feedback type="invalid">
                    {errorProviderCode}
                  </Form.Control.Feedback>
              </Form.Group>
                {!pending && <Row className="justify-content-center mt-5">
                  <Button onClick={validate}>
                    Create
                  </Button>
                </Row>}
               
              </Container>
            </Card.Body>
          </Card>
        </Col>
      </Container></>}
      {errorMessage && (
            <Row className="justify-content-center row-margin">
              <Alert variant={"danger"}>
                {errorMessage}
              </Alert>
            </Row>
          )}
          {successMessage && (
            <Row className="justify-content-center row-margin">
              <Alert variant={"success"}>
                {successMessage}
              </Alert>
            </Row>
          )}
      {isLoading && <Row className="justify-content-center row-margin">
        <LoadingCircle />
          </Row>}
    </div>

  );
};

export default CreateOrgUser;