import firebase from "firebase";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Alert, Container, Row} from 'react-bootstrap';
import LoadingCircle from "../components/loading";
import { AuthContext } from "../services/auth";
import "./page-styles.css";
import axios from "axios";
import DataTable from 'react-data-table-component';
import Switch from '@mui/material/Switch';
import FilterComponent from "./FilterComponent";


var firebaseApp = firebase.app();
var functions = firebaseApp.functions("asia-northeast1");

const ManageOrg = () => {
  const { currentUser, bearerToken } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState(null);
  const [authHeader, setAuthHeader] = useState(null);
  const baseURL = `${process.env.REACT_APP_API_V2_URL}/api/graphql`;
  const [isOrgPending, setOrgPending] = useState(true);
  const [organizationLists, setOrganizationLists] = useState([]);
  const [errorMessage,setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [filteredItems, setFilteeredItems] = useState([]);
  const filterRef = useRef(null)

  const filterOrgs = (searchText) => {
    setFilterText(searchText);
    const resultfilteredItems = organizationLists.filter(
      item => item.label && item.label.toLowerCase().includes(searchText.toLowerCase()),
    );
    setFilteeredItems(resultfilteredItems);
    filterRef?.current?.focus()
  }
  
	const subHeaderComponentMemo = React.useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
        setFilteeredItems(organizationLists);
			}
		};

		return (
			<FilterComponent filterRef={filterRef} onFilter={e => filterOrgs(e.target.value)} onClear={handleClear} filterText={filterText} />
		);
	}, [filterText, resetPaginationToggle]);


    useEffect(() => {
    // Set auth headers on loading of page
    setIsLoading(true);
    AuthHeaderFunction();
    setTimeout(getOrgs,2000)
    setCurrentPage(1)
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getOrgs = async () => {
    setOrgPending(true);
    
    const graphqlQuery = {
        "query": `query {
          orgs {
            id
            name
            isDeactivated
            canAccessManagementPortal
          }
        }`,
        "variables": {}
    };
    axios.defaults.headers.post['Authorization'] = `Bearer ${bearerToken}`;
    axios.defaults.headers.post['content-type'] = `application/json`;
    // Even if endpoint is not expecting data, pass null as the data.
    axios.post(baseURL, graphqlQuery)
      .then(function (response) {
        const result = response?.data;
        if (response?.status === 200) {
          const orgs = response?.data?.data?.orgs;
          if(orgs.length > 0) {
            orgs.sort(function(a, b){
              var nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
              if (nameA < nameB) 
               return -1;
              if (nameA > nameB)
               return 1;
              return 0;
             });
             let orgsOptions = orgs.map((org) => ({value: org.id, label: org.name, isDeactivated: org.isDeactivated?? false, canAccessManagementPortal: org.canAccessManagementPortal ?? false }));
            setOrganizationLists(orgsOptions)
            const resultfilteredItems = orgsOptions.filter(
              item => item.label && item.label.toLowerCase().includes(filterText.toLowerCase()),
            );
            setFilteeredItems(resultfilteredItems);
          }
          setOrgPending(false);
          setIsLoading(false);
        } 
      })
      .catch(function (error) {
        setErrorMessage(error?.response?.data?.message)
        console.error(error?.response?.data?.message);
        setOrgPending(false);
        setIsLoading(false);
      });
  }

    const AuthHeaderFunction = () => {
      if (currentUser && bearerToken !== null) {
        let authHeaderObj = {
          Authorization: 'Bearer ' + bearerToken,
          'Content-Type': 'application/json',
        };
        setAuthHeader(authHeaderObj);
      }
    };
    
    const OrgListElement = () => {
      const columns = [
        {
          name: 'Org ID',
          selector: row => row.value,
          sortable: true,
          center: true
        },
        {
          name: 'Org Name',
          selector: row => row.label,
          sortable: true,
          center: true
        },
        {
          name: 'Onboarding Status',
          cell: row => <Switch checked = {!row.isDeactivated} onChange={()=>{callUpdateOrgStatus(row)}} />,
          center: true
        },
        {
          name: 'Can access management portal',
          cell: row => <Switch checked = {row.canAccessManagementPortal} onChange={()=>{callUpdateManagementPortalAccess(row)}} />,
          center: true
        }
      ]
      return (
        <DataTable
        title="Orgs List"
        columns={columns}
        data={filteredItems}
        pagination
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        paginationDefaultPage={currentPage}
        onChangePage={handlePageChange}
        />
      );
    }
  const callUpdateOrgStatus = (org) => {
    setResponseMessage("");
    setErrorMessage("")
    setIsLoading(true)
    var graphql = {
      query: `mutation {\n  updateOrgStatus(\n    changeOrgStatusInput: {\n      onboardingStatus: ${org.isDeactivated},\n      orgId: "${org.value}"    }\n  )\n}\n`,
      variables: {}
    }
        axios.defaults.headers.post['Authorization'] = `Bearer ${bearerToken}`;
        axios.defaults.headers.post['content-type'] = `application/json`;
        // Even if endpoint is not expecting data, pass null as the data.
        axios.post(baseURL, graphql)
          .then(function (response) {
            if (response?.status === 200) {
              const result = response?.data?.data?.updateOrgStatus;
              if(result) {
                setSuccessMessage('Organization onboarding status updated!');
                getOrgs().then((result) => console.log("org updated"));
              } else {
                console.log(response?.data)
                setErrorMessage('Sorry, Organization onboarding status not updated!');
              }
              setIsLoading(false);
              setTimeout(() => {
                setSuccessMessage("");
                setErrorMessage("")
              }, 2000)
            } else {
              console.log(response)
            }
          })
          .catch(function (error) {
            let message = "";
            for(const er of error?.response?.data?.errors) {
              message += er.message+"\n";
            }
            setErrorMessage(message)
            console.error(message);
            setIsLoading(false);  
          });
  };

  const callUpdateManagementPortalAccess = (org) => {
    setResponseMessage("");
    setErrorMessage("")
    setIsLoading(true)
    var graphql = {
      query: `mutation {\n  updateManagementPortalAccess(\n    updateManagementPortalAccessInput: {\n      managmentPortalAccessStatus: ${!org.canAccessManagementPortal},\n      orgId: "${org.value}"    }\n  )\n}\n`,
      variables: {}
    }
        axios.defaults.headers.post['Authorization'] = `Bearer ${bearerToken}`;
        axios.defaults.headers.post['content-type'] = `application/json`;
        // Even if endpoint is not expecting data, pass null as the data.
        axios.post(baseURL, graphql)
          .then(function (response) {
            if (response?.status === 200) {
              const result = response?.data?.data?.updateManagementPortalAccess;
              if(result) {
                setSuccessMessage('Organization management portal access status updated!');
                getOrgs().then((result) => console.log("org updated"));
              } else {
                console.log(response?.data)
                setErrorMessage('Sorry, Organization management portal access status not updated!');
              }
              setIsLoading(false);
              setTimeout(() => {
                setSuccessMessage("");
                setErrorMessage("")
              }, 2000)
            } else {
              console.log(response)
            }
          })
          .catch(function (error) {
            let message = "";
            for(const er of error?.response?.data?.errors) {
              message += er.message+"\n";
            }
            setErrorMessage(message)
            console.error(message);
            setIsLoading(false); 
          });
  };

  return (
    <div className="page-container">
      <Container className="d-flex justify-content-center my-4">
        <h2>Manage Org (Onboard/Offboard orgs)</h2>
        </Container>

        <OrgListElement/>

      {errorMessage && (
            <Row className="justify-content-center row-margin">
              <Alert variant={"danger"}>
                {errorMessage}
              </Alert>
            </Row>
          )}
          {successMessage && (
            <Row className="justify-content-center row-margin">
              <Alert variant={"success"}>
                {successMessage}
              </Alert>
            </Row>
          )}
      {isLoading && <Row className="justify-content-center row-margin">
        <LoadingCircle />
          </Row>}
    </div>
  );
};

export default ManageOrg;