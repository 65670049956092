import React from "react";
import { Route, Router } from "react-router-dom";
import "./App.css";
import PrivateRoute from "./components/private-route";
import ReferralCode from "./pages/generate-referral-code";
import Home from "./pages/home";
import LearnContent from "./pages/learn-content";
import Login from "./pages/login";
import Notifications from "./pages/notifications";
import AddColleagues from "./pages/add-colleagues";
import CreateMPTeams from "./pages/create-mp-teams";
import CreateCustomTeams from "./pages/create-custom-teams";
import CreateCustomTags from "./pages/create-custom-tags";
import GenerateReview from "./pages/generate-review";
import AuthProvider from "./services/auth";
import history from "./services/history";
import CreateUser from "./pages/create-user";
import GeneratePrismSummary from "./pages/generate-prism-summary";
import CreateOrgUser from "./pages/create-org-user";
import SyncUserColleagues from "./pages/sync-colleagues";
import ManageOrg from "./pages/manage-org";
import CreateManagerCapture from "./pages/create-manager-capture";

function App() {
  return (
    <AuthProvider>
      <Router history={history}>
        <div>
          <Route exact path="/" component={Login} />
          <Route exact path="/login" component={Login} />
          <PrivateRoute exact path="/home" component={Home} />
          <PrivateRoute exact path="/referralCode" component={ReferralCode} />
          <PrivateRoute exact path="/notifications" component={Notifications} />
          <PrivateRoute exact path="/learnContent" component={LearnContent} />
          <PrivateRoute exact path="/addColleagues" component={AddColleagues} />
          <PrivateRoute exact path="/createUser" component={CreateUser} />
          <PrivateRoute exact path="/createOrgUser" component={CreateOrgUser} />
          <PrivateRoute exact path="/createMPTeams" component={CreateMPTeams} />
          <PrivateRoute exact path="/createCustomTeams" component={CreateCustomTeams} />
          <PrivateRoute exact path="/createCustomTags" component={CreateCustomTags} />
          <PrivateRoute exact path="/createManagerCapture" component={CreateManagerCapture} />
          <PrivateRoute exact path="/generatePrismSummary" component={GeneratePrismSummary} />
          <PrivateRoute exact path="/manageOrg" component={ManageOrg} />
          <PrivateRoute exact path="/syncColleagues" component={SyncUserColleagues} />
          <PrivateRoute exact path="/generateReview" component={GenerateReview} />
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
