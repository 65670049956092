import axios from "axios";
import firebase from "firebase";
import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import LoadingCircle from "../components/loading";
import OptionSelector from "../components/option-selector";
import UsersTable from "../components/user-table";
import * as constants from "../constants";
import { AuthContext } from "../services/auth";
import "./page-styles.css";

var firebaseApp = firebase.app();
var functions = firebaseApp.functions("asia-northeast1");
var baseURL = process.env.REACT_APP_API_V1_URL;

const Notifications = () => {

  const { currentUser, bearerToken } = useContext(AuthContext);
  const [pending, setPending] = useState(false);

  // Conditional UI and form methods. 
  const [deviceNotificationFlow, setDeviceNotificationFlow] = useState(false);
  const [slackNotificationFlow, setSlackNotificationFlow] = useState(false);
  const [notificationMethodSelected, setNotificationMethodSelected] = useState(false);
  const [allSlackUsersConfirmed, setAllSlackUsersConfirmed] = useState(null);
  const [allSlackUsersSelected, setAllSlackUsersSelected] = useState(false);
  const [notificationChannel, setNotificationChannel] = useState(null);

  //// Device notification states.
  const [userEmail, setUserEmail] = useState(null);
  const [notificationTitle, setNotificationTitle] = useState(null);
  const [notificationBody, setNotificationBody] = useState(null);
  const [errorUserEmail, setErrorUserEmail] = useState(false);
  const [errorNotificationTitle, setErrorNotificationTitle] = useState(false);
  const [errorNotificationBody, setErrorNotificationBody] = useState(false);

  const [inValidateUserEmails, setInValidateUserEmail] = useState(false);
  const [validateNotificationTitle, setValidateNotificationTitle] = useState(false);

  const [show, setShow] = useState(false);
  const [allConfirmed, setAllConfirmed] = useState(false);

  //// Slack notification states. 
  const [addSlackUserMessage, setAddSlackUserMessage] = useState(null);
  const [addSlackUserStatus, setAddSlackUserStatus] = useState(null);
  const [slackUserId, setSlackUserId] = useState(null);
  const [slackUserName, setSlackUserName] = useState(null);
  const [slackUserEmail, setSlackUserEmail] = useState(null);
  const [allSlackUsers, setAllSlackUsers] = useState(null);
  const [selectedSlackUsers, setSelectedSlackUsers] = useState([]);
  const [slackNotification, setSlackNotification] = useState(null);
  const [errorSlackNotificationMessage, setErrorSlackNotificationMessage] = useState(null);
  const [showSlackModal, setShowSlackModal] = useState(false);

  // Response message and status states. 
  const [responseType, setResponseType] = useState(null);
  const [responseMessage, setResponseMessage] = useState(null);

  // Auth Header state.
  const [authHeader, setAuthHeader] = useState(null);

  useEffect(() => {
    // Set auth headers on loading of page
    AuthHeaderFunction();
  }, []);

  // Function to set auth header into state variable
  const AuthHeaderFunction = () => {
    if (currentUser && bearerToken !== null) {
      let authHeaderObj = {
        Authorization: 'Bearer ' + bearerToken,
        'Content-Type': 'application/json',
      };
      setAuthHeader(authHeaderObj);
    }
  };

  const resetStates = () => {
    setNotificationMethodSelected(false);
    setAllSlackUsersConfirmed(false);
    setAllSlackUsersSelected(false);

    setUserEmail(null);
    setNotificationTitle(null);
    setNotificationBody(null);
    setErrorUserEmail(false);
    setErrorNotificationTitle(false);
    setErrorNotificationBody(false);
    setInValidateUserEmail(false);
    setValidateNotificationTitle(false);
    setAllConfirmed(false);

    setSlackUserId(null);
    setSlackUserName(null);
    setSlackUserEmail(null);
    setAllSlackUsers([]);
    setSelectedSlackUsers([]);
    setSlackNotification(null);
    setErrorSlackNotificationMessage(null);
    setAddSlackUserMessage(null);
    setAddSlackUserStatus(null);

    setResponseType(null);
    setResponseMessage(null);
  }

  const selectNotificationMethod = async (option) => {
    setNotificationMethodSelected(true);
    if (option === constants.DEVICE_NOTIFICATION) {
      resetStates();
      setSlackNotificationFlow(false);
      setDeviceNotificationFlow(true);
      setNotificationChannel(constants.DEVICE_NOTIFICATION);
      setNotificationMethodSelected(true);
    } else if (option === constants.SLACK_NOTIFICATION) {
      resetStates();
      await retrieveSlackUsers();
      setDeviceNotificationFlow(false);
      setSlackNotificationFlow(true);
      setNotificationChannel(constants.SLACK_NOTIFICATION);
      setNotificationMethodSelected(true);
    }
  }

  /* Slack notification functions. */
  // onChange function to set Slack notification message.
  const SlackNotificationBodyValue = (event) => {
    if (event.target.value == '' || event.target.value == null) {
      setErrorSlackNotificationMessage('Please do not leave this field blank');
    } else {
      setErrorSlackNotificationMessage(null);
    }
    setSlackNotification(event.target.value);
  }

  const retrieveSlackUsers = async () => {
    setPending(true);
    // Even if endpoint is not expecting data, pass null as the data.
    axios.post(baseURL + '/admin/retrieve-slack-users', null, { headers: authHeader })
      .then(function (response) {
        const result = response?.data;
        if (response?.status === 200) {
          if (result?.data?.slackUsers != null && result?.data?.slackUsers.length > 0) {
            const OnLoopSlackUsers = result.data?.slackUsers;
            const OnLoopSlackUserOptions = [...OnLoopSlackUsers, { "uid": "all", "name": "all", "email": "Select All OnLoop Slack Users" }];
            setAllSlackUsers(OnLoopSlackUserOptions);
            setPending(false);
          }
        } else {
          setAddSlackUserStatus('danger');
          setAddSlackUserMessage("Error retrieving OnLoop Slack Users!")
          setPending(false);
        }
      })
      .catch(function (error) {
        setAddSlackUserStatus('danger');
        setAddSlackUserMessage("Error retrieving OnLoop Slack Users!")
        console.error(error?.response?.data?.message);
        setPending(false);
      });
  }

  // Modal onClick function that confirms the selection of all OnLoop Slack users.
  const handleConfirmForSlackUsers = () => {
    setShowSlackModal(false);
    setAllSlackUsersConfirmed(true);
    setAllSlackUsersSelected(false);
    setSelectedSlackUsers('all');
    setAddSlackUserStatus('success');
    setAddSlackUserMessage('All Slack users have been selected.')
  }

  // Modal onClick function that cancels the selection of all OnLoop Slack users.
  const handleCancelForSlackUsers = () => {
    setShowSlackModal(false);
    setAllSlackUsersConfirmed(false);
    setSelectedSlackUsers([]);
    setAddSlackUserStatus('danger');
    setAddSlackUserMessage('Please select a user!');
  }

  // onChange function to populate the slack user states after 
  // admin selects a new individual user using Typeahead.
  const addSlackUser = (selectedUser) => {
    if (selectedUser == null) {
      setAllSlackUsersSelected(false);
      setSlackUserId(null);
      setSlackUserName(null);
      setSlackUserEmail(null);
    } else if (selectedUser.uid === 'all') {
      setAllSlackUsersSelected(true);
    } else {
      setAllSlackUsersSelected(false);
      setAddSlackUserMessage(null);
      setAddSlackUserStatus(null);
      setSlackUserId(selectedUser.uid);
      setSlackUserName(selectedUser.name);
      setSlackUserEmail(selectedUser.email);
    }
  }

  // onClick function for 'Add User' button when adding individual users. 
  // Validates that user id, name, and email are set
  // before adding new slack user into the selected slack users array. 
  const validateSlackUser = () => {

    let validateSlackUserId = false;
    let validateSlackUserName = false;
    let validateSlackUserEmail = false;

    if (slackUserId != null && slackUserId.length > 0) {
      validateSlackUserId = true;
    }

    if (slackUserName != null && slackUserName.length > 0) {
      validateSlackUserName = true;
    }

    if (slackUserEmail != null && slackUserEmail.length > 0) {
      validateSlackUserEmail = true;
    }

    if (validateSlackUserId && validateSlackUserName && validateSlackUserEmail) {
      addSlackUserToArray();
    } else {
      setAddSlackUserStatus('danger');
      setAddSlackUserMessage('Please select a user!')
    }
  }

  // onClick function for 'Confirm Selection' button when selecting all OnLoop Slack users. 
  // Triggers confirmation modal. 
  const validateAllSlackUsers = () => {
    setAddSlackUserMessage(null);
    setAddSlackUserStatus(null);
    setSelectedSlackUsers([]);
    setShowSlackModal(true);
  }

  // Function triggered by 'validateSlackUser' function when there are no errors.
  // Adds a new Slack user into the selected Slack users array. 
  // Reset Slack user states after user has been successfully added into the array. 
  const addSlackUserToArray = () => {

    // Check for duplicate users in array.
    const duplicateSlackUsers = selectedSlackUsers.filter((user) => {
      return user.uid == slackUserId;
    });

    if (duplicateSlackUsers.length > 0) {
      setAddSlackUserStatus('danger');
      setAddSlackUserMessage(`${slackUserName} (${slackUserEmail}) has already been added to the OnLoop Slack users list!`)
      setSlackUserId(null);
      setSlackUserName(null);
      setSlackUserEmail(null);
      return;
    }

    setAddSlackUserMessage(`Successfully added ${slackUserName} (${slackUserEmail}) to the OnLoop Slack users list!`);
    setAddSlackUserStatus('success');
    let array = [...selectedSlackUsers, { "uid": slackUserId, "name": slackUserName, "email": slackUserEmail }];
    setSelectedSlackUsers(array);
    setSlackUserId(null);
    setSlackUserName(null);
    setSlackUserEmail(null);
  }

  // OnClick function for the 'Delete' button in the 'Users' component table. 
  // Remove selected user by index from the users array.
  // Index refers to the key of the 'Delete button upon initialising the table.
  const removeSlackUser = (user) => {
    setAddSlackUserMessage(null);
    setAddSlackUserStatus(null);
    let updatedSlackUsers = selectedSlackUsers;
    updatedSlackUsers.splice(user.index, 1);
    if (updatedSlackUsers.length == 0) {
      setAddSlackUserStatus('danger');
      setAddSlackUserMessage('Please add at least one user');
    }
    setSelectedSlackUsers([...updatedSlackUsers]);
  }

  const validateSlackNotificationSubmission = () => {
    setPending(true);
    setResponseType(null);
    setResponseMessage(null);
    let slackUserSelectionValidated = false;
    let slackNotificationValidated = false;

    if (selectedSlackUsers === 'all') {
      slackUserSelectionValidated = true;
    } else if (selectedSlackUsers != null && selectedSlackUsers.length > 0) {
      slackUserSelectionValidated = true;
    }

    if (slackNotification != null && slackNotification.length > 0) {
      slackNotificationValidated = true;
    }

    if (slackUserSelectionValidated && slackNotificationValidated) {
      let slackUserIds = [];
      if (selectedSlackUsers != 'all') {
        for (const selectedUser of selectedSlackUsers) {
          slackUserIds.push(selectedUser?.uid);
        }
      } else {
        slackUserIds.push(selectedSlackUsers);
      }

      const data = {
        user_id_array: slackUserIds,
        message: slackNotification
      };

      setAddSlackUserMessage(null);
      sendSlackNotificationCall(data);
    } else {
      setPending(false);
      console.error('Error validating Slack notification submission');
    }
  }

  const sendSlackNotificationCall = (data) => {
    axios.post(baseURL + '/admin/send-slack-notification', data, { headers: authHeader })
      .then(function (response) {
        const result = response?.data;
        let message = result?.message ?? '';

        if (response?.status === 200) {
          let unsuccessfulNotifications = result?.data?.usersNotSentTo ?? null;

          if (unsuccessfulNotifications != null && unsuccessfulNotifications.length > 0) {
            const usersNotSentToString = unsuccessfulNotifications.join(', ');
            message = message + ' Users not sent to: [' + usersNotSentToString + ']';
            setResponseType("danger");
          } else {
            setResponseType("success");
          }
          setResponseMessage(message);
          setPending(false);
        } else {
          setResponseType("danger");
          setResponseMessage(message);
          setPending(false);
        }
        setSelectedSlackUsers([]);
        setSlackNotification(null);
      })
      .catch(function (error) {
        setResponseType("danger");
        setResponseMessage("Something went wrong. Please try again later.")
        console.error(error?.response?.data?.message);
        setSelectedSlackUsers([]);
        setSlackNotification(null);
        setPending(false);
      });
  }

  /* Device notification functions. */
  const userEmailValue = (event) => {
    setErrorUserEmail(null);
    setUserEmail(event.target.value);
  };

  const NotificationTitleValue = (event) => {
    setErrorNotificationTitle(null);
    setNotificationTitle(event.target.value);
  };

  const NotificationBodyValue = (event) => {
    setErrorNotificationBody(null);
    setNotificationBody(event.target.value);
  };

  const maxLengthValidate = () => {
    if (notificationTitle !== null && notificationTitle.length > 33) {
      setErrorNotificationTitle("Exceeding maxlength of 33 characters, please use a shorter title");
      setValidateNotificationTitle(false);
    } else {
      setValidateNotificationTitle(true);
    }
  }

  const validateDeviceNotificationSubmission = async () => {
    setResponseType(null);
    setResponseMessage(null);

    let validateUserEmails = false;
    let validateNotificationTitleContent = false;
    let validateNotificationBody = false;
    const emailArr = [];

    if (userEmail === null || userEmail.length === 0) {
      setErrorUserEmail("Please enter valid email address");
      setPending(false);
    } else if (userEmail === "all") {
      if (allConfirmed) {
        emailArr.push(userEmail);
        validateUserEmails = true;
      } else {
        setShow(true);
      }
    } else {
      const emailList = userEmail.split(",");
      const inValidEmailArr = [];
      for (const email of emailList) {
        const regex = /\S+@\S+\.\S+/; // checks for a valid format (anystring@anystring.anystring)
        if (regex.test(String(email).toLowerCase())) {
          emailArr.push(email);
        } else {
          inValidEmailArr.push(email);
          setInValidateUserEmail(inValidEmailArr.toString());
          setErrorUserEmail("Please enter valid email address");
        }
      }
      if (emailArr.length > 0) {
        validateUserEmails = true;
      }
    }

    if (notificationTitle === null || notificationTitle.length === 0) {
      setErrorNotificationTitle("Please do not leave this field blank");
    } else {
      validateNotificationTitleContent = true;
    }

    if (notificationBody === null || notificationBody.length === 0) {
      setErrorNotificationBody("Please do not leave this field blank");
    } else {
      validateNotificationBody = true;
    }

    if (validateUserEmails && validateNotificationTitle && validateNotificationTitleContent && validateNotificationBody) {
      setPending(true);
      const emailStringArray = emailArr.toString();

      const data = {
        userEmails: emailStringArray,
        title: notificationTitle,
        body: notificationBody,
        channel: notificationChannel
      };

      sendNotificationCall(data);
    } else {
      setPending(false);
    }
  };

  const handleConfirm = () => {
    setShow(false);
    setAllConfirmed(true);

  }
  const handleCancel = () => {
    setShow(false);
    setUserEmail(null);
    setErrorUserEmail("Please enter valid email address");
    setPending(false);
  }

  const sendNotificationCall = (data) => {
    setResponseType(null);
    setResponseMessage(null);
    const sendNotification = functions.httpsCallable("sendNotificationFromAdminPortal");
    sendNotification(data).then(function (res) {
      const result = JSON.parse(JSON.stringify(res.data));
      if (result.statusCode === 200) {
        setResponseType("success");
        setResponseMessage(result.statusMessage);
        setPending(false);
      } else {
        setResponseType("danger");
        setResponseMessage(result.statusMessage);
        setPending(false);
      }
    });
    setUserEmail(null);
    setAllConfirmed(null);
    setValidateNotificationTitle(false);
    setNotificationTitle(null);
    setNotificationBody(null);
  };

  return (
    <div className="page-container">
      <Container className="d-flex justify-content-center my-4">
        <h2>Send Notifications</h2>
      </Container>
      <Container>
        <Container>
          <ul className="notification-hint-padding">
            <li>To access emojis on Mac: on Chrome Browser &gt; Edit &gt; Emojis &amp; Symbols</li>
            <li>To access emojis on Windows 10: Press Windows Logo Key + . (period)</li>
          </ul>
        </Container>

        <Container className="d-flex flex-column align-items-center py-4">
          <h6 className="text-center mb-4">Select notification method:</h6>
          <Form className="d-flex justify-content-center">
            <Form.Group>
              <OptionSelector
                name={'notification-method'}
                label={'Device Notification'}
                value={constants.DEVICE_NOTIFICATION}
                onClick={event => selectNotificationMethod(event.target.value)}
                id={'device-notification-method'}
              />
              <OptionSelector
                name={'notification-method'}
                label={'Slack Notification'}
                value={constants.SLACK_NOTIFICATION}
                onClick={event => selectNotificationMethod(event.target.value)}
                id={'slack-notification-method'}
              />
            </Form.Group>
          </Form>
        </Container>

        {/* Device notification flow */}
        {deviceNotificationFlow &&
          <Container>
            <Form>
              <Form.Group className="has-danger">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  onChange={userEmailValue}
                  required
                  type="email"
                  value={userEmail || ''}
                  placeholder="name@example.com"
                  isInvalid={!!errorUserEmail ? true : false}
                />
                <Form.Control.Feedback type="invalid">
                  {errorUserEmail}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Notification Title</Form.Label>
                <Form.Control
                  onChange={NotificationTitleValue}
                  required
                  type="text"
                  value={notificationTitle || ''}
                  placeholder="Hey there!"
                  onKeyUp={maxLengthValidate}
                  isInvalid={!!errorNotificationTitle ? true : false}
                />
                <Form.Control.Feedback type="invalid">
                  {errorNotificationTitle}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Notification Body</Form.Label>
                <Form.Control
                  onChange={NotificationBodyValue}
                  required
                  value={notificationBody || ''}
                  as="textarea"
                  rows={3}
                  isInvalid={!!errorNotificationBody ? true : false}
                />
                <Form.Control.Feedback type="invalid">
                  {errorNotificationBody}
                </Form.Control.Feedback>
              </Form.Group>
            </Form>
          </Container>
        }

        {/* Slack notification flow */}
        {slackNotificationFlow &&
          <Container>

            {/* Slack notification body */}
            <Container className="d-flex flex-column align-items-center mt-2">
              <h6 className="text-center mb-4">Write the Slack notification message:</h6>
            </Container>
            <Form>
              <Form.Group className="has-danger">
                <Form.Control
                  onChange={SlackNotificationBodyValue}
                  required
                  placeholder="Hey there!"
                  as="textarea"
                  rows={3}
                  isInvalid={!!errorSlackNotificationMessage ? true : false}
                />
                <Form.Control.Feedback type="invalid">
                  {errorSlackNotificationMessage}
                </Form.Control.Feedback>
              </Form.Group>
            </Form>

            {/* Select individual users to send Slack notification to flow */}
            <Form>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Select a user to send a Slack notification to:</Form.Label>
                    <Typeahead
                      id={'find-user-with-slack'}
                      className="pr-0 w-100"
                      labelKey={(option) => `${option.name} (${option.email})`}
                      options={allSlackUsers}
                      placeholder="name@example.com"
                      onChange={(selected) => {
                        addSlackUser(selected[0]);
                      }}
                      disabled={allSlackUsersConfirmed}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Container className="justify-content-center w-100">
                  <Row className="justify-content-center">
                    {!allSlackUsersSelected && !allSlackUsersConfirmed &&
                      <Button variant="primary label-alignment mt-1" onClick={validateSlackUser}>
                        Add User
                      </Button>
                    }
                    {allSlackUsersSelected &&
                      <Button variant="primary label-alignment mt-1" onClick={validateAllSlackUsers}>
                        Confirm Selection
                      </Button>
                    }
                  </Row>
                </Container>
              </Row>
            </Form>

            <Row className="d-flex justify-content-center mt-2">
              {addSlackUserMessage &&
                <Alert variant={addSlackUserStatus}>{addSlackUserMessage}</Alert>
              }
            </Row>
            {/* Slack Users Table */}
            {!allSlackUsersConfirmed && selectedSlackUsers.length > 0 &&
              <Col className="my-5">
                <div>
                  <Form.Label className="text-center w-100">
                    <h6>Users</h6>
                  </Form.Label>
                  <Container>
                    <hr className='mt-2 mb-2' />
                    <Row className='mb-2 d-flex justify-content-around'>
                      <Col xs={7}>
                        <b>Name</b>
                      </Col>
                      <Col xs={2}>
                        <b>Remove</b>
                      </Col>
                    </Row>
                    <UsersTable
                      usersArray={selectedSlackUsers}
                      onClick={removeSlackUser} />
                    {/* </Table> */}
                  </Container>
                </div>
              </Col>
            }
          </Container>
        }

        {/* Device Notification Form submission */}
        <Container>
          {!pending && notificationMethodSelected && deviceNotificationFlow &&
            <Row className="justify-content-center">
              <Button
                onClick={validateDeviceNotificationSubmission}
                size="lg">
                Send Device Notification
              </Button>
            </Row>}

          {/* Slack Notification Form submission */}
          {!pending && notificationMethodSelected && slackNotificationFlow &&
            <Row className="justify-content-center">
              {allSlackUsersConfirmed && slackNotification != null && slackNotification.length > 0 &&
                <Container className="d-flex flex-column align-items-center py-4">
                  <h6 className="text-center">Warning: This notification will be sent to all OnLoop Slack users.</h6>
                  <h6 className="text-center mb-4">Please click the button below only if you wish to proceed.</h6>
                </Container>
              }
              {selectedSlackUsers.length > 0 && slackNotification != null && slackNotification.length > 0 &&
                <Button
                  onClick={validateSlackNotificationSubmission}
                  size="lg">
                  Send Slack Notification
                </Button>
              }
            </Row>}

          {pending && (
            <Row className="justify-content-center row-margin">
              <LoadingCircle />
            </Row>
          )}
          {responseType && (
            <Row className="justify-content-center row-margin">
              <Alert variant={responseType}>{responseMessage}</Alert>
            </Row>
          )}
          {errorUserEmail && (
            <Row className="justify-content-center row-margin">
              <Alert variant={"danger"}>
                The following email addresses are not valid.{" "}
                {inValidateUserEmails}
              </Alert>
            </Row>
          )}
        </Container>

        {/* Device Notification Modal */}
        <Modal show={show} onHide={!show}>
          <Modal.Header closeButton>
            <Modal.Title>Warning!</Modal.Title>
          </Modal.Header>
          <Modal.Body>By entering <strong>all</strong>, this notification will be sent to all users of OnLoop. Do you wish to continue?</Modal.Body>
          <Modal.Footer>
            <Button onClick={handleConfirm}>
              Yes
            </Button>
            <Button onClick={handleCancel}>
              No
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Slack Notification Modal */}
        <Modal show={showSlackModal} onHide={!showSlackModal}>
          <Modal.Header closeButton>
            <Modal.Title>Warning!</Modal.Title>
          </Modal.Header>
          <Modal.Body>This notification will be sent to <strong>all OnLoop Slack users</strong>. You will not be able to select individual users if you proceed. Do you wish to continue?</Modal.Body>
          <Modal.Footer>
            <Button onClick={handleConfirmForSlackUsers}>
              Yes
            </Button>
            <Button onClick={handleCancelForSlackUsers}>
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
};
export default Notifications;
