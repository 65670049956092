import React, { useContext } from "react";
import { Nav, Navbar, Row } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import { AuthContext } from "../services/auth";
import app from "../services/firebase";
import history from "../services/history";
import './component-styles.css';

const signOut = () => {
  app.auth().signOut();
  history.push("/login");
};

const SideNavBar = () => {
  const { currentUser, gpt3Admin, gpt3Moderation, syncColleagues } = useContext(AuthContext);
  return (
    <Navbar expand="sm" sticky="top" className="nav-bar-background align-items-start py-sm-5">
      <Row className="m-0 w-100 justify-content-between flex-xl-column">
        <Navbar.Brand className="nav-brand-title ml-sm-1 px-xl-2" href="/home">ONLOOP ADMIN</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
            <Nav variant = "pills" className="flex-column my-5 px-xl-2">
            <Nav.Link as={NavLink} to="/createUser" activeClassName="nav-link-active">Create Test User</Nav.Link>
            <Nav.Link as={NavLink} to="/createOrgUser" activeClassName="nav-link-active">Create Org User</Nav.Link>
              <Nav.Link as={NavLink} to="/referralCode" activeClassName="nav-link-active">Generate Referral Code</Nav.Link>
              <Nav.Link as={NavLink} to="/learnContent" activeClassName="nav-link-active">Learn Recommendation</Nav.Link>
              <Nav.Link as={NavLink} to="/notifications" activeClassName="nav-link-active">Send Notifications</Nav.Link>
              <Nav.Link as={NavLink} to="/addColleagues" activeClassName="nav-link-active">Add Colleagues</Nav.Link>
              {syncColleagues && <Nav.Link as={NavLink} to="/syncColleagues" activeClassName="nav-link-active">Sync user's colleague</Nav.Link>}
              <Nav.Link as={NavLink} to="/createMPTeams" activeClassName="nav-link-active">Create MP Teams</Nav.Link>
              <Nav.Link as={NavLink} to="/createCustomTeams"activeClassName="nav-link-active">Create Custom Teams</Nav.Link>
              <Nav.Link as={NavLink} to="/createCustomTags"activeClassName="nav-link-active">Create Custom Tags</Nav.Link>
              <Nav.Link as={NavLink} to="/createManagerCapture"activeClassName="nav-link-active">Create Manager Capture</Nav.Link>
              {gpt3Moderation && <Nav.Link as={NavLink} to="/manageOrg" activeClassName="nav-link-active">Manage Org</Nav.Link>}
              {gpt3Moderation && <Nav.Link as={NavLink} to="/generatePrismSummary" activeClassName="nav-link-active">Generate Prism summary</Nav.Link>}
              {gpt3Admin && <Nav.Link as={NavLink} to="/generateReview" activeClassName="nav-link-active">Generate Review</Nav.Link>}
              <Navbar.Brand className="nav-link-name pt-5 px-2">{currentUser.displayName}</Navbar.Brand>
              <Nav.Link href="/login" onClick={signOut} className="px-2">Sign Out</Nav.Link>
            </Nav>
          </Navbar.Collapse>
      </Row>
    </Navbar>
  );
}

export default SideNavBar;