import { render } from '@testing-library/react';
import 'moment-timezone';
import React, { useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import TextareaAutosize from 'react-textarea-autosize';
import "./component-styles.css";


//im using vars because i dont think we need states for these 
var typewriteString = "";
var typewriteBuffer = "";
var printing = false;
var printSpeedFactor = 1;
var forceEndAnimation = false;



const Typewriter = (props) => {

  const [typewriteOutput, setTypewriteOutput] = useState("");

  useEffect(() => {
    forceEndAnimation = props.endAnimation;
  }, [props]);

  const typewriterNextLetter = () => {

    printing = typewriteBuffer.length < typewriteString.length;
    if (printing) {
      const nextChar = typewriteString[typewriteBuffer.length];
      typewriteBuffer += nextChar;

      //various delays based on character for better flow
      var delay = 75;
      switch (nextChar) {
        case "\n": delay = 1500; break;
        case " ": delay = 100; break;
        case ".":
        case "?":
        case "!":
        case "":
          delay = 500;
          break;
      }
      setTimeout(typewriterNextLetter, delay * printSpeedFactor);
    }

    if (forceEndAnimation) {
      typewriteBuffer = typewriteString;
    }

    setTypewriteOutput(typewriteBuffer);
    //theres probably some better react way to do this
    const typewriter = document.getElementById("typewriter");
    if (typewriter !== null) {
      typewriter.scrollTop += (typewriter.scrollHeight - typewriter.scrollTop) / 100;

      if (!printing) {
        typewriter.style.overflow = "scroll";

        if (!forceEndAnimation) {
          typewriter.scrollTop = typewriter.scrollHeight;
        }
        typewriter.scrollTop--;
        typewriter.scrollTop++;
      }
    }


  }

  const addStringArrayToTypewriter = (arrayOfStrings) => {
    if (arrayOfStrings != null) {
      typewriteString = arrayOfStrings.join("\n");
      typewriterNextLetter();
    }

  }

  if (!typewriteOutput) {
    addStringArrayToTypewriter(props.input);
  }



  return (
    <TextareaAutosize className="typewriter" id="typewriter" readOnly value={prompt} minRows={8} maxRows={8} style={{ width: "100%", overflow: 'hidden', resize: 'none' }} value={typewriteOutput} />
  );
};



export default Typewriter;
