import algoliasearch from 'algoliasearch/lite';
import firebase from "firebase";
import React, { Fragment, useState } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import connected from "../assets/mp_connected.png";
import pending from "../assets/mp_pending.png";
import not_connected from "../assets/sp_notconnected.png";
import LoadingCircle from "../components/loading";
import UserSearch from "../components/user-search";
import * as constants from "../constants";
import "./page-styles.css";

const searchClient = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_API_KEY);
const index = searchClient.initIndex('users');
var firebaseApp = firebase.app();
var functions = firebaseApp.functions("asia-northeast1");

const AddColleagues = () => {

  // Initialising state variables and functions
  const [isLoading, setIsLoading] = useState(false);
  const [userOptions, setUserOptions] = useState([]);
  const [currentUser, setUser] = useState([]);
  const [existingColleagues, setExistingColleagues] = useState(null);
  const [addExistingUser, setAddExistingUser] = useState(true);
  const [addColleagueManually, setAddColleagueManually] = useState(false);
  const [newUserId, setNewUserId] = useState(null);
  const [newName, setNewName] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [newColleagues, setColleague] = useState([]);

  // Loading states
  const [existingColleaguesPending, setExistingColleaguesPending] = useState(false);
  const [addColleaguesPending, setAddColleaguesPending] = useState(false);

  // Message and status states
  const [addColleagueMessage, setAddColleagueMessage] = useState(null);
  const [addColleagueStatus, setAddColleagueStatus] = useState(null);
  const [responseType, setResponseType] = useState(null);
  const [responseMessage, setResponseMessage] = useState(null);

  // Error status and messages states
  const [errorNewName, setErrorNewName] = useState(false);
  const [errorNewEmail, setErrorNewEmail] = useState(false);

  // Start of Components segment

  /*
    Component to populate Existing Colleagues table when 'existingColleagues' state is populated
  */
  const ExistingColleagues = () => {
    if (existingColleagues.length != 0) {
      let existingColleaguesArray = existingColleagues;
      return (
        existingColleaguesArray.map((colleague) => (!colleague.is_user ?
          <>
            <Row className="align-middle justify-content-center">
              <h6>
                <span>
                  <img className="connected_status_icon pb-1" src={colleague.connected_status == constants.CONNECTED_STATUS_CONNECTED ? connected : colleague.connected_status == constants.CONNECTED_STATUS_PENDING ? pending : not_connected} />
                </span>   {colleague.name || "Name not provided"}  <small>({colleague.email})</small>
              </h6>
            </Row>
            <hr className='mt-1 mb-3' />
          </> : <></>
        ))
      )
    }
    else if (currentUser.length != 0) {
      return (
        <>
          <Row className="align-middle justify-content-center">
            <h6>No Existing Colleagues Found!</h6>
          </Row>
          <hr className='mt-1 mb-3' />
        </>
      )
    }
    else {
      return (
        <>
        </>
      )
    }
  }


  /*
    Component to populate Colleagues to Add table when 'newColleagues' state is populated
  */
  const NewColleagues = () => {

    if (newColleagues) {
      let colleagueArray = newColleagues;
      return (
        colleagueArray.map((colleague, index) => (
          <>
            <Row>
              <Col lg={9}>
                <h6 className="text-center pt-2">
                  {colleague.name || "Name not provided"} <small>({colleague.email})</small>
                </h6>
              </Col>
              <Col lg={3}>
                <Button
                  className="mt-0 mb-1"
                  variant="danger"
                  onClick={() => removeColleague({ index })}>
                  Delete
              </Button>
              </Col>
            </Row>
            <hr className='mt-1 mb-2' />
          </>
        )
        )
      )
    }
  }

  // End of Components segment

  // Start of Functions segment

  /*
    AsyncTypeAhead Function
    The filterBy prop can be used in one of two ways: to specify option properties that should be searched or to pass a custom callback.
    Set filterBy to true by default
  */
  const filterBy = () => true;

  /* 
    AsyncTypeAhead x Algolia Function
    Call Algolia function to retrieve objectId, name and email
    Param query refers to what the user enters into the AsyncTypeAhead Textbox
    Assign hits obtained from Algolia to userOptions and assigned it to state userOptions
  */
  const handleSearch = async (query) => {
    setIsLoading(true);
    await index.search(query, {
      attributesToRetrieve: ['objectID', 'name', 'email'],
    }).then(({ hits }) => {
      const retrievedOptions = hits.map((user) => ({
        objectId: user.objectID,
        name: user.name,
        email: user.email,
      }));
      setUserOptions(retrievedOptions);
    });
    setIsLoading(false);
  };

  /*
    This function retrieves existing colleagues of a given user by calling cloud function 'callGetUserExistingColleagues' 
    and storing the array of existing collegues array into state 'existingColleagues'
    param selectedUser refers to a user object e.g. {name: "Ashley", objectId: "xxxx", email: "ashley@onloop.io"}
   */
  const getUserColleagues = async (selectedUser) => {
    setExistingColleaguesPending(true);
    setResponseType(null);
    setResponseMessage(null);
    setAddColleagueMessage(null);
    setAddColleagueStatus(null);

    if (selectedUser == null) {
      setUser([]);
      setColleague([]);
      setExistingColleagues(null);
    }
    else {
      setUser(selectedUser);
    }

    const getColleagues = functions.httpsCallable("callGetUserExistingColleagues");
    await getColleagues(selectedUser).then(function (res) {
      const result = JSON.parse(JSON.stringify(res.data));
      if (result.statusCode === 200) {
        setExistingColleagues(result.colleagueArray);
      }
    });
    setExistingColleaguesPending(false);
  }

  // Function to determine which segment to render and how does the user want to specify the new colleague details
  const addColleagueMethod = (method) => {
    setAddColleagueMessage(null);
    setAddColleagueStatus(null);
    if (method === constants.SEARCH_COLLEAGUE_FROM_ALGOLIA) {
      setAddColleagueManually(false);
      setAddExistingUser(true);
    } else {
      setAddExistingUser(false);
      setAddColleagueManually(true);
    }

  }

  /*
    Function to populate the state after user enters a new colleague using the Algolia search bar
  */
  const addAlgoliaUserAsColleague = (selectedUser) => {
    setResponseType(null);
    setResponseMessage(null);
    setAddColleagueMessage(null);
    setAddColleagueStatus(null);

    if (!selectedUser) {
      setNewUserId(null);
      setNewName('');
      setNewEmail('');
    }
    else {
      setNewName(selectedUser.name);
      setNewEmail(selectedUser.email);
      setNewUserId(selectedUser.objectId);
    }
  }

  /*
    onClick function for the 'Add Colleague' button validate input 'newName' and 'newEmail' to prevent empty name/email and invalid email format
    If there are no error, make call to 'addColleagueToArray' to add new information as a new colleague in the 'newColleagues' state array
  */
  const validateNewColleague = () => {
    let error = false;

    // Check if new name has been added
    if (newName === null || newName.length === 0) {
      setErrorNewName("Please enter a valid name");
      error = true;
    }
    else {
      setErrorNewName(false);
    }

    // Check if new email has been added
    if (newEmail === null || newEmail.length === 0) {
      setErrorNewEmail("Please enter a valid email address");
      error = true;
    }
    else {
      const regex = /\S+@\S+\.\S+/; // checks for a valid format (anystring@anystring.anystring)
      if (regex.test(String(newEmail).toLowerCase())) {
        setErrorNewEmail(false);
      } else {
        setErrorNewEmail("Please enter a valid email address");
        error = true;
      }
    }

    // If no error, call function to add to array
    if (!error) {
      addColleagueToArray();
    }

  };

  /*
    Function for the 'Add Colleague' button to add a new object which consist of the new name and new email into the existing newColleagues array
    Reset state after it has been successfully added into the array
  */
  const addColleagueToArray = () => {
    setResponseType(null);
    setResponseMessage(null);
    setAddColleagueMessage(`Successfully added ${newName} (${newEmail}) to the new colleague list!`);
    setAddColleagueStatus("success");
    let array = [...newColleagues, { "uid": newUserId, "name": newName, "email": newEmail }];
    setColleague(array);
    setNewUserId(null);
    setNewName('');
    setNewEmail('');
  }

  /*
    onClick function for the 'Delete' button in the 'NewColleagues' component table
    Remove selected colleague by index from the newColleagues array
    index refers to the key of the 'Delete' button upon initialising the table
  */
  const removeColleague = (index) => {
    setResponseType(null);
    setResponseMessage(null);
    setAddColleagueMessage(null);
    setAddColleagueStatus(null);
    let colleagues = newColleagues;
    colleagues.splice(index.index, 1);
    setColleague([...colleagues]);
  }

  /*
    onClick function for the 'Submit' button. To validate if currentUser has been initialised and there are new colleagues to add (based on 'newColleagues' state)
    If there are no error, make call to 'sendNewColleagues' to make a Firebase Cloud Function call to 'callAddColleaguesToUser' to do a batch adding of these new users as colleagues of the currentUser
  */
  const validateSubmission = async () => {

    // Check if the currentUser has been initialised
    if (currentUser == [] || currentUser.length == 0) {
      setResponseType("danger");
      setResponseMessage("Please search for a user!");
    } // Validate if there is addedColleagues
    else if (newColleagues === [] || newColleagues.length == 0) {
      setResponseType("danger");
      setResponseMessage("Please add a colleague!");
    }
    else {
      setAddColleaguesPending(true);
      await sendNewColleagues();
      setAddColleaguesPending(false);
    }
  };

  /*
    Function triggered by 'validateSubmission' when there are no errors.
    Package currentUser's objectId and the array of newColleagues into an object 'data' as 'currentUser' & 'newColleagues' values respectively
    After which make the Cloud Function call to 'callAddColleaguesToUser' and pass 'data' as a function parameter.
    
    statusCode: 
    200 - success
  */
  const sendNewColleagues = async () => {
    const data = { "currentUser": currentUser.objectId, "newColleagues": newColleagues }
    const addColleaguesToUser = functions.httpsCallable("callAddColleaguesToUser");
    await addColleaguesToUser(data).then(function (res) {
      const result = JSON.parse(JSON.stringify(res.data));
      if (result.statusCode === 200) {
        getUserColleagues(currentUser);
        setColleague([]);
        setResponseType("success");
        setResponseMessage("Successfully added users!");
      }
      else {
        setResponseType("danger");
        setResponseMessage(result.statusMessage);
      }
    });
  };

  // End of Functions segment

  return (
    <div className="page-container">
  
      <Container className="d-flex justify-content-center my-4">
        <h2>Add Colleagues</h2>
      </Container>
      <Container>
        <ol className="notification-hint-padding">
          <li>Search for user you wish to add colleagues</li>
          <li>Search for existing colleague OR add full name and email for colleagues</li>
          <li>Verify the list of all the colleagues and their details before clicking submit as no changes can happen after</li>
        </ol>
        <Form>
          {/* Search Users */}
          <Form.Group>
            <Form.Label><b>Step 1: Search User to add Colleagues</b></Form.Label>
            <UserSearch
              id={'find-current-user'}
              className="pr-0 w-100"
              isLoading={isLoading}
              labelKey="email"
              minLength={3}
              onSearch={handleSearch}
              options={userOptions}
              placeholder="name@example.com"
              onChange={(selected) => {
                getUserColleagues(selected);
              }} />
          </Form.Group>
          {
            currentUser.length != 0 &&
            <Form>
              <Form.Label className="text-center w-100"><b>Step 2: Add New Colleague</b></Form.Label>
              <Row className='justify-content-center m-3'>
                <Form.Check
                  inline
                  name="add-colleague-type"
                  label="Add Existing OnLoop Users"
                  defaultChecked
                  value={constants.SEARCH_COLLEAGUE_FROM_ALGOLIA}
                  type={'radio'}
                  onClick={(event) => { addColleagueMethod(event.target.value) }}
                  id={`add-existing-colleauges`}
                />
                <Form.Check
                  inline
                  name="add-colleague-type"
                  label="Add Colleague Details Manually"
                  value={constants.ADD_DETAILS_MANUALLY}
                  type={'radio'}
                  onClick={(event) => { addColleagueMethod(event.target.value) }}
                  id={`add-details-manually`}
                />
              </Row>
            </Form>
          }
          {
            addExistingUser && currentUser.length != 0 &&
            <Row className="w-100 mx-0 mb-3">
              <Col>
                <Row className="mt-2 justify-content-center">
                  <Form.Label><b>* Search for an existing user on OnLoop to be added as a colleague *</b></Form.Label>
                </Row>
                <Row>
                  {/* Search New Colleague from Algolia */}
                  <Col lg={1} className='label-alignment'>
                    <b>Email</b>
                  </Col>
                  <Col lg={11} className="pr-0 w-100">
                    <UserSearch
                      id={'find-user-from-algolia'}
                      className="pr-0 w-100"
                      isLoading={isLoading}
                      onSearch={handleSearch}
                      minLength={3}
                      options={userOptions}
                      onChange={addAlgoliaUserAsColleague} />
                  </Col>
                </Row>
              </Col>
            </Row>
          }
          {
            addColleagueManually && currentUser.length != 0 &&
            <Row>
              <Col>
                <Row className="mt-2 justify-content-center">
                  <Form.Label><b>* Enter the Name and Email of the colleague to be added as a colleague *</b></Form.Label>
                </Row>
                <Row>
                  {/* New Colleague Name */}
                  <Col lg={1} className='label-alignment'><b>Name</b></Col>
                  <Col lg={11}>
                    <Form.Group className="pr-0 w-100">
                      <Form.Control
                        type="text"
                        placeholder="OnLoop Admin"
                        value={newName || ''}
                        onChange={(event) => { setNewName(event.target.value); }}
                        isInvalid={!!errorNewName ? true : false} />
                      <Form.Control.Feedback type="invalid">
                        {errorNewName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  {/* New Colleague Email */}
                  <Col lg={1} className='label-alignment'><b>Email</b></Col>
                  <Col lg={11}>
                    <Form.Group className="pr-0 w-100">
                      <Form.Control
                        type="email"
                        placeholder="email@onloop.io"
                        value={newEmail || ''}
                        onChange={(event) => { setNewEmail(event.target.value); }}
                        isInvalid={!!errorNewEmail ? true : false}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorNewEmail}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
          }
          {
            currentUser.length != 0 &&
            <Row>
              {/* Add New Colleague Button */}
              <Container className="justify-content-center w-100">
                <Row className="justify-content-center w-100">
                  <Button variant="primary label-alignment mt-1" onClick={() => { validateNewColleague(); }}>
                    Add Colleague
                  </Button>
                </Row>
                <Row className="justify-content-center w-100 text-align mt-2">
                  {addColleagueMessage &&
                    <Alert variant={addColleagueStatus}>{addColleagueMessage}</Alert>
                  }
                </Row>
              </Container>
            </Row>
          }
        </Form>
        {/* Existing Colleagues and Colleagues to Add */}
        {
          currentUser.length != 0 &&
          <>
            <Row className="mt-4">
              <Col>
                <div>
                  <Form.Label className="text-center w-100">
                    <b>Existing Colleagues</b>
                  </Form.Label>
                  <Row className="justify-content-center">
                    {existingColleaguesPending
                      && <LoadingCircle />}
                  </Row>
                  <Container>
                    <hr className='mt-2 mb-3' />
                    {
                      !existingColleaguesPending
                      && existingColleagues
                      && <ExistingColleagues />
                    }
                  </Container>
                </div>
              </Col>
              <Col>
                <div>
                  <Form.Label className="text-center w-100">
                    <b>Colleagues to Add</b>
                  </Form.Label>
                  {/* <Table bordered hover> */}
                  <Container>
                    <hr className='mt-2 mb-2' />
                    {
                      newColleagues
                      && <NewColleagues />
                    }
                    {/* </Table> */}
                  </Container>
                </div>
              </Col>
            </Row>
            <div className="pt-5">
              <Row className="justify-content-center">
                <Form.Label className="warning-tip">Step 3: Please ensure all details are correct - There is no going back!</Form.Label>
              </Row>
              <Row className="justify-content-center">
                {!addColleaguesPending &&
                  <Button variant="primary" onClick={validateSubmission}>
                    Submit!
                  </Button>
                }
                {addColleaguesPending &&
                  <LoadingCircle />
                }
              </Row>
            </div>
          </>
        }
        <Container>
          {responseType && (
            <Row className="justify-content-center row-margin">
              <Alert variant={responseType}>{responseMessage}</Alert>
            </Row>
          )}
        </Container>
      </Container>
    </div >
  );

};

export default AddColleagues