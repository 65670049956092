import firebase from "firebase";
import 'moment-timezone';
import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import ProgressBar from 'react-bootstrap/ProgressBar';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'react-moment';
import StarPicker from 'react-star-picker';
import TextareaAutosize from 'react-textarea-autosize';
import FullCalendar from '../components/full-calendar-date-picker';
import LoadingCircle from "../components/loading";
import ScreenSizeDetector from '../components/screen-size-detector';
import TypeWriter from '../components/typewriter';
import { AuthContext } from '../services/auth';
import "./page-styles.css";
var dayjs = require('dayjs')

var firebaseApp = firebase.app();
var functions = firebaseApp.functions("asia-northeast1");


const GenerateReview = () => {
  const { currentUser } = useContext(AuthContext);
  const [viewingUser] = useState(currentUser);
  const [existingColleagues, setExistingColleagues] = useState(null);
  const [selectedColleagueId, setSelectedColleagueId] = useState(null);
  const [selectedColleague, setSelectedColleague] = useState(null);
  const [firstCaptureTimestamp, setFirstCaptureTimestamp] = useState(null);
  const [lastCaptureTimestamp, setLastCaptureTimestamp] = useState(null);
  const [lastReviewTimestamp, setLastReviewTimestamp] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [showDatePicker, setDatePickerOption] = useState(false);
  const [showAllDate, setAllDateOption] = useState(false);
  const [showGenerateButton, setShowGenerateButton] = useState(true);
  const [feedbacks, setFeedbacks] = useState(null);
  const [endAnimation, setEndAnimation] = useState(false);
  const [review, setReview] = useState(null);
  const [reviewRatings, setReviewRatings] = useState(null);
  const [reviewRatingsText, setReviewRatingsText] = useState(null);
  const [feedbackReviewPath, setFeedbackReviewPath] = useState(null);
  const [fullCalendarSelected, setFullCalendarSelected] = useState(true);

  // Message and status states
  const [responseType, setResponseType] = useState(null);
  const [responseMessage, setResponseMessage] = useState(null);

  // Loading states
  const [existingColleaguesPending, setExistingColleaguesPending] = useState(null);
  const [generateReviewPending, setGenerateReviewPending] = useState(null);
  const [reviewRatingPending, setReviewRatingPending] = useState(null);
  const [ProgressBarValue, setProgressBar] = useState(null);
  const [loadingText, setLoadingText] = useState(null);
  const screenSize = ScreenSizeDetector();

  const width = screenSize?.[0] ?? window.innerWidth;

  useEffect(() => {
    getUserColleagues(viewingUser.uid);
  }, []);

  const ExistingColleagues = () => {
    if (existingColleagues.length != 0) {
      return (
        <Form.Control className="w-100" as="select" type="text" onChange={(event) => { selectedColleagueDetails(event.target.value != '' ? event.target.value : null) }} size="md" custom>
          <option default value=''>Select a Colleague</option>
          {
            existingColleagues.map((colleague) => (!colleague.is_user ?
              <option value={colleague.colleague_id} selected={selectedColleagueId == colleague.colleague_id}>
                {colleague.name || "Name not provided"} {colleague.email === null ? "" : `(${colleague.email})`}
              </option> : <></>
            ))}
        </Form.Control>
      );
    }
    else if (existingColleagues.length == 0) {
      return (
        <Form.Control type="text" placeholder="Selected User has no Colleagues!" readOnly />
      )
    }
    else {
      return (
        <>
        </>
      )
    }
  }

  const SelectedColleagueDetailsView = () => {
    useEffect(() => {
      getLastReview();
    }, []);
    return (
      <Row className='mb-2'>
        <Col className='text-start'>First Capture Made for {selectedColleague?.name}: <b>{firstCaptureTimestamp == null ? "No capture has been made for colleague!" : <Moment unix format="DD MMM YYYY" className="review-text-style">{firstCaptureTimestamp}</Moment>}</b></Col>
        <Col className='text-start'>Last Capture Made for {selectedColleague?.name}: <b>{lastCaptureTimestamp == null ? "No capture has been made for colleague!" : <Moment unix format="DD MMM YYYY" className="review-text-style">{lastCaptureTimestamp}</Moment>}</b></Col>
        <Col className='text-start'>Last Review Generated on: <b>{lastReviewTimestamp == null ? "No review has been generated" : <Moment unix format="DD MMM YYYY" className="review-text-style">{lastReviewTimestamp}</Moment>}</b></Col>
      </Row >);
  }

  const getUserColleagues = async (viewingUserId) => {
    // callGetUserExistingColleagues() is an existing function that takes in two params
    // so just mapping the data to ensure this functions works without editing the current function
    const selectedUser = {
      objectId: viewingUserId
    };
    setExistingColleaguesPending(true);
    const getColleagues = functions.httpsCallable("callGetUserExistingColleagues");
    await getColleagues(selectedUser).then(function (res) {
      const result = JSON.parse(JSON.stringify(res.data));
      if (result.statusCode === 200) {
        setExistingColleagues(result.colleagueArray);
      }
    });
    setExistingColleaguesPending(false);
  }

  const selectedColleagueDetails = async (colleagueId) => {
    resetStates();
    setSelectedColleagueId(colleagueId);
    existingColleagues.map((colleague) => {
      if (colleague.colleague_id == colleagueId) {
        setSelectedColleague(colleague);
        const firstCapture = colleague.first_capture_date?._seconds;
        const lastCapture = colleague.last_capture_date?._seconds;
        if (firstCapture != null && lastCapture != null) {
          setFirstCaptureTimestamp(firstCapture);
          setLastCaptureTimestamp(lastCapture);
        }
      }
    });
  }

  const getLastReview = async () => {
    const data = {
      colleagueId: selectedColleagueId
    };
    const getLastReviewFunction = functions.httpsCallable("getLastReviewGeneratedDate");
    await getLastReviewFunction(data).then(function (res) {
      const result = JSON.parse(JSON.stringify(res.data));
      if (result.statusCode === 200) {
        setLastReviewTimestamp(result?.latestFeedbackReviewCreatedAt?._seconds);
      }
    });
  }

  const validate = async () => {
    setShowGenerateButton(false);
    setProgressBar(0);
    let data;
    if (showAllDate) {
      if (firstCaptureTimestamp != null && lastCaptureTimestamp != null) {
        data = {
          colleagueUid: selectedColleagueId,
          fromDate: firstCaptureTimestamp,
          toDate: lastCaptureTimestamp
        }
        getListOfFeedbacks(data);
        generateReview(data);
      } else {
        setShowGenerateButton(true)
        setProgressBar(null);
        setResponseType("danger");
        setResponseMessage("No capture has been made on this colleague.");
        return false;
      }
    } else if (showDatePicker) {
      if (startDate != null && endDate != null) {
        let startDateTimestamp;
        let endDateTimestamp;
        if (!fullCalendarSelected) {
          const startDateValue = dayjs(startDate);
          const endDateValue = dayjs(endDate);
          startDateTimestamp = startDateValue.unix();
          endDateTimestamp = endDateValue.unix();
        } else {
          startDateTimestamp = startDate.unix();
          endDateTimestamp = endDate.unix();
        }
        data = {
          colleagueUid: selectedColleagueId,
          fromDate: startDateTimestamp,
          toDate: endDateTimestamp >= lastCaptureTimestamp ? lastCaptureTimestamp : endDateTimestamp
        }
        getListOfFeedbacks(data);
        generateReview(data);
        return true;
      } else {
        // If either start or end date not selected from calendar
        setShowGenerateButton(true)
        setProgressBar(null);
        setResponseType("danger");
        setResponseMessage("Please select both a start and end date.");
        return false;
      }
    } else {
      // If time period radio buttons are not selected
      setShowGenerateButton(true);
      setResponseType("danger");
      setResponseMessage("Please select a time period option from above.");
      return false;
    }

  }

  useEffect(() => {
    switch (ProgressBarValue != null) {
      case ProgressBarValue >= 0 && ProgressBarValue < 90:
        setTimeout(() => {
          setProgressBar(ProgressBarValue + 10)
        }, 1000);
        break;
      case ProgressBarValue == 30:
        setLoadingText("1/3 Fetching your Captures...");
        break;
      case ProgressBarValue == 60:
        setLoadingText("2/3 Understanding more about your colleague...");
        break;
      case ProgressBarValue == 90:
        setLoadingText("3/3 Writing the review...");
        break;
      default:
        setLoadingText(null);
    }
  });

  const getListOfFeedbacks = async (data) => {
    setResponseType(null);
    setResponseMessage(null);
    const getFeedbacksFunction = functions.httpsCallable("retrieveCapturesWithTagsList");
    await getFeedbacksFunction(data).then(function (res) {
      const result = JSON.parse(JSON.stringify(res.data));
      if (result != null) {
        if (result.statusCode === 200) {
          setFeedbacks(result?.feedbackItemsList);
        } else {
          setResponseType("danger");
          setResponseMessage(result?.message);
        }
      }
    });
  }

  const generateReview = async (data) => {
    setFeedbacks(null);
    setEndAnimation(false);
    setReview(null);
    setResponseType(null);
    setResponseMessage(null);
    setGenerateReviewPending(true);
    const getReviewFunction = functions.httpsCallable("generateReview");
    await getReviewFunction(data).then(function (res) {
      const result = JSON.parse(JSON.stringify(res.data));
      if (result != null) {
        setEndAnimation(true);
        setProgressBar(100);
        setShowGenerateButton(true);
        if (result.statusCode === 200) {
          setReview(result?.output);
          setFeedbackReviewPath(result?.feedbackReviewDocPath);
        } else {
          setProgressBar(null);
          setResponseType("danger");
          setResponseMessage(result?.message);
          setGenerateReviewPending(false);
        }
      } else {
        setShowGenerateButton(true);
        setProgressBar(null);
        setResponseType("danger");
        setResponseMessage("Oops! Something went wrong, please try again after sometime.");
        setGenerateReviewPending(false);
      }
    });
    getLastReview();
    setGenerateReviewPending(false);

  }

  const selectReviewRatingText = (event) => {
    setReviewRatingsText(event.target.value);
    updateRatingForReview();
  }

  const updateRatingForReview = async () => {
    setReviewRatingPending(true);
    if (reviewRatings == null) {
      setResponseType("danger");
      setResponseMessage("Please select select a rating.");
      return
    }

    const data = {
      feedbackReviewPath: feedbackReviewPath,
      feedbackReviewRating: reviewRatings,
      feedbackReviewComment: reviewRatingsText != null ? reviewRatingsText : ''
    }

    const updateReviewRatingFunction = functions.httpsCallable("updateFeedbackReviewRating");
    await updateReviewRatingFunction(data).then(function (res) {
      const result = JSON.parse(JSON.stringify(res.data));
      if (result.statusCode === 200) {
        setResponseType("success");
        setResponseMessage(result?.message);
      } else {
        setResponseType("danger");
        setResponseMessage(result?.message);
      }
      setReviewRatingPending(false);
    });
  }

  const resetStates = async () => {
    setSelectedColleague(null);
    setSelectedColleagueId(null);
    setFirstCaptureTimestamp(null);
    setLastCaptureTimestamp(null);
    setLastReviewTimestamp(null);
    setStartDate(null);
    setEndDate(null);
    setDatePickerOption(false);
    setAllDateOption(false);
    setFeedbacks(null);
    setEndAnimation(false);
    setReview(null);
    setReviewRatings(null);
    setFeedbackReviewPath(null);
    setFullCalendarSelected(true);
    setResponseType(null);
    setResponseMessage(null);
    setExistingColleaguesPending(null);
    setGenerateReviewPending(null);
    setProgressBar(null);
    setLoadingText(null);
    setShowGenerateButton(true);
    setReviewRatingPending(false);
  }

  return (
    <div className="page-container">
      <Container className="d-flex justify-content-center my-4">
        <h2>Generate Review by GPT-3</h2>
      </Container>
      <Container className="w-100">
        <Form>
          <Form.Group>
            <Form.Label>
              Select A Colleague to Generate Review for:
            </Form.Label>
            <Form.Row>
              {(!existingColleaguesPending && existingColleagues !== null) && < ExistingColleagues />}
              {(existingColleaguesPending && existingColleagues == null) && <Form.Row className="justify-content-center p-2">
                <LoadingCircle />
              </Form.Row>}
            </Form.Row>
          </Form.Group>
          <Form.Group>
            {selectedColleague !== null && <SelectedColleagueDetailsView selectedColleague={selectedColleague} />}
          </Form.Group>
          {firstCaptureTimestamp !== null && <Form.Group>
            <Form.Row >
              <Col lg={4}><Form.Label>Select time period to generate reviews for:</Form.Label></Col>
              <Col><Form.Check
                inline
                checked={showAllDate}
                className="review-text-style"
                name="select-date-period"
                label='Include all Captures'
                onClick={() => {
                  setDatePickerOption(false)
                  setAllDateOption(true)
                }}
                type={'radio'}
                id={`select-all`}
              />
                <Form.Check
                  inline
                  checked={showDatePicker}
                  className="review-text-style"
                  name="select-date-period"
                  label="Include all Captures within a time period"
                  onClick={() => {
                    setDatePickerOption(true)
                    setAllDateOption(false)
                  }}
                  type={'radio'}
                  id={`select-from-a-range`}
                /></Col>
            </Form.Row>
          </Form.Group>}
          {showAllDate && <Row className="justify-content-center row-margin w-100">
            <Alert variant={'primary'}>All captures between <b><Moment unix format="DD MMM YYYY">{firstCaptureTimestamp}</Moment></b> and <b><Moment unix format="DD MMM YYYY">{lastCaptureTimestamp}</Moment></b> will be included for the review.</Alert>
          </Row>}
          {(showDatePicker && width < 1000) && <Form.Group>
            <Form.Label>
              Select the start and end date
            </Form.Label>
            <Form.Row>
              <DatePicker
                className="datepicker"
                selected={null}
                onChange={(dates) => {
                  const [start, end] = dates;
                  setFullCalendarSelected(false);
                  setStartDate(start);
                  setEndDate(end);
                }}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
              />
            </Form.Row>
            {
              startDate != null && endDate != null &&
              <Form.Row className="justify-content-center row-margin">
                <Alert className="datepicker-alert" variant={'primary'}>All captures between <b>{new Date(startDate).toDateString()}</b> and <b>{new Date(endDate).toDateString()}</b> will be included for the review.</Alert>
              </Form.Row>
            }
          </Form.Group>}
          {(showDatePicker && width > 1000) && <FullCalendar
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setResponseType={setResponseType}
            setResponseMessage={setResponseMessage}
          />}
        </Form>
        <Container>
          {responseType && (
            <Row className="justify-content-center row-margin w-100">
              <Alert variant={responseType}>{responseMessage}</Alert>
            </Row>
          )}
          {((firstCaptureTimestamp && lastCaptureTimestamp) || (startDate && endDate)) &&
            <>
              <Row className="justify-content-center W-100 mt-1">
                <Form.Label className="warning-tip">Please ensure all details are correct - There is no going back!</Form.Label>
              </Row>
              <Row className="justify-content-center w-100 mx-0 my-1">
                {(!generateReviewPending && showGenerateButton) && <Button onClick={validate}>
                  Generate Review!
                </Button>}
                {(generateReviewPending && ProgressBarValue !== null) &&
                  <Container><ProgressBar animated variant={"progress-bar"} now={ProgressBarValue} label={`${ProgressBarValue}%`} /><Row className="justify-content-center W-100 mt-1 review-text-style "><Form.Label>{loadingText !== null ? loadingText : ''}</Form.Label></Row></Container>}
              </Row>
              <Row className="justify-content-center W-100">
                <Form.Label className="warning-tip"><small>Powered by GPT-3</small></Form.Label>
              </Row>
            </>
          }
        </Container>

        {feedbacks != null && <Container><Form.Group>
          <Form.Label><b>Here are the feedbacks used to write the review:</b></Form.Label>
          <Form.Row><TypeWriter
            input={feedbacks}
            endAnimation={endAnimation}
            className="typewriter-custom"
          ></TypeWriter>
          </Form.Row>
        </Form.Group>
        </Container>}

        {!generateReviewPending && review !== null &&
          <Container>
            <Form.Group>
              <Form.Label><b>Here is your generated review:</b></Form.Label>
              <Form.Row><TextareaAutosize readOnly value={review} minRows={5} style={{ width: "100%" }} /></Form.Row>
              <Form.Group className="mt-5">
                <Form.Row>
                  <Col lg="5"><Form.Label className="warning-tip pt-0.5">How do you rate this review? (1 star - Bad, 5 star - Excellent)</Form.Label>
                  </Col>
                  <Col><StarPicker
                    onChange={(ratings) => {
                      setReviewRatings(ratings);
                      updateRatingForReview();
                    }}
                    value={reviewRatings}
                    size={30}
                  />
                  </Col>
                </Form.Row>
                <Form.Label className="warning-tip" >Please tell us more:</Form.Label>
                <Form.Row className="d-flex justify-content-center justify-content-lg-between">
                  <Col lg="8"><Form.Control
                    onChange={selectReviewRatingText}
                    value={reviewRatingsText || ''}
                    as="textarea"
                    rows={3}
                  /></Col>
                  <Col>
                    {!reviewRatingPending && <Button onClick={updateRatingForReview}>
                      Submit Review</Button>}
                    {reviewRatingPending && <LoadingCircle />}
                  </Col>
                </Form.Row>
              </Form.Group>
            </Form.Group>
          </Container>}
        {reviewRatings != null &&
          <Row className="justify-content-center row-margin w-100">
            <Alert variant={"success"}>You have rated {reviewRatings} for this generated review!</Alert>
          </Row>}
      </Container>
    </div >
  );
};

export default GenerateReview;
