import firebase from "firebase";
import algoliasearch from 'algoliasearch/lite';
import React, { Fragment, useState, useEffect } from "react";
import { Button, Col, Container, Form, Row, Table, Alert } from "react-bootstrap";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import LoadingCircle from "../components/loading";
import UserSearch from "../components/user-search";

const searchClient = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_API_KEY);
const index = searchClient.initIndex('users');
const index2 = searchClient.initIndex('users');
var firebaseApp = firebase.app();
var functions = firebaseApp.functions("asia-northeast1");

const CreateMPTeams = () => {

  const [managerSearchLoading, setManagerSearchLoading] = useState(false);
  const [userSearchLoading, setUserSearchLoading] = useState(false);
  const [managerOptions, setManagerOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);

  const [selectedManager, setSelectedManager] = useState(null);

  const [teamCreated, setTeamCreated] = useState(false);
  const [teamStatusNotification, setTeamStatusNotification] = useState('');
  const [teamID, setTeamID] = useState(null);

  const [teamMembers, setTeamColleagues] = useState([]);
  const [existingColleagues, setExistingColleagues] = useState([]);

  // Used to retrieve index of colleague from existingColleagues array to 
  //populate colleagueFirestore with existingColleague object
  const [firestoreColleagueIndex, setFirestoreColleagueIndex] = useState(-1);
  const [colleagueFirestore, setColleagueFirestore] = useState(null);
  const [colleagueAlgolia, setColleagueAlgolia] = useState('');
  const [newColleagues, setNewColleagues] = useState([]);

  // Loading states
  const [retrievingTeam, setRetrievingTeam] = useState(false);
  const [creatingTeam, setCreatingTeam] = useState(false);
  const [loadingTeamMembers, setLoadingTeamMembers] = useState(false);
  const [loadingUserColleagues, setLoadingUserColleagues] = useState(false);
  const [addColleaguesPending, setAddColleaguesPending] = useState(false);

  //Response messages
  const [responseType, setResponseType] = useState('');
  const [responseMessage, setResponseMessage] = useState('');

  // Retrieve team members and colleagues after teamID has been initialised
  useEffect(async () => {
    if (teamID != null) {
      setLoadingTeamMembers(true);
      setLoadingUserColleagues(true);
      await getTeamMembers();
      setLoadingTeamMembers(false);
      await getUserColleagues();
      setLoadingUserColleagues(false);
    }
  }, [teamID]);

  // Start of Components segment

  /*
    Component to populate table which displays members from Manager's Directs team
  */
  const TeamMembers = () => {
    if (selectedManager !== null && teamMembers.length != 0) {
      let teamColleaguesArray = teamMembers;
      return (
        <Container>{
          teamColleaguesArray.map((colleague) => (
            <>
              <Row as="h6" className="align-middle text-center">
                <Col>{colleague.name || "Name not provided"} <small>({colleague.email})</small></Col>
              </Row>
              <hr />
            </>
          ))
        }
        </Container>
      )
    } else if (selectedManager != null) {
      return (
        <Container>
          <Row as="h6" className="align-middle text-center m-2">
            <Col>There are no colleagues in the team!</Col>
          </Row>
        </Container>
      )
    } else {
      return (
        <Container>
          <Row>
          </Row>
        </Container>
      )
    }
  }

  /*
    Component to populate dropdown list which shows the manager's existing colleagues.
    onChange calls the function assignColleague to populate firestoreColleague with the colleague object
    when value is -2, it indicates a new colleague will be added to the manager
    when value is -1, it indicates no colleague has been selected and for the function to clear firestoreColleague state and 
    to assign option 1 of dropdownlist as the selected variable
  */
  const ExistingColleagues = () => {
    if (existingColleagues.length != 0 && teamCreated) {
      let existingColleaguesArray = existingColleagues;
      return (
        <Form.Control as="select" id="dropdown-basic-button" className="mx-4 mb-2"
          onChange={assignColleague}
          value={firestoreColleagueIndex}>
          <option value={-1}>Select an existing colleague</option>
          {
            existingColleaguesArray.map((colleague, index) => (
              <option
                key={index}
                value={index}
              >
                {colleague.name || "name not initialized"} ({ colleague.email || "email not initialized"})
              </option>
            ))
          }
          {/* Return assignColleague function will pick up the value of -2 and create a new colleague */}
          <option value={-2} selected={firestoreColleagueIndex == -2 ? true : false}>Add New Colleague</option>
        </Form.Control >
      )

    } else if (existingColleagues.length == 0 && teamCreated) {
      return (
        <Form.Control as="select" className="mx-4 mb-2" disabled={true} onChange={assignColleague} value={firestoreColleagueIndex}>
          {/* Return assignColleague function will pick up the value of -1 and reset the firestoreColleague state */}
          <option value={-1}>Manager has no colleagues</option>
          {/* Return assignColleague function will pick up the value of -2 and create a new colleague */}
          <option value={-2} selected={firestoreColleagueIndex == -2 ? true : false}> Add New Colleague </option>
        </Form.Control>
      )
    } else if (teamStatusNotification.length == 0) {
      return (
        <Form.Control as="select" className="mx-4 mb-2" disabled='true'>
          <option> -- Please Search for a Manager! -- </option>
        </Form.Control>
      )
    }
  }

  /*
    Component to populate Colleagues to Add table when 'newColleagues' state is populated
  */
  const NewColleagues = () => {
    if (newColleagues) {
      let colleagueArray = newColleagues;
      return (
        <Container>
          <hr />
          {
            colleagueArray.map((colleague, index) => (
              <>
                <Row className="pt-2 pb-0 w-100">
                  <Col lg={10} className="pr-0 w-100">
                    <Row className="w-100">
                      {(colleague.colleague_name == null && colleague.colleague_email == null) &&
                        <Col>
                          <h6 className="text-danger">User will be added as a colleague and team member</h6>
                        </Col>
                      }
                      {(colleague.colleague_name != null || colleague.colleague_email != null) &&
                        <>
                          <Col lg={2} as="h6">
                            Colleague:
                          </Col>
                          <Col lg={10} as="h6" className="text-align-start">
                            {colleague.colleague_name == null ? <h6 className="text-danger">Unknown</h6> : colleague.user_name} {colleague.colleague_email != null && <small>({colleague.user_email})</small>}
                          </Col>
                        </>
                      }
                    </Row>
                    <Row className="w-100">
                      <Col lg={2} as="h6">
                        User:
                      </Col>
                      <Col lg={10} as="h6" className="text-align-start">
                        {colleague.user_name == null ? "Name not initialized" : colleague.user_name} {colleague.user_name != null && <small>({colleague.user_email})</small>}
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={2} className="pl-0 w-100">
                    <Button
                      variant="danger"
                      onClick={() => removeColleague({ index })}>
                      Delete
                  </Button>
                  </Col>
                </Row>
                <hr />
              </>
            )
            )}
        </Container >
      )
    }
  }
  // End of Components segment

  /*
    AsyncTypeAhead x Algolia Function
    Call Algolia function to retrieve objectId, name and email
    Param query refers to what the user enters into the AsyncTypeAhead Textbox
    Assign hits obtained from Algolia to managerOptions and assigned it to state userOptions
  */
  const handleManagerSearch = async (query) => {
    // Clear error/success messages upon function call
    setResponseType('');
    setResponseMessage('');

    // Set loading state to render loading circle
    setManagerSearchLoading(true);
    await index.search(query, {
      attributesToRetrieve: ['objectID', 'name', 'email'],
    }).then(({ hits }) => {
      const retrievedOptions = hits.map((user) => ({
        objectId: user.objectID,
        name: user.name,
        email: user.email,
      }));
      setManagerOptions(retrievedOptions);
    });
    // Clear loading circle after function is complete
    setManagerSearchLoading(false);
  };

  /*
    AsyncTypeAhead x Algolia Function
    Call Algolia function to retrieve objectId, name and email
    Param query refers to what the user enters into the AsyncTypeAhead Textbox
    Assign hits obtained from Algolia to userOptions and assigned it to state userOptions
  */
  const handleUserSearch = async (query) => {
    // Clear error/success messages upon function call
    setResponseType('');
    setResponseMessage('');
    // Set loading state to render loading circle
    setUserSearchLoading(true);
    await index2.search(query, {
      attributesToRetrieve: ['objectID', 'name', 'email'],
    }).then(({ hits }) => {
      const retrievedOptions = hits.map((user) => ({
        objectId: user.objectID,
        name: user.name,
        email: user.email,
      }));
      setUserOptions(retrievedOptions);
    });
    // Clear loading circle after function is complete
    setUserSearchLoading(false);
  };

  // This function gets called when the user has selected a manager.
  // The function will retrieve the user's 'my_directs' teamId and keep it in `teamID`.
  const getManagerTeam = async (selectedManager) => {

    // Set loading state to render loading circle
    setRetrievingTeam(true);

    // Reset all variables
    setTeamCreated(false);
    setTeamStatusNotification('');
    setNewColleagues([]);
    setExistingColleagues([]);
    setTeamColleagues([]);
    setTeamID(null);

    // Clear error/success messages upon function call
    setResponseType('');
    setResponseMessage('');

    if (selectedManager == null) {
      setSelectedManager(null)
    } else {
      setSelectedManager(selectedManager);

      const getManagerDirectsTeamRef = functions.httpsCallable("callGetUserDirectsTeamId");
      await getManagerDirectsTeamRef({ userId: selectedManager.objectId }).then(function (res) {
        const result = JSON.parse(JSON.stringify(res.data));
        if (result.statusCode === 200 && result.directsTeamId != '') {
          setTeamStatusNotification(selectedManager.name + "'s directs team has been created!");
          setTeamCreated(true);
          setTeamID(result.directsTeamId);
        } else {
          setTeamStatusNotification(selectedManager.name + "'s directs team has not been created!");
        }
      });
    }
    // Clear loading circle after function is complete
    setRetrievingTeam(false);
  }

  // Function call to 'createDefaultTeamsForUserAdmin' to create directs team for the given manager.
  // objectId is from user object retrieved from Algolia user index
  const createDirectsTeam = async () => {

    // Set loading state to render loading circle
    setCreatingTeam(true);

    // Clear error/success messages upon function call
    setResponseType('');
    setResponseMessage('');
    const createDefaultTeams = functions.httpsCallable("createDefaultTeamsForUserAdmin");
    let manager = { "currentUser": selectedManager.objectId };
    await createDefaultTeams(manager).then(async function (res) {
      const result = JSON.parse(JSON.stringify(res.data));
      // Function returns team id. Check if id is empty or null
      if (result != null && result != '') {
        setTeamID(result);
        setTeamStatusNotification(selectedManager.name + "'s directs team has been created!");
        setTeamCreated(true);
      } else {
        setResponseType("danger");
        setResponseMessage("An error has occurred. Please try again!");
      }
    });
    // Clear loading circle after function is complete
    setCreatingTeam(false);
  }

  // Function call to 'callGetUserExistingColleagues' to retrieve the manager's colleagues
  const getUserColleagues = async () => {
    const getColleagues = functions.httpsCallable("callGetUserExistingColleagues");
    await getColleagues(selectedManager).then(function (res) {
      const result = JSON.parse(JSON.stringify(res.data));
      if (result.statusCode === 200) {
        setExistingColleagues(result.colleagueArray);
      }
    });
  }

  // Function call to 'callGetTeamMembers' to retrieve the manager's directs team members
  const getTeamMembers = async () => {

    // Clear error/success messages upon function call
    setResponseType('');
    setResponseMessage('');
    // Get team's members
    const retrieveTeamMembers = functions.httpsCallable("callGetTeamMembers");
    await retrieveTeamMembers({ "teamId": teamID }).then(function (res) {
      const result = JSON.parse(JSON.stringify(res.data));
      if (result.statusCode === 200 && result.directsTeamRef != '') {
        setTeamColleagues(result.members);
      }
    });
  }

  // Function to update colleagueFirestore state with colleague object.
  // If user selects 'Add New Colleague', the value will be -2 and a colleague object will null fields will be created
  const assignColleague = (event) => {

    // Clear error/success messages upon function call
    setResponseType('');
    setResponseMessage('');
    if (event.target.value != null && event.target.value != -1 && event.target.value != -2) {
      setFirestoreColleagueIndex(event.target.value);
      setColleagueFirestore(existingColleagues[event.target.value]);
    } else if (event.target.value != null && event.target.value == -2) {
      setFirestoreColleagueIndex(event.target.value);
      let colleague = {
        'name': null,
        'email': null,
        'colleague_id': null,
      }
      setColleagueFirestore(colleague);
    } else if (event.target.value != null && event.target.value == -1) {
      setFirestoreColleagueIndex(event.target.value);
      setColleagueFirestore(null);
    }
  }

  /*
    Function for the 'Add Colleague' button to add a new object which consist of
    colleague and top level user's name and email and id into the existing newColleagues state array
    Reset state after it has been successfully added into the array
  */
  const addColleagueToArray = () => {
    // Clear error/success messages upon function call
    setResponseType('');
    setResponseMessage('');

    if (colleagueAlgolia != null && colleagueFirestore != null && colleagueAlgolia != '') {
      let newColleague = {
        "user_name": colleagueAlgolia?.name,
        "user_email": colleagueAlgolia?.email,
        "user_id": colleagueAlgolia?.objectId,
        "colleague_name": colleagueFirestore?.name,
        "colleague_email": colleagueFirestore?.email,
        "colleague_id": colleagueFirestore?.colleague_id
      }

      console.log(newColleague);

      let array = [...newColleagues, newColleague];
      setNewColleagues(array);
      setFirestoreColleagueIndex(-1);
      setColleagueFirestore(null);
      setColleagueAlgolia('');
    } else {
      setResponseType("danger");
      setResponseMessage("Please ensure both colleague and user fields are filled!");
    }

  }

  /*
    onClick function for the 'Delete' button in the 'NewColleagues' component table
    Remove selected colleague by index from the newColleagues array
    index refers to the key of the 'Delete' button upon initialising the table
  */
  const removeColleague = (index) => {
    let colleagues = newColleagues;
    colleagues.splice(index.index, 1);
    setNewColleagues([...colleagues]);
  }

  /*
    onClick function for the 'Submit' button. To validate if manager has been initialised and there are new colleagues to add (based on 'newColleagues' state)
    If there are no error, make call to 'sendNewColleagues' to make a Firebase Cloud Function call to 'addColleagueToUserDirectTeamAdmin' to do a batch adding of these new users as colleagues of the currentUser
  */
  const validateSubmission = async () => {
    // Clear error/success messages upon function call
    setResponseType('');
    setResponseMessage('');
    if (selectedManager != null && selectedManager != '' && newColleagues.length != 0) {
      // Set loading state to render loading circle
      setAddColleaguesPending(true);
      setLoadingTeamMembers(true);

      await sendNewColleagues();

      // Clear loading circle after function is complete
      setLoadingTeamMembers(false);
      setAddColleaguesPending(false);
    } else if (selectedManager == null || selectedManager == '') {
      setResponseType("danger");
      setResponseMessage("Please search for a manager!");
    } else if (newColleagues.length == 0) {
      setResponseType("danger");
      setResponseMessage("Please add a colleague!");
    }
  }

  /*
    Function triggered by 'validateSubmission' when there are no errors.
    Loops through newColleagues to make individual function calls. 1 call for 1 user.
    Package new team member's user_id (top level), colleague_id and teamID as "userUid", "colleagueUid" and "teamUid" respectively as "data"
    After which make the Cloud Function call to 'addColleagueToUserDirectTeamAdmin' and pass 'data' as a function parameter.

    statusCode:
    200 - success
  */
  const sendNewColleagues = async () => {
    for (const colleague of newColleagues) {
      const addColleagueToUserDirectTeamAdmin = functions.httpsCallable("addColleagueToUserDirectTeamAdmin");
      const data = { "userUid": colleague.user_id, "teamUid": teamID, "colleagueUid": colleague.colleague_id };
      await addColleagueToUserDirectTeamAdmin(data).then(async function (res) {
        const result = JSON.parse(JSON.stringify(res.data));
        if (result.statusCode === 200) {
          setResponseType("success");
          setResponseMessage("Successfully added users!");
          setNewColleagues([]);
          await getTeamMembers();
        } else {
          setResponseType("danger");
          setResponseMessage("An error has occurred. Please try again!");
        }
      });
    }
  };

  return (
    <div className="page-container">
      <Container className="d-flex justify-content-center my-4">
        <h2>Create MP Teams</h2>
      </Container>
      <Container>
        <ol className="notification-hint-padding">
          <li>Search for the manager you wish to add colleagues into his/her directs team</li>
          <li>Create directs team if directs team has not been created</li>
          <li>Search for the user to add within the manager's colleagues. (If user is not a colleague, select 'Add New Colleague' for user to be added into the team and as a colleague of the manager)</li>
          <li>Search for the new team member within the user's repository</li>
          <li>Click 'Add Colleague' to add the user to the list of new team members</li>
          <li>Verify the list of all the colleagues and their details before clicking submit as no changes can happen after</li>
        </ol>
        {/* Manager Search */}
        <Form>
          <Form.Group>
            <Form.Label><b>Search for the Thoughtful Manager</b></Form.Label>
            <UserSearch
              id={'search-for-manager'}
              isLoading={managerSearchLoading}
              onSearch={handleManagerSearch}
              minLength={3}
              options={managerOptions}
              onChange={getManagerTeam} />
          </Form.Group>
        </Form>
      </Container>

      {/* Directs Team found? message segment */}
      <Container className="justify-content-center text-center w-100">
        {selectedManager != null && !retrievingTeam &&
          <>
            <h5>{teamStatusNotification}</h5>
            {!teamCreated && teamStatusNotification != '' && !creatingTeam &&
              <Button variant="primary" onClick={createDirectsTeam}>
                create team!
            </Button>}
            <div className="d-flex justify-content-center">
              {creatingTeam &&
                < LoadingCircle />
              }
            </div>
          </>
        }
        {
          selectedManager != null && retrievingTeam &&
          <div className="d-flex justify-content-center">
            <LoadingCircle />
          </div>
        }
      </Container>
      <hr />
      {/* Existing colleagues and add new colleagues segment */}
      <Container className="justify-content-center text-center w-100">
        <Row className="mt-0">
          <Col>
            <Form.Label className="text-center w-100">
              <b>Existing Team Members</b>
              <hr className="mb-0" />
            </Form.Label>
          </Col>
          <Col>
            <Form.Label className="text-center w-100">
              <b>Add New Team Members</b>
              <hr className="mb-0" />
            </Form.Label>
          </Col>
        </Row>
        {teamCreated &&
          <>
            <Row className="w-100">
              <Col>
                {
                  !loadingTeamMembers &&
                  <Row className="justify-content-center">
                    <TeamMembers />
                  </Row>
                }
                {loadingTeamMembers &&
                  <Row className="justify-content-center p-2">
                    <LoadingCircle />
                  </Row>
                }
              </Col>
              <Col>
                {/* <AddColleagues /> */}
                {
                  !loadingUserColleagues &&
                  <Row className="pl-2">
                    <Col lg={11} className="p-0">
                      <Row className="justify-content-center">
                        <ExistingColleagues />
                      </Row>
                      <Row>
                        <UserSearch
                          id={'search-for-colleagues'}
                          className={"w-100 mb-2 mx-4"}
                          isLoading={userSearchLoading}
                          onSearch={handleUserSearch}
                          minLength={3}
                          options={userOptions}
                          onChange={setColleagueAlgolia} />
                      </Row>
                    </Col>
                    <Col lg={1} className="px-1">
                      <Button variant="primary label-alignment" onClick={addColleagueToArray}>
                        Add Colleague
                      </Button>
                    </Col>
                  </Row>
                }
                {
                  loadingUserColleagues &&
                  <Row className="justify-content-center p-2">
                    <LoadingCircle />
                  </Row>
                }
                {/* Display new team members section */}
                <Container className="p-2 w-100">
                  <Form.Label className="text-center w-100">
                    <b>New Team Members</b>
                  </Form.Label>
                  <NewColleagues />
                </Container>
              </Col>
            </Row>

          </>
        }
      </Container>

      <div className="pt-5">
        <Row className="justify-content-center">
          <Form.Label className="warning-tip">Please ensure all details are correct - There is no going back!</Form.Label>
        </Row>
        <Row className="justify-content-center">
          {
            !addColleaguesPending &&
            <Button variant="primary"
              onClick={validateSubmission}>
              Submit!
          </Button>
          }
          {
            addColleaguesPending &&
            <LoadingCircle />
          }
        </Row>
        <Container>
          {responseType && (
            <Row className="justify-content-center row-margin">
              <Alert variant={responseType}>{responseMessage}</Alert>
            </Row>
          )}
        </Container>
      </div>
    </div >
  )
}

export default CreateMPTeams;