import firebase from "firebase";
import React, { useEffect, useState } from "react";
import app from "../services/firebase.js";
import axios from "axios";

var firebaseApp = firebase.app();
var functions = firebaseApp.functions("asia-northeast1");

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);
  let [validUser, setValidUser] = useState(null);
  let [setErrorCode] = useState(null);
  let [errorMessage, setErrorMessage] = useState(null);
  const [gpt3Admin, setGPT3AdminAccess] = useState(false);
  const [gpt3Moderation, setGPT3ModerationAccess] = useState(false);
  const [manageOrg, setManageOrg] = useState(false);
  const [syncColleagues, setSyncColleagues] = useState(false);
  let [bearerToken, setBearerToken] = useState(null);
  const baseURL = `${process.env.REACT_APP_API_V2_URL}/api/graphql`;
  console.log(process.env)
  useEffect(() => {
    app.auth().onAuthStateChanged((user) => {
      setCurrentUser(user);
      if (user != null) {
        user.getIdToken().then(function (idToken) {
          setBearerToken(idToken);
        });
      }
      setValidUser(false)
      setPending(false);
    });
  }, []);

  if (currentUser) {
    const checkAdmin = functions.httpsCallable("checkCurrentUserIsAdmin");
    checkAdmin().then(function (res) {
      const result = JSON.parse(JSON.stringify(res.data))
      if (result.statusCode === 200) {
        setValidUser(true);
      } else if (result.statusCode === 401) {
        setValidUser(false);
        setErrorMessage(result.errorMsg)
      } else {
        setValidUser(false);
        setErrorCode(result.errorCode);
        setErrorMessage(result.errorMsg)
      }
    }).catch(function (error) {

    })

    const checkGPT3Access = functions.httpsCallable("checkCurrentUserIsGPT3Admin");
    checkGPT3Access().then(function (res) {
      const result = JSON.parse(JSON.stringify(res.data));
      if (result.statusCode === 200) {
        setGPT3AdminAccess(true);
      } else if (result.statusCode === 401) {
        setGPT3AdminAccess(false);
      } else {
        setGPT3AdminAccess(false);
        setErrorCode(result.errorCode);
      }
    });

    if(bearerToken) {
      const graphqlQuery = {
        "query": `query BIO_QUERY($type: String!) {
          hasAccess(type: $type) 
        }
        `,
        "variables": {type: "gpt3_moderation"}
    };
    axios.defaults.headers.post['Authorization'] = `Bearer ${bearerToken}`;
    axios.defaults.headers.post['content-type'] = `application/json`;
    // Even if endpoint is not expecting data, pass null as the data.
    axios.post(baseURL, graphqlQuery)
      .then(function (response) {
        const result = response?.data;
        if (response?.status === 200) {
          setGPT3ModerationAccess(result?.data?.hasAccess)
        } 
      })
      .catch(function (error) {
        setErrorMessage(error?.response?.data?.message)
        console.error(error?.response?.data?.message);
        setGPT3ModerationAccess(false)
      });

      const graphqlQuerySyncColleague = {
        "query": `query BIO_QUERY($type: String!) {
          hasAccess(type: $type) 
        }
        `,
        "variables": {type: "sync_colleague"}
    };
    axios.defaults.headers.post['Authorization'] = `Bearer ${bearerToken}`;
    axios.defaults.headers.post['content-type'] = `application/json`;
    // Even if endpoint is not expecting data, pass null as the data.
    axios.post(baseURL, graphqlQuerySyncColleague)
      .then(function (response) {
        const result = response?.data;
        if (response?.status === 200) {
          setSyncColleagues(result?.data?.hasAccess)
        } 
      })
      .catch(function (error) {
        setErrorMessage(error?.response?.data?.message)
        console.error(error?.response?.data?.message);
        setSyncColleagues(false)
      });

      const graphqlManageOrgColleague = {
        "query": `query BIO_QUERY($type: String!) {
          hasAccess(type: $type) 
        }
        `,
        "variables": {type: "manage_org"}
    };
    axios.defaults.headers.post['Authorization'] = `Bearer ${bearerToken}`;
    axios.defaults.headers.post['content-type'] = `application/json`;
    // Even if endpoint is not expecting data, pass null as the data.
    axios.post(baseURL, graphqlManageOrgColleague)
      .then(function (response) {
        const result = response?.data;
        if (response?.status === 200) {
          setManageOrg(result?.data?.hasAccess)
        } 
      })
      .catch(function (error) {
        setErrorMessage(error?.response?.data?.message)
        console.error(error?.response?.data?.message);
        setManageOrg(false)
      });
    }
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        validUser,
        pending,
        errorMessage,
        gpt3Admin,
        gpt3Moderation,
        bearerToken,
        syncColleagues,
        manageOrg
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
