import React, { Fragment } from "react";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

const OrganizationSearch = (props) => {

  /*
  AsyncTypeAhead Function
  The filterBy prop can be used in one of two ways: to specify option properties that should be searched or to pass a custom callback.
  Set filterBy to true by default
*/
  const filterBy = () => true;
  const options = props.options;

  return (
    <AsyncTypeahead
      id={props.id}
      className={props.className}
      filterBy={filterBy}
      isLoading={props.isLoading}
      labelKey="name"
      minLength={props.minLength}
      onSearch={props.onSearch}
      options={options}
      placeholder="Enter name of organization (e.g. OnLoop)"
      onChange={(selected) => {
        // Get Organization Name - Check if there is an organization.
        props.onChange(selected[0]);
      }}
      renderMenuItemChildren={(options, props) => (
        <Fragment>
          <div>
            {options.name || "organization name not initialized"}
          </div>
        </Fragment>
      )} />
  )
}

export default OrganizationSearch;