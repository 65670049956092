import React, { Fragment } from "react";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

const UserSearch = (props) => {

  /*
  AsyncTypeAhead Function
  The filterBy prop can be used in one of two ways: to specify option properties that should be searched or to pass a custom callback.
  Set filterBy to true by default
*/
  const filterBy = () => true;
  const options = props.options;

  return (
    <AsyncTypeahead
      id={props.id}
      className={props.className}
      filterBy={filterBy}
      isLoading={props.isLoading}
      labelKey="email"
      minLength={props.minLength}
      onSearch={props.onSearch}
      options={options}
      placeholder="name@example.com"
      onChange={(selected) => {
        // Get Manager Team - Check if there is a team, if not show button to create a team
        // getManagerTeam(selected[0]);
        props.onChange(selected[0]);
      }}
      renderMenuItemChildren={(options, props) => (
        <Fragment>
          <div>
            {options.name || "name not initialized"}
            <div>
              <small>{options.email}</small>
            </div>
          </div>
        </Fragment>
      )} />
  )
}

export default UserSearch;