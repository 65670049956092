import React, { useState } from 'react';
import { Alert, Row } from "react-bootstrap";
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './component-styles.css';

const FullCalendarDatePicker = (props) => {
    const [focusedInput, setFocusedInput] = useState(null);

    return (
        <div className='date-picker row-margin'>
            <DateRangePicker
                startDateId="startDate"
                endDateId="endDate"
                startDate={props.startDate}
                endDate={props.endDate}
                block={true}
                onDatesChange={({ startDate, endDate }) => {
                    props.setResponseType(null);
                    props.setResponseMessage(null);
                    props.setStartDate(startDate);
                    props.setEndDate(endDate);
                }}
                focusedInput={focusedInput}
                onFocusChange={setFocusedInput}
                hideKeyboardShortcutsPanel={true}
                isOutsideRange={() => false}
                startDatePlaceholderText="Start Date"
                endDatePlaceholderText="End Date"
                orientation={props.vertical}


            />

            {
                props.startDate != null && props.endDate != null &&
                <Row className="justify-content-center row-margin">
                    <Alert className="datepicker-alert" variant={'primary'}>All captures between <b>{new Date(props.startDate).toDateString()}</b> and <b>{new Date(props.endDate).toDateString()}</b> will be included for the review.</Alert>
                </Row>
            }
        </div>
    );

}
export default FullCalendarDatePicker;