import algoliasearch from "algoliasearch/lite";
import firebase from "firebase";
import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Container, Form, Row, ToggleButton, ButtonGroup } from "react-bootstrap";
import LoadingCircle from "../components/loading";
import UserSearch from "../components/user-search";

const searchClient = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_API_KEY);
const ownerIndex = searchClient.initIndex('users');
const userIndex = searchClient.initIndex('users');
var firebaseApp = firebase.app();
var functions = firebaseApp.functions("asia-northeast1")

const CreateCuntomTeams = () => {
  // Owner information
  const [ownerSearchLoading, setOwnerSearchLoading] = useState(false);
  const [ownerOptions, setOwnerOptions] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState(null);
  const [loadingTeamOfOwner, setLoadingTeamOfOwner] = useState(false);

  // User informaion
  const [userSearchLoading, setUserSearchLoading] = useState(false);
  const [userOptions, setUserOptions] = useState([]);

  // Team information
  // Used to indicate whether the system is in the process of creating custom team.
  const [creatingTeam, setCreatingTeam] = useState(false);
  // Used to create a list of teams belong to owner.
  const [radioValue, setRadioValue] = useState('');
  const [teamOptions, setteamOptions] = useState([]);
  const [loadingCustomTeam, setLoadingCustomTeam] = useState(false);
  const [loadingTeamMembers, setLoadingTeamMembers] = useState(false);
  const [teamID, setTeamID] = useState(null);
  const [teamNameValue, setTeamNameValue] = useState('');
  const [teamMembers, setTeamColleagues] = useState([]);
  const [existingColleagues, setExistingColleagues] = useState([]);
  const [teamStatus, setTeamStatus] = useState('');

  // Used to retrieve index of colleague from existingColleagues array to 
  // populate colleagueFirestore with existingColleague object
  const [firestoreColleagueIndex, setFirestoreColleagueIndex] = useState(-1);
  const [colleagueFirestore, setColleagueFirestore] = useState(null);
  const [colleagueAlgolia, setColleagueAlgolia] = useState('');
  const [newColleagues, setNewColleagues] = useState([]);
  const [loadingUserColleagues, setLoadingUserColleagues] = useState(false);
  const [addColleaguesPending, setAddColleaguesPending] = useState(false);

  // Response messages
  const [responseMessage, setResponseMessage] = useState('');
  const [responseType, setResponseType] = useState('');
  

  // Retrieve team members and colleagues after teamID has been initialised
  useEffect(async () => {
    if (teamID != null) {
      setLoadingTeamMembers(true);
      setLoadingUserColleagues(true);
      await getTeamMembers();
      setLoadingTeamMembers(false);
      await getUserColleagues();
      setLoadingUserColleagues(false);
    }
  }, [teamID]);

  /*
    Component to select the ownerId to add colleague in.
  */
  const handleOwnerSearch = async (query) => {
    // Clear error/success messages upon function call.
    setResponseType('');
    setResponseMessage('');
    // Set loading state to render loading circle.
    setOwnerSearchLoading(true);

    await ownerIndex.search(query, {
      attributesToRetrieve: ['objectID', 'name', 'email'],
    }).then(({hits}) => {
      const retrievedOptions = hits.map((user) => ({
        objectId: user.objectID,
        name: user.name,
        email: user.email,
      }));
      setOwnerOptions(retrievedOptions);
    });

    // Clear loading circle after function is complete.
    setOwnerSearchLoading(false);
  };

  /*
    Component to select the colleague to add to the team of the owner.
  */
  const handleUserSearch = async (query) => {
    setResponseType('');
    setResponseMessage('');
    setUserSearchLoading(true);

    await userIndex.search(query, {
      attributesToRetrieve: ['objectID', 'name', 'email'],
    }).then(({ hits }) => {
      const retrievedOptions = hits.map((user) => ({
        objectId: user.objectID,
        name: user.name,
        email: user.email,
      }));
      setUserOptions(retrievedOptions);
    });

    setUserSearchLoading(false);
  };

  /*
    Component to get a list of teams of the selected owner.
  */
  const getOwnerTeam = async (ownerId) => {
    setResponseType('');
    setResponseMessage('');
    setLoadingTeamOfOwner(true);
    setTeamStatus('');
    // Reset states for owner and team.
    setTeamID(null);
    setCreatingTeam(false);
    setSelectedOwner(null);
    var temporaryTeamList = [];

    if (ownerId != null) {
      setSelectedOwner(ownerId);

      const getOwnerTeamsRef = functions.httpsCallable("getOwnerTeams");
      await getOwnerTeamsRef({ ownerId: ownerId.objectId }).then(function (res) {
        const response = JSON.parse(JSON.stringify(res.data));
        const result = response?.userTeams;
        if (response?.statusCode === 200 && result != null && result != '') {
          setTeamStatus(ownerId.name + "'s teams have been found!");
          var i; var arrayOfTeamPath;

          for (i = 0; i < result?.length; i++) {
            if (result[i].team_type == 'custom') {
              arrayOfTeamPath = result[i].team_ref_path.split('/');
              // read the team id from arrayOfTeamPath and push it with corresponding team name to the team list
              temporaryTeamList.push({ name: result[i].team_name, value: arrayOfTeamPath[arrayOfTeamPath.length - 1] });
            }
          }
        } else {
          setTeamStatus(ownerId.name + " has not owned any teams");
        }
        setteamOptions(temporaryTeamList);
      });
    }
    setLoadingTeamOfOwner(false);
  }

  /*
    Component to select team to add colleagus.
  */
  const selectTeamToAddColleague = async () => {
    setResponseType('');
    setResponseMessage('');
    setTeamID(null);
    setCreatingTeam(false);

    if (radioValue != null && radioValue != 'other') {
      setTeamID(Object(radioValue));

    } else {
      setResponseType("danger");
      setResponseMessage("An error has occurred. Please try again!");
    }
  }

  const createCustomTeamClick = async () => {
    setResponseType('');
    setResponseMessage('');
    setTeamID(null);
    setCreatingTeam(true);
  }

  const TeamMembers = () => {
    if (teamID !== null && teamMembers.length != 0) {
      let teamColleaguesArray = teamMembers;
      return (
        <Container>{
          teamColleaguesArray.map((colleague) => (
            <>
              <Row as="h6" className="align-middle text-center">
                <Col>{colleague.name || "Name not provided"} <small>({colleague.email})</small></Col>
              </Row>
              <hr />
            </>
          ))
        }
        </Container>
      )
    } else if (teamID != null) {
      return (
        <Container>
          <Row as="h6" className="align-middle text-center m-2">
            <Col>There are no colleagues in the team now!</Col>
          </Row>
        </Container>
      )
    } else {
      return (
        <Container>
          <Row>
          </Row>
        </Container>
      )
    }
  }

  /*
    Component to populate dropdown list which shows the manager's existing colleagues.
    onChange calls the function assignColleague to populate firestoreColleague with the colleague object
    when value is -2, it indicates a new colleague will be added to the manager
    when value is -1, it indicates no colleague has been selected and for the function to clear firestoreColleague state and 
    to assign option 1 of dropdownlist as the selected variable
  */
  const ExistingColleagues = () => {
    if (existingColleagues.length != 0 && teamID != null) {
      let existingColleaguesArray = existingColleagues;
      return (
        <Form.Control as="select" id="dropdown-basic-button" className="mx-4 mb-2"
          onChange={assignColleague}
          value={firestoreColleagueIndex}>
          <option value={-1}>Select an existing colleague</option>
          {
            existingColleaguesArray.map((colleague, index) => (
              <option
                key={index}
                value={index}
              >
                {colleague.name || "name not initialized"} ({ colleague.email || "email not initialized"})
              </option>
            ))
          }
          {/* Return assignColleague function will pick up the value of -2 and create a new colleague */}
          <option value={-2} selected={firestoreColleagueIndex == -2 ? true : false}>Add New Colleague</option>
        </Form.Control >
      )

    } else if (existingColleagues.length == 0 && teamID != null) {
      return (
        <Form.Control as="select" className="mx-4 mb-2" disabled={true} onChange={assignColleague} value={firestoreColleagueIndex}>
          {/* Return assignColleague function will pick up the value of -1 and reset the firestoreColleague state */}
          <option value={-1}>Owner has no colleagues</option>
          {/* Return assignColleague function will pick up the value of -2 and create a new colleague */}
          <option value={-2} selected={firestoreColleagueIndex == -2 ? true : false}> Add New Colleague </option>
        </Form.Control>
      )
    } else if (teamStatus.length == 0) {
      return (
        <Form.Control as="select" className="mx-4 mb-2" disabled='true'>
          <option> -- Please Search for a Owner! -- </option>
        </Form.Control>
      )
    }
  }

  /*
    Component to populate Colleagues to Add table when 'newColleagues' state is populated
  */
  const NewColleagues = () => {
    if (newColleagues) {
      let colleagueArray = newColleagues;
      return (
        <Container>
          <hr />
          {
            colleagueArray.map((colleague, index) => (
              <>
                <Row className="pt-2 pb-0 w-100">
                  <Col lg={10} className="pr-0 w-100">
                    <Row className="w-100">
                      {(colleague.colleague_name == null && colleague.colleague_email == null) &&
                        <Col>
                          <h6 className="text-danger">User will be added as a colleague and team member</h6>
                        </Col>
                      }
                      {(colleague.colleague_name != null || colleague.colleague_email != null) &&
                        <>
                          <Col lg={2} as="h6">
                            Colleague:
                          </Col>
                          <Col lg={10} as="h6" className="text-align-start">
                            {colleague.colleague_name == null ? <h6 className="text-danger">Unknown</h6> : colleague.user_name} {colleague.colleague_email != null && <small>({colleague.user_email})</small>}
                          </Col>
                        </>
                      }
                    </Row>
                    <Row className="w-100">
                      <Col lg={2} as="h6">
                        User:
                      </Col>
                      <Col lg={10} as="h6" className="text-align-start">
                        {colleague.user_name == null ? "Name not initialized" : colleague.user_name} {colleague.user_name != null && <small>({colleague.user_email})</small>}
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={2} className="pl-0 w-100">
                    <Button
                      variant="danger"
                      onClick={() => removeColleague({ index })}>
                      Delete
                  </Button>
                  </Col>
                </Row>
                <hr />
              </>
            )
            )}
        </Container >
      )
    }
  }

  /*
    Component to create custom team with specific owner id  unique team name.
  */
  const createCustomTeam = async (data) => {
    setLoadingCustomTeam(true);
    setResponseType('');
    setResponseMessage('');

    const createCustomTeamRef = functions.httpsCallable("callCreateCustomTeam");
    if (data != null) {
      await createCustomTeamRef(data).then(async function (res) {
        const result = JSON.parse(JSON.stringify(res.data));
        if (result != null && result?.statusCode == 200) {
          setTeamID(result?.teamId);
          setTeamStatus(selectedOwner.name + " has a new team created " + teamNameValue);
        } else {
          setTeamID(null);
          setResponseType("danger");
          setResponseMessage("An error has occurred. Please try again!");
        }
      });
    } else {
      setResponseType("danger");
      setResponseMessage("An error has occurred. Please try again!");
    }

    setCreatingTeam(false);
    setLoadingCustomTeam(false);
  }

  const verifyTeamDetails = async () => {
    if (selectedOwner.objectId != null && teamNameValue != '') {
      const data = {
        "ownerId": selectedOwner.objectId,
        "teamName": teamNameValue
      };
      createCustomTeam(data);
    } else {
      setResponseType('danger');
      setResponseMessage('the team name cannot be null');
    }
  }

  // Function call to 'callGetUserExistingColleagues' to retrieve the manager's colleagues
  const getUserColleagues = async () => {
    const getColleagues = functions.httpsCallable("callGetUserExistingColleagues");
    await getColleagues(selectedOwner).then(function (res) {
      const result = JSON.parse(JSON.stringify(res.data));
      if (result?.statusCode === 200) {
        setExistingColleagues(result?.colleagueArray);
      }
    });
  }

  const getTeamMembers = async () => {
    setResponseType('');
    setResponseMessage('');

    // Get team's members
    const retrieveTeamMembers = functions.httpsCallable("callGetTeamMembers");
    await retrieveTeamMembers({ "teamId": teamID }).then(function (res) {
      const result = JSON.parse(JSON.stringify(res.data));
      if (result?.statusCode === 200 && result?.directsTeamRef != '') {
        setTeamColleagues(result?.members);
      }
    });
  }
  
  // Function to update colleagueFirestore state with colleague object.
  // If user selects 'Add New Colleague', the value will be -2 and a colleague object will null fields will be created
  const assignColleague = (event) => {

    // Clear error/success messages upon function call
    setResponseType('');
    setResponseMessage('');
    if (event.target.value != null && event.target.value != -1 && event.target.value != -2) {
      setFirestoreColleagueIndex(event.target.value);
      setColleagueFirestore(existingColleagues[event.target.value]);
    } else if (event.target.value != null && event.target.value == -2) {
      setFirestoreColleagueIndex(event.target.value);
      let colleague = {
        'name': null,
        'email': null,
        'colleague_id': null,
      }
      setColleagueFirestore(colleague);
    } else if (event.target.value != null && event.target.value == -1) {
      setFirestoreColleagueIndex(event.target.value);
      setColleagueFirestore(null);
    }
  }

  const addColleagueToArray = () => {
    // Clear error/success messages upon function call
    setResponseType('');
    setResponseMessage('');

    if (colleagueAlgolia != null && colleagueFirestore != null && colleagueAlgolia != '') {
      let newColleague = {
        "user_name": colleagueAlgolia?.name,
        "user_email": colleagueAlgolia?.email,
        "user_id": colleagueAlgolia?.objectId,
        "colleague_name": colleagueFirestore?.name,
        "colleague_email": colleagueFirestore?.email,
        "colleague_id": colleagueFirestore?.colleague_id
      }

      let array = [...newColleagues, newColleague];
      setNewColleagues(array);
      setFirestoreColleagueIndex(-1);
      setColleagueFirestore(null);
      setColleagueAlgolia('');
    } else {
      setResponseType("danger");
      setResponseMessage("Please ensure both colleague and user fields are filled!");
    }
  }

  const removeColleague = (index) => {
    let colleagues = newColleagues;
    colleagues.splice(index.index, 1);
    setNewColleagues([...colleagues]);
  }

  const validateSubmission = async () => {
    // Clear error/success messages upon function call
    setResponseType('');
    setResponseMessage('');

    if (selectedOwner != null && selectedOwner != '' && newColleagues.length != 0) {
      // Set loading state to render loading circle
      setAddColleaguesPending(true);
      setLoadingTeamMembers(true);
      await sendNewColleagues();
      // Clear loading circle after function is complete
      setLoadingTeamMembers(false);
      setAddColleaguesPending(false);
    } else if (selectedOwner == null || selectedOwner == '') {
      setResponseType("danger");
      setResponseMessage("Please search for a owner!");
    } else if (newColleagues.length == 0) {
      setResponseType("danger");
      setResponseMessage("Please add a colleague!");
    }

    setAddColleaguesPending(false);
  }

  const sendNewColleagues = async () => {
    for (const colleague of newColleagues) {
      const addColleagueToUserCustomTeamAdmin = functions.httpsCallable("addColleagueToUserCustomTeamAdmin");
      
      if (colleague.user_id != null && teamID != null ){
        const data = { "userUid": colleague.user_id, "teamUid": teamID, "colleagueUid": colleague.colleague_id };
        await addColleagueToUserCustomTeamAdmin(data).then(async function (res) {
          const result = JSON.parse(JSON.stringify(res.data));
          if (result?.statusCode === 200) {
            setResponseType("success");
            setResponseMessage("Successfully added users!");
            setNewColleagues([]);
            await getTeamMembers();
          } else {
            setResponseType("danger");
            setResponseMessage("An error has occurred. Please try again!");
          }
        });
      } else {
        setResponseType("danger");
        setResponseMessage("Pleace make sure all the details are correct!");
      }
     
    }
  };

  return (
    <div className="page-container">
      <Container className="d-flex justify-content-center my-4">
        <h2>Create Custom Teams</h2>
      </Container>
      <Container>
        <ol className="notification-hint-padding">
          <li>Search the owner of the team that you wish to add colleagues into his/her cunstom team. </li>
          <li>Choose the custom team by team name that you want to add colleagues in.</li>
          <li>Create custom team if custom team has not been created.</li>
          <li>Search for the user to add within the owner's colleagues. (If user is not a colleague, select 'Add New Colleague' for user to be added into the team and as a colleague of the owner)</li>
          <li>Search for the new team member within the user's repository</li>
          <li>Click 'Add Colleague' to add the user to the list of new team members</li>
          <li>Verify the list of all the colleagues and their details before clicking submit as no changes can happen after</li>
        </ol>
        {/* Team owner Search */}
        <Form>
          <Form.Group>
            <Form.Label><b>Search for the Team Owner</b></Form.Label>
            <UserSearch
              id={'search-for-team-owner'}
              isLoading={ownerSearchLoading}
              onSearch={handleOwnerSearch}
              minLength={3}
              options={ownerOptions}
              onChange={getOwnerTeam} />
          </Form.Group>
        </Form>
      </Container>
    
      {/* Team Owner Found. Display team he/she owns. */}
      <Container className="justify-content-center p-2">
        { selectedOwner != null && !loadingTeamOfOwner &&
            <>
            { teamOptions != 0 &&
              <div class="text-center">
              <ButtonGroup toggle vertical >
                {teamOptions.map((radio, idx) => (
                    <ToggleButton
                      size="lg"
                      key={idx}
                      type="radio"
                      variant="outline-info"
                      name="radio"
                      value={radio.value}
                      checked={radioValue === radio.value}
                      onChange={(e) => setRadioValue(e.currentTarget.value)}>
                      {radio.name}
                    </ToggleButton>
                ))}
              </ButtonGroup>
              </div>
            }
            { teamOptions.length != 0 &&
              <div class="text-center">
                <br />
                  <Button variant="primary" onClick={selectTeamToAddColleague} size="lg"  block>
                    Select team to add Colleague
                  </Button>
                <br /> 
              </div> 
            }
            {
              <div className="pl-2" class="text-center">
                <Button variant="secondary" onClick={createCustomTeamClick} size="lg" block>
                  <b>Create Custom Team</b>
                </Button>
              </div>

              }
            </>
        }

        { selectedOwner != null  && loadingTeamOfOwner &&
          <Row className="justify-content-center p-2">
            <LoadingCircle />
          </Row>
        }
      </Container>

      {/* Create new custom team */}
      <Container className="page-container">
        {creatingTeam == true && teamID == null && 
          <>
            {!loadingCustomTeam &&
              <div>
                <Row className="notification-hint-padding">
                  <Form.Label><b>Custom Team Name</b></Form.Label>
                  <Form.Control
                    onChange={(e) => setTeamNameValue(e.currentTarget.value)}
                  />
                </Row>
                <br/>
                <Row className="justify-content-center">
                    <Button variant="primary"
                      onClick={verifyTeamDetails}>
                      Create custom team!
                    </Button>
                </Row>
              </div>
            }
            {loadingCustomTeam &&
              <Row className="justify-content-center p-2">
                <LoadingCircle />
              </Row>
            }
          </>
        }
      </Container>

      <hr />

      {/* Existing colleagues and add new colleagues segment */}
      <Container className="justify-content-center text-center w-100">
        <Row className="mt-0">
          <Col>
            <Form.Label className="text-center w-100">
              <b>Existing Team Members</b>
              <hr className="mb-0" />
            </Form.Label>
          </Col>
          <Col>
            <Form.Label className="text-center w-100">
              <b>Add New Team Members</b>
              <hr className="mb-0" />
            </Form.Label>
          </Col>
        </Row>
        {teamID != null &&
          <>
            <Row className="w-100">
              <Col>
                {!loadingTeamMembers &&
                  <Row className="justify-content-center">
                    <TeamMembers />
                  </Row>
                }
                {loadingTeamMembers &&
                  <Row className="justify-content-center p-2">
                    <LoadingCircle />
                  </Row>
                }
              </Col>
              <Col>
                {/* <AddColleagues /> */}
                {
                  !loadingUserColleagues &&
                  <Row className="pl-2">
                    <Col lg={11} className="p-0">
                      <Row className="justify-content-center">
                        <ExistingColleagues />
                      </Row>
                      <Row>
                        <UserSearch
                          id={'search-for-colleagues'}
                          className={"w-100 mb-2 mx-4"}
                          isLoading={userSearchLoading}
                          onSearch={handleUserSearch}
                          minLength={3}
                          options={userOptions}
                          onChange={setColleagueAlgolia} />
                      </Row>
                    </Col>
                    <Col lg={1} className="px-1">
                      <Button variant="primary label-alignment" onClick={addColleagueToArray}>
                        Add Colleague
                      </Button>
                    </Col>
                  </Row>
                }
                {
                  loadingUserColleagues &&
                  <Row className="justify-content-center p-2">
                    <LoadingCircle />
                  </Row>
                }
                {/* Display new team members section */}
                <Container className="p-2 w-100">
                  <Form.Label className="text-center w-100">
                    <b>New Team Members</b>
                  </Form.Label>
                  <NewColleagues />
                </Container>
              </Col>
            </Row>
          </>
        }
      </Container>

      <div className="pt-5">
        <Row className="justify-content-center">
          <Form.Label className="warning-tip">Please ensure all details are correct - There is no going back!</Form.Label>
        </Row>
        <Row className="justify-content-center">
          {
            !addColleaguesPending &&
            <Button variant="primary"
              onClick={validateSubmission}>
              Submit!
          </Button>
          }
          {
            addColleaguesPending &&
            <LoadingCircle />
          }
        </Row>
        <Container>
          {responseType && (
            <Row className="justify-content-center row-margin">
              <Alert variant={responseType}>{responseMessage}</Alert>
            </Row>
          )}
        </Container>
      </div>
    </div >
  )
}

export default CreateCuntomTeams;