import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

const Home = () => {
  return (
    <div className="page-container">
      <Container>
        <Row className="justify-content-center">
          <h1>Welcome to OnLoop Admin </h1>
        </Row>
        <Row className="justify-content-center">
          <h6>Please click on the NavBar to navigation to different functions</h6>
        </Row>
      </Container>
    </div>
  );
};

export default Home;
