import {useContext, useEffect, useState} from "react";
import {Alert, Button, Container, Form, Row, ListGroup} from "react-bootstrap";
import LoadingCircle from "../components/loading";
import {AuthContext} from "../services/auth";
import axios from "axios";
import firebase from "firebase";
import Select from "react-select";

var firebaseApp = firebase.app();
var functions = firebaseApp.functions("asia-northeast1");

const CreateManagerCapture = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {currentUser, bearerToken} = useContext(AuthContext);

  const [errorMessage, setErrorMessage] = useState(null);
  const baseURL = `${process.env.REACT_APP_API_V2_URL}/api/graphql`;
  const baseURL_old = `${process.env.REACT_APP_API_V1_URL}`;
  const [enableSubmit, setEnableSumit] = useState(false);
  const [captureText, setCaptureText] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  //org
  const [organizationLists, setOrganizationLists] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState("");
  const [selectedOrgObj, setSelectedOrgObj] = useState({});
  const [isOrgPending, setOrgPending] = useState(true);

  //manager
  const [managersList, setManagersList] = useState([]);
  const [selectedManager, setSelectedManager] = useState("");
  const [selectedManagerObj, setSelectedManagerObj] = useState({});
  const [isManagerPending, setManagerPending] = useState(true);

  //direct
  const [directsList, setDirectsList] = useState([]);
  const [selectedDirect, setSelectedDirect] = useState("");
  const [selectedDirectObj, setSelectedDirectObj] = useState({});
  const [isDirectPending, setDirectPending] = useState(true);

  //tags
  const [selectedCaptureType, setSelectedCaptureType] = useState("");
  const [categoryList, setCategoryList] = useState([])
  
  const [selectedCategory, setSelectedCategory] = useState({});
  const [selectedTag, setSelectedTag] = useState({});
  const [selectedTagsList, setSelectedTagsList] = useState([]);
  const [selectedCategoryTagsList, setSelectedCategoryTagsList] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const [defaultTagsList, setDefaultTagsList] = useState([]);

  useEffect(() => {
    setTimeout(getOrgs, 2000);
  }, []);

  //getOrgs API
  const getOrgs = async () => {
    setOrgPending(true);
    setIsLoading(true);
    const graphqlQuery = {
      query: `query {
          orgs {
            id
            name
          }
        }`,
      variables: {},
    };
    axios.defaults.headers.post["Authorization"] = `Bearer ${bearerToken}`;
    axios.defaults.headers.post["content-type"] = `application/json`;
    // Even if endpoint is not expecting data, pass null as the data.
    axios
      .post(baseURL, graphqlQuery)
      .then(function (response) {
        const result = response?.data;
        if (response?.status === 200) {
          const orgs = response?.data?.data?.orgs;
          if (orgs.length > 0) {
            orgs.sort(function (a, b) {
              var nameA = a.name.toLowerCase(),
                nameB = b.name.toLowerCase();
              if (nameA < nameB) return -1;
              if (nameA > nameB) return 1;
              return 0;
            });
            let orgsOptions = orgs.map(org => ({
              value: org.id,
              label: org.name,
            }));

            setOrganizationLists(orgsOptions);
          }
          setOrgPending(false);
          setIsLoading(false);
        }
      })
      .catch(function (error) {
        setErrorMessage(error?.response?.data?.message);
        console.error(error?.response?.data?.message);
        setOrgPending(false);
        setIsLoading(false);
      });
  };

  const getManagersByOrg = async org => {
    setCaptureText("");
    setSelectedCaptureType("");
    setSelectedCategory({});
    setSelectedTag({});
    setSelectedTagsList([]);
    setSelectedCategoryTagsList([]);
    setTagsList([]);
    setDefaultTagsList([]);
    setCategoryList([])
    setSelectedOrgObj(org);
    const orgId = org?.value;
    setSuccessMessage("")
    setErrorMessage("")
    setSelectedOrg(orgId);
    setManagersList([]);
    setIsLoading(true);
    setSelectedManager("");
    setSelectedDirect("");
    setDirectPending(true);
    setManagerPending(true);
    setEnableSumit(false);
    setSelectedManagerObj({});
    setSelectedDirectObj({});
    if (orgId == null) return;

    const graphqlQuery = {
      query: `query BIO_QUERY($orgId: String!) {
        org(id: $orgId) {
          id
          name
          managers {
            id
            email
            name
          }
        }
      }
      `,
      variables: {orgId},
    };
    axios.defaults.headers.post["Authorization"] = `Bearer ${bearerToken}`;
    axios.defaults.headers.post["content-type"] = `application/json`;
    // Even if endpoint is not expecting data, pass null as the data.
    axios
      .post(baseURL, graphqlQuery)
      .then(function (response) {
        const result = response?.data;
        if (response?.status === 200) {
          let managers = response?.data?.data?.org?.managers;
          managers = !managers || managers.length == 0 ? [] : managers;
          const managerObj = managers.map(manager => ({
            value: manager.id,
            label: manager.name + " - " + manager.email,
          }));
          setManagersList(managerObj);
          setManagerPending(false);
          setIsLoading(false);
        }
      })
      .catch(function (error) {
        setErrorMessage(error?.response?.data?.message);
        console.error(error?.response?.data?.message);
        setManagerPending(false);
        setIsLoading(false);
      });
  };



  const getManagerDirects = async manager => {
    setSelectedManagerObj(manager);
    const viewingUserId = manager?.value;
    
    setDirectsList([]);
    setSelectedDirect("");
    setSelectedDirectObj({});
    setSelectedManager(viewingUserId);
    setDirectPending(true);
    setIsLoading(true);
    const graphqlQuery = {
      query: `query BIO_QUERY($userId: String!) {
        getDirectReports(userId: $userId) {
          id
          name
          email
        }
      }
      `,
      variables: {userId: viewingUserId},
    };
    axios.defaults.headers.post["Authorization"] = `Bearer ${bearerToken}`;
    axios.defaults.headers.post["content-type"] = `application/json`;
    // Even if endpoint is not expecting data, pass null as the data.
    axios
      .post(baseURL, graphqlQuery)
      .then(function (response) {
        const result = response?.data;
        if (response?.status === 200) {
          let directs = response?.data?.data?.getDirectReports;
          directs = !directs || directs.length == 0 ? [] : directs;
          const directsObj = directs.map(direct => ({
            value: direct.id,
            label: direct.name + " - " + direct.email,
          }));
          
          setDirectsList(directsObj);
          setDirectPending(false);
          setIsLoading(false);
        }
      })
      .catch(function (error) {
        setErrorMessage(error?.response?.data?.message);
        console.error(error?.response?.data?.message);
        setDirectPending(false);
        setIsLoading(false);
      });
  };

  const getManagerColleagueSelf = async manager => {
    const viewingUserId = manager?.value;
    setDirectPending(true);
    setIsLoading(true);
    const graphqlQuery = {
      query: `query BIO_QUERY($userId: String!) {
        user(id: $userId) {
          id
          name
          colleagues{
            id
            name 
            email
            isUser
          }
        }
      }
      `,
      variables: {userId: viewingUserId},
    };
    axios.defaults.headers.post["Authorization"] = `Bearer ${bearerToken}`;
    axios.defaults.headers.post["content-type"] = `application/json`;
    // Even if endpoint is not expecting data, pass null as the data.
    axios
      .post(baseURL, graphqlQuery)
      .then(function (response) {
        const result = response?.data;
        if (response?.status === 200) {
          let colleagues = response?.data?.data?.user?.colleagues;
          colleagues = !colleagues || colleagues.length == 0 ? [] : colleagues;
          //filters to get managers' self colleague
          const colleaguesObj = colleagues.filter(colleague => colleague.isUser)
          .map(colleague => ({
            value: colleague.id,
            label: colleague.name + " - " + colleague.email,
          }));
          setDirectsList(directsList => [...directsList, colleaguesObj[0]]);
          setDirectPending(false);
          setIsLoading(false);
        }
      })
      .catch(function (error) {
        setErrorMessage(error?.response?.data?.message);
        console.error(error?.response?.data?.message);
        setDirectPending(false);
        setIsLoading(false);
      });
  };
  

  const getManagerTags = async manager => {
    setCaptureText("");
    setSelectedCaptureType("");
    setSelectedCategory({});
    setSelectedTag({});
    setSelectedTagsList([]);
    setSelectedCategoryTagsList([]);
    setTagsList([]);
    setDefaultTagsList([]);
    setCategoryList([])
    const viewingUserId = manager?.value;
    setSelectedManager(viewingUserId);
    setIsLoading(true);

    const graphqlQuery = {
      query: `query BIO_QUERY($userId: String!) {
        user(id: $userId) {
          id
          name
          tags {
            name
            category
            type
          }
        }
      }
      `,
      variables: {userId: viewingUserId},
    };
    axios.defaults.headers.post["Authorization"] = `Bearer ${bearerToken}`;
    axios.defaults.headers.post["content-type"] = `application/json`;
    // Even if endpoint is not expecting data, pass null as the data.
    const usersCategories = {};

    axios
      .post(baseURL, graphqlQuery)
      .then(function (response) {
        const result = response?.data;
        if (response?.status === 200) {
          let tags = response?.data?.data?.user?.tags;
          tags = !tags || tags.length == 0 ? [] : tags;
          const tagsObj = tags.map(tag => {
            usersCategories[tag.category] = {value: tag.category, label: tag.category}
            return {
              label: tag.name,
              value: tag.type + " " + tag.category,
            };
          });
          
          setTagsList(tagsObj);
          setDefaultTagsList(tagsObj);
          setIsLoading(false);
          setCategoryList(Object.values(usersCategories))
        }
      })
      .catch(function (error) {
        setErrorMessage(error?.response?.data?.message);
        console.error(error?.response?.data?.message);
        setDirectPending(false);
        setIsLoading(false);
      });
  };

  const OrgListElement = () => {
    if (organizationLists.length != 0) {
      return (
        <Select
          options={organizationLists}
          value={selectedOrgObj}
          hideSelectedOptions="false"
          onChange={event => {
            getManagersByOrg(event);
          }}
          name="organization"
          placeholder="Choose your Organization"
        />
      );
    } else {
      return <></>;
    }
  };

  const ManagersElement = () => {
    if (managersList.length != 0) {
      return (
        <Select
          options={managersList}
          value={selectedManagerObj}
          onChange={event => {
            getManagerDirects(event);
            getManagerColleagueSelf(event);
            getManagerTags(event);
          }}
          name="user"
          placeholder="Choose your user"
        />
      );
    } else {
      return <></>;
    }
  };

  const DirectsElement = () => {
    return (
      <>
        <Select
          options={directsList}
          value={selectedDirectObj}
          onChange={event => {
            updateDirect(event);
          }}
          name="direct"
          placeholder="Choose your Direct"
        />
      </>
    );
  };

  const CategoriesElement = () => {
    return (
      <>
        <Select
          options={categoryList}
          value={selectedCategory}
          onChange={event => {
            updateSelectedCategory(event);
          }}
          name="Categories"
          placeholder="Choose your category"
        />
      </>
    );
  };

  const TagOptionsElement = () => {
    return (
      <>
        <Select
          options={selectedCategoryTagsList}
          onChange={event => {
            removeSelectedTagFromTagsList(event);
            addSelectedTag(event);
          }}
          name="Tags"
          placeholder="Choose your tag"
        />
      </>
    );
  };

  const SelectedTagsElement = () => {
    return (
      <>
        <ul style={{paddingInlineStart: "0px"}}>
          {selectedTagsList.map(tag => (
            <li
              key={tag.label + tag.name}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                background: "#f0f0f0",
                borderRadius: "10px",
                padding: "10px",
                marginBottom: "10px",
              }}
            >
              {tag.label}{" "}
              <button
                type="button"
                onClick={() => removeSelectedTag(tag)}
                style={{
                  background: "transparent",
                  border: "none",
                  color: "red",
                  fontSize: "16px",
                }}
              >
                &times;
              </button>
            </li>
          ))}
        </ul>
      </>
    );
  };

  const updateDirect = async direct => {
    setCaptureText("");
    setSelectedCaptureType("");
    setSelectedCategory({});
    setSelectedTag({});
    setSelectedTagsList([]);
    setSelectedCategoryTagsList([]);
    setSelectedDirectObj(direct);
    const directId = direct?.value;
    setSelectedDirect(directId);

    if (directId != "") {
      setEnableSumit(true);
    } else {
      setEnableSumit(false);
    }
  };

  const updateSelectedCategory = async category => {
    setSelectedCategory(category);
    const categoryType = selectedCaptureType  == 'positive' ? 'Celebrate' : 'Improve';
    const filteredCatergoryTags = tagsList.filter(tag => {
      return tag.value === categoryType + " " + category.value;
    });
    setSelectedCategoryTagsList(filteredCatergoryTags);
  };

  const handleCaptureTypeChange = event => {
    setSelectedCaptureType(event.target.value);
    setSelectedCategory({});
    setSelectedTag({});
    setSelectedTagsList([]);
    setSelectedCategoryTagsList([]);
    setTagsList(defaultTagsList);
  };

  const addSelectedTag = async tag => {
    setSelectedTag(tag);
    setSelectedTagsList(selectedTagsList => [...selectedTagsList, tag]);
  };

  const removeSelectedTagFromTagsList = selectedTag => {
    //remove tag from tagsList
    const filteredTags = tagsList.filter(tag => {
      return tag.value + tag.label !== selectedTag.value + selectedTag.label;
    });
    setTagsList(filteredTags);
    //removes selected tag from current state of tag option
    const filteredCategoryTags = selectedCategoryTagsList.filter(tag => {
      return tag.label !== selectedTag.label;
    });
    setSelectedCategoryTagsList(filteredCategoryTags);
  };

  const removeSelectedTag = async selectedTag => {
    //remove tag from selectedTagsList
    const filteredSelectedTags = selectedTagsList.filter(tag => {
      return tag.label !== selectedTag.label;
    });
    setSelectedTagsList(filteredSelectedTags);
    //add tag back into tagsList
    setTagsList(tagsList => [...tagsList, selectedTag]);
    //adds into current state of tag option if the same category is currently selected
    if (
      selectedTag.value ===
      selectedCaptureType + " " + selectedCategory.value
    ) {
      setSelectedCategoryTagsList(selectedCategoryTagsList => [
        ...selectedCategoryTagsList,
        selectedTag,
      ]);
    }
  };

  const createCapture = () => {
    if (!selectedOrg || selectedOrg === "") {
      setErrorMessage("Please select org!");
      return;
    }

    if (!selectedManager || selectedManager === "") {
      setErrorMessage("Please select user!");
      return;
    }

    if (!selectedDirect || selectedDirect === "") {
      setErrorMessage("Please select user!");
      return;
    }

    if (!selectedCaptureType || selectedCaptureType === "") {
      setErrorMessage("Please select a capture type!");
      return;
    }

    if (!captureText || captureText === "") {
      setErrorMessage("Please type in capture text!");
      return;
    }

    const selectedTagsNameList = selectedTagsList.map(tag => tag.label);
    setIsLoading(true);

    let myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization",`Bearer ${bearerToken}`);
    //axios.defaults.headers.post["Authorization"] = `Bearer ${bearerToken}`;
    //axios.defaults.headers.post["content-type"] = `application/json`;
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        manager_id: selectedManager,
        colleague_id: selectedDirect,
        feedback_text: captureText,
        capture_type: selectedCaptureType,
       tags: selectedTagsNameList,
      }),
      redirect: 'follow'
    };
    
    fetch(`${baseURL_old}/admin/create-public-capture`, requestOptions)
    .then(response => response.text())
  .then(function (result) {
    const response = JSON.parse(result)
  
      if (response?.statusCode === 200) {
        setSuccessMessage(
          "Your request has been submitted!"
        );
      } else {
        setErrorMessage(
          "Sorry, Your request not accepted!, Please try after sometime!"
        );
      }
      setIsLoading(false);
      setCaptureText("");
      setSelectedCaptureType("");
      setSelectedCategory({});
      setSelectedTag({});
      setSelectedTagsList([]);
      setSelectedCategoryTagsList([]);
      setTagsList([]);
      setDefaultTagsList([]);
      setSelectedOrgObj({});
      setCategoryList([])
      setSelectedOrg("");
      setManagersList([]);
      setSelectedManager("");
      setSelectedDirect("");
      setDirectPending(true);
      setManagerPending(true);
      setEnableSumit(false);
      setSelectedManagerObj({});
      setSelectedDirectObj({});
    })
    .catch(function (error) {
      setErrorMessage(error?.response?.data?.message);
      console.error(error?.response?.data?.message);
      setIsLoading(false);
    });
      
  };

  return (
    <div className="page-container">
      <Container className="d-flex justify-content-center my-4">
        <h2>Create manager capture</h2>
      </Container>
      <Container className="w-100">
        <ol className="notification-hint-padding">
          <li>Search for the organization</li>
          <li>Search for the manager</li>
          <li>Search for the direct that the capture is for</li>
          <li>Fill up the form for the capture</li>
        </ol>
        {!isOrgPending && (
          <Form>
            <div className="row">
              <div className="col-sm-4">
                <Form.Group>
                  <Form.Label>
                    <b>Select Organization</b>
                  </Form.Label>
                  <OrgListElement />
                </Form.Group>
              </div>

              {!isManagerPending && (
                <div className="col-sm-4">
                  <Form.Group>
                    <Form.Label>
                      <b>Select Manager</b>
                    </Form.Label>
                    <ManagersElement />
                  </Form.Group>
                </div>
              )}

              {!isDirectPending && (
                <div className="col-sm-4">
                  <Form.Group>
                    <Form.Label>
                      <b>Select Directs</b>
                    </Form.Label>
                    <DirectsElement />
                  </Form.Group>
                </div>
              )}
            </div>
            {enableSubmit && (
              <>
                <div className="row">
                  <div className="col-sm-2">
                    <Form.Label>
                      <b>Celebrate</b>
                      <input
                        type="radio"
                        checked={selectedCaptureType === "positive"}
                        onChange={handleCaptureTypeChange}
                        value="positive"
                      />
                    </Form.Label>
                  </div>
                  <div className="col-sm-2">
                    <Form.Label>
                      <b>Improve</b>
                      <input
                        type="radio"
                        checked={selectedCaptureType === "negative"}
                        onChange={handleCaptureTypeChange}
                        value="negative"
                        style={{paddingLeft: "4px"}}
                      />
                    </Form.Label>
                  </div>
                </div>
                {selectedCaptureType && (
                  <>
                    <div className="row">
                      <div className="col">
                        <Form.Label>
                          <b>Capture Text</b>
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={4} // Adjust the number of rows as per your preference
                          placeholder="Enter Capture Text"
                          onChange={event => {
                            setCaptureText(event.target.value);
                          }}
                          value={captureText}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-4">
                        <Form.Label>
                          <b> Category </b>
                        </Form.Label>
                        <CategoriesElement />
                      </div>
                      <div className="col-sm-4">
                        <Form.Label>
                          <b> Tags </b>
                        </Form.Label>
                        <TagOptionsElement />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <Form.Label>
                          <b> Tags Selected </b>
                        </Form.Label>
                        <SelectedTagsElement />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4">
                        <Form.Label></Form.Label>
                        <button
                          type="button"
                          style={{marginTop: "27px"}}
                          className="btn btn-success"
                          name="submit"
                          id="submit"
                          onClick={createCapture}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </Form>
        )}

        {errorMessage && (
          <Row className="justify-content-center row-margin">
            <Alert variant={"danger"}>{errorMessage}</Alert>
          </Row>
        )}

        {isLoading && (
          <Row className="justify-content-center row-margin">
            <LoadingCircle />
          </Row>
        )}
        {successMessage && (
          <Row className="justify-content-center row-margin">
            <Alert variant={"success"}>{successMessage}</Alert>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default CreateManagerCapture;
